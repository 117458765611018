//#region Imports!
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PlodDetails, { PlodDetailsConfigProps } from "../components/PlodSheet/PlodDetailsComponent";
import getInstanceID from "../functions/getInstanceID";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IContract } from "../Interfaces/Contract.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import { IStatusWithOrder } from "../Interfaces/Shift.interface";
import { Api } from "../api";
import getInstanceData from "../functions/getInstanceData";

//#endregion



const NewPlod: React.FunctionComponent = () => {
  const instanceID = getInstanceID();
  const instanceData = getInstanceData();
  //#region Filters.
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");
  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
  };

  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<IStatusWithOrder[]>([]);


  const fetchData = async () => {
    setStatusOptions([]);
    setLoadingOptions(true);
    if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID) {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramSelectorID}/~GetStatusForNewPlod`);
        setStatusOptions(data);
      } catch (e) {
        setStatusOptions([]);
        // setDataError(`An error occured while loading Workflows, Please try again later.`);
        // handleErrorResponse(e, "Error while loading selectors"); //TODO: need a better way for this. that actually stops the page.
      }
    }
    setLoadingOptions(false);
  };

  useEffect(() => {
    fetchData(); //TODO: Surely there has to be a better way to do this
  }, [instanceID, miningCompanySelectorID, contractSelectorID, drillingProgramSelectorID]);



  const plodsheetConfig: PlodDetailsConfigProps = {
    DrillingDetailsTimeConfig: "FromToTime",
    TaskTimeConfig: "FromToTime",
    NewDrillingDetailsDefaultFromTime: "FromDrillingTask",
    NewTasksDefaultFromTime: "LatestToTime",
    showActivityOverviewTable: true,
    activityOverviewTableEditable: false,
    canChangeTaskChargeType: instanceData.UserRole.ID != 145, //TODO:MAKE THIS NOT HARD CODED!!!
    canEditPersonnelType: false,
  };


  return (
    <>
      <Box
        sx={{
          marginLeft: '15px',
          display: 'flex',
          flexWrap: 'wrap', // Allow wrapping of items
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0,
          '@media (max-width: 100px)': {
            flexDirection: 'column', // Stack items vertically on smaller screens
            alignItems: 'stretch', // Stretch to fill the available width
          },
        }}
      >

        <MiningCompanySelect
          plodID={instanceID}
          value={miningCompanySelectorID}
          setValue={setMiningCompanySelectorID}
          onChange={handleMiningCompanyChange}
          errorMessage={errorMessage}
          miningCompanyOptions={miningCompanyOptions}
          setMiningCompanyOptions={setMiningCompanyOptions}
        />

        <ContractSelect
          plodID={instanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          value={contractSelectorID}
          setValue={setContractSelectorID}
          onChange={handleContractChange}
          errorMessage={errorMessage}
          contractOptions={contractOptions}
          setContractOptions={setContractOptions}
        />
        <DrillingProgramSelect
          plodID={instanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          contractSelectorID={contractSelectorID}
          value={drillingProgramSelectorID}
          setValue={setDrillingProgramSelectorID}
          onChange={handleDrillingProgramChange}
          errorMessage={errorMessage}
          drillingProgramOptions={drillingProgramOptions}
          setDrillingProgramOptions={setDrillingProgramOptions}
        />

      </Box>


      {errorMessage != null ? (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          <Typography variant="body1" color={"error"}>
            An error occured, Please reload the page or try again later.
          </Typography>
          <Typography variant="body1" color={"error"}>
            {errorMessage}
          </Typography>
        </Box>
      ) : (
        <PlodDetails
          selectorData={{
            save: true,
            plodID: instanceID,
            drillingProgramID: drillingProgramSelectorID,
            contractID: contractSelectorID,
            miningCompanyID: miningCompanySelectorID,
            shiftID: null,
          }}
          dropdownOptions={{
            Statuses: statusOptions,
            loadingDropdownOptions: loadingOptions,
          }}
          canEdit={true}
          plodsheetConfig={plodsheetConfig}
        />
      )
      }
    </>
  );
};

export default NewPlod;