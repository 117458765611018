// App.tsx
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from '@mui/material';
import { Api } from "../api";
import secureLocalStorage from "react-secure-storage";


export const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const location = useLocation(); // React router location hook

  useEffect(() => {
    const checkAuth = async () => {
      setLoading(true); // Ensure loading state is set to true initially
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get("LoginToken");
      const localToken = secureLocalStorage.getItem("LoginToken");
      const token = urlToken || localToken;

      if (!token) {
        window.location.href = "https://login.redochre.cloud/?reason=notoken";
        return; // Prevent further execution if no token exists
      }

      try {
        secureLocalStorage.setItem("LoginToken", token);
        // Try to load data based on the token
        let RequestURL = `/api/Plods/~StartWithInfo`;
        const { data } = await Api.post(RequestURL);
        secureLocalStorage.setItem("InstanceData", data);

        setAuthenticated(true); // Set authenticated when successful
      } catch (error) {
        secureLocalStorage.clear(); // Clear invalid data if error occurs
        setAuthenticated(false);
        window.location.href = "https://login.redochre.cloud/?reason=failedauthentication";
        return; // Stop further execution on error
      } finally {
        setLoading(false); // Set loading to false after the request is finished
        setInitialized(true); // Mark as initialized once finished
      }
    };
    if (!initialized) {
      checkAuth(); // Run auth logic only once on initial load
    }

  }, [initialized, location]);

  if (loading || !initialized) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }
  if (authenticated) {
    return <>{children}</>;
  }
  else {
    console.log(loading);
    console.log(initialized);
    window.location.href = "https://login.redochre.cloud/?reason=notauthenticated";
    return <></>;
  }
};