import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { IDrillingType } from '../../../Interfaces/DrillingType.interface';

export const DrillingTypesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "DrillingType",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Drilling Type Code",
      field: "DrillingTypeCode",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  const initialRow: Partial<IDrillingType> = {
    DrillingType: '',
    DrillingTypeCode: '',
  };


  const validateFields = async (row: IDrillingType): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.DrillingType?.trim()) validationErrors.push("Drilling Type Name cannot be empty.");
    if (!row.DrillingTypeCode?.trim()) validationErrors.push("Drilling Type Code cannot be empty.");
    if (validationErrors.length) {
      await AlertService.showAlert(`Drilling Type "${row.DrillingType}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IDrillingType>
        name="Drilling Type"
        baseURL={`/api/Plods/ID_${instanceID}/DrillingTypes/`}
        columns={columns}
        primaryField="DrillingType"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
