import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Api, handleErrorResponse } from "../../../api";
import getInstanceID from "../../../functions/getInstanceID";
import GenericSelect from "../../GenericSelect";
import AddIcon from "@mui/icons-material/Add";
import { TeamMemberMiningAccessCards } from ".";
import { AlertService } from "../../../services/AlertService";
import { AllMiningCompaniesCard } from "./AllMiningCompaniesCard";
import { NoneMiningCompaniesCard } from "./NoneMiningCompaniesCard";

interface IRoleDetails {
  RoleName: string;
  ID: number;
  MenuItemsAvailable: {
    Menu: string;
    Active: boolean;
    ID: number;
  }[]
}

interface TeamPermissionsDataProps {
  TeamMemberID: string;
  TeamMemberName: string;
}

const TeamMemberPermissionsData: React.FunctionComponent<TeamPermissionsDataProps> = ({ TeamMemberID, TeamMemberName }) => {
  let InstanceID = getInstanceID();

  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataError, setDataError] = useState<string | null>(null);

  const [selectedMiningCompanyID, setSelectedMiningCompanyID] = React.useState<string>('');
  const [miningAccess, setMiningAccess] = React.useState<any[]>([]);
  const [miningCompanyOptions, setMiningCompanyOptions] = React.useState<any[]>([]);
  const [roleDetails, setRoleDetails] = React.useState<IRoleDetails | null>(null);

  const fetchData = async () => {
    setEditMode(false);
    setMiningAccess([]);
    setDataPrivilegesSelectedValue("NONE");
    setMiningCompanyOptions([]);
    setRoleDetails(null);
    setDataError(null);
    setLoading(true);
    try {
      if (TeamMemberID) {
        const body = {
          query: `{
          Plods(ID_AP_Plod: ${InstanceID}) {
            MiningCompanys {
              ID_EA_MiningCompany
              MiningCompanyName
              Contracts {
                ID_EC_Contract
                ContractName
              }
            } 
            TeamMembers(ID: ${TeamMemberID}) {
              Roles {
                RoleName
                ID
                MenuItem {
                    Menu
                    Active
                    ID
                }
              }
              CompanyAccess {
                MiningCompanyAccess
                MiningAccess {
                  ID_EA_MiningCompany
                  MiningCompanyName
                  AllContracts
                  ContractAccessYN {
                    ID_EC_Contract
                    ContractName
                    Access
                  }
                }
              }
            }
          }
        }`
        };
        const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
        const { TeamMembers, MiningCompanys } = data.data.Plods[0];
        const { CompanyAccess, Roles } = TeamMembers[0];
        const { MiningCompanyAccess, MiningAccess } = CompanyAccess[0];
        if (Roles.length > 0) {
          setRoleDetails({
            RoleName: Roles[0].RoleName,
            ID: Roles[0].ID,
            MenuItemsAvailable: Roles[0].MenuItem
          });
        }
        setMiningAccess(MiningAccess);
        setMiningCompanyOptions(MiningCompanys);
        setDataPrivilegesSelectedValue(MiningCompanyAccess);
      }
    } catch (e) {
      setMiningAccess([]);
      setDataPrivilegesSelectedValue("NONE");
      setMiningCompanyOptions([]);
      setRoleDetails(null);
      setDataError("Error occured while loading Permissions.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (TeamMemberID) fetchData();
  }, [TeamMemberID]);

  const [dataPrivilegesSelectedValue, setDataPrivilegesSelectedValue] = React.useState('NONE');
  const handleDataPrivilegesChange = (event: any) => {
    const selectedValue = event.target.value;
    setDataPrivilegesSelectedValue(selectedValue);
  };

  function handleAddMiningCompanyClick(): void {
    let selectedMiningCompany: any = miningCompanyOptions.find((miningcompany) =>
      miningcompany.ID_EA_MiningCompany == selectedMiningCompanyID) || {};
    let newMiningCompanyAccessData = {
      ID_EA_MiningCompany: selectedMiningCompany.ID_EA_MiningCompany,
      MiningCompanyName: selectedMiningCompany.MiningCompanyName,
      Access: false,
      ContractAccessYN: selectedMiningCompany.Contracts.map((contract: any) => {
        return {
          ID_EC_Contract: contract.ID_EC_Contract,
          ContractName: contract.ContractName,
          Access: false,
        };
      })
    };

    setMiningAccess(prev => [...prev, newMiningCompanyAccessData]);
    setSelectedMiningCompanyID('');
  }

  const [editMode, setEditMode] = React.useState(false);

  async function handleEditSaveButttonClick(): Promise<void> {
    if (editMode) {
      try {
        const dataToStringify = {
          MiningAccess: miningAccess,
          MiningCompanyAccess: dataPrivilegesSelectedValue,
        };
        console.log(dataToStringify);
        const rawData = JSON.stringify(dataToStringify);
        console.log(rawData);
        //Goes to a custom API endpoint.
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${TeamMemberID}/~SaveUserPermissions`,
          rawData
        );
        await AlertService.showAlert(`Successfully Updated Team Member Permissions.`, "success");
      } catch (e) {
        handleErrorResponse(e, `Error updating ".`);
        return;
      }
    }
    setEditMode(!editMode);
  }

  function handleCancelButtonClick(): void {
    setEditMode(false);
    fetchData();
  }


  const EditSaveButton: React.FunctionComponent = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start", // Aligns the button to the right
            alignItems: "flex-start",     // Aligns the button to the bottom
            marginLeft: "10px",          // Pushes the button to the bottom if there's space
          }}
        >
          <Button
            variant="outlined"
            onClick={handleEditSaveButttonClick}
            sx={{
              marginLeft: '10px',
              marginTop: '10px',
              width: '100px',
            }}
          >
            {editMode ? "SAVE" : "EDIT"}
          </Button>
          {editMode &&
            <Button
              variant="outlined"
              onClick={handleCancelButtonClick}
              sx={{
                marginLeft: '10px',
                marginTop: '10px',
                width: '100px',
              }}
            >
              Cancel
            </Button>
          }
        </Box>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      ) : (dataError != null ? (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          <Typography variant="body1" color={"error"}>
            An error occured, Please reload the page or try again later.
          </Typography>
          <Typography variant="body1" color={"error"}>
            {dataError}
          </Typography>
        </Box>
      ) : (
        <>
          {/*For saying what role it has and the permissions for said role. */}
          <>
            <Box
              sx={{
                border: 1,
                borderColor: "#DEDEDE",
                borderRadius: 2,
                padding: 2,
                marginTop: 2,
                width: "50%",
                marginLeft: 1.5,
                minWidth: '400px'
              }}
            >
              <Typography variant="body1">
                <strong>{TeamMemberName} </strong>
                {roleDetails == null ? (<>
                  has no role.
                </>) : (
                  <>
                    has the role of
                    <strong> {roleDetails.RoleName}</strong>
                    <br />
                    The <strong>{roleDetails.RoleName}</strong> role
                    grants the following menu access:
                    <br />
                    <br />
                    {roleDetails.MenuItemsAvailable
                      ?.filter((item: any) => item?.Active)
                      .map((item: any, index: number, array: any[]) => (
                        <span key={index}>
                          {item?.Menu}
                          {index < array.length - 1 && ", "}
                        </span>
                      ))}
                  </>
                )}
              </Typography>
            </Box>
          </>

          <EditSaveButton />
          {/*Selector for the data permissions. */}
          <Box
            sx={{
              border: 1,
              borderColor: "#DEDEDE",
              borderRadius: 2,
              padding: 2,
              marginTop: 2,
              width: "300px",
              marginLeft: 1.5,
            }}
          >
            <FormControl >
              <FormLabel>Data Privileges</FormLabel>
              <RadioGroup
                aria-label="Data Privileges"
                name="dataPrivileges"
                value={dataPrivilegesSelectedValue}
                onChange={handleDataPrivilegesChange}
              >
                <FormControlLabel
                  value="ALL"
                  control={<Radio disabled={!editMode} />}
                  label="All Mining Companies"
                />
                <FormControlLabel
                  value="NONE"
                  control={<Radio disabled={!editMode} />}
                  label="No Mining Companies"
                />
                <FormControlLabel
                  value="SELECTION"
                  control={<Radio disabled={!editMode} />}
                  label="Selected Mining Companies"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/*Shows which card/s to show based on the selection. */}
          <>
            {dataPrivilegesSelectedValue === "ALL" &&
              <AllMiningCompaniesCard TeamMemberName={TeamMemberName} />
            }
            {dataPrivilegesSelectedValue === "NONE" &&
              <NoneMiningCompaniesCard TeamMemberName={TeamMemberName} />
            }
            {dataPrivilegesSelectedValue === "SELECTION" &&
              <>
                <Stack direction='row' marginLeft='15px'>
                  <GenericSelect<any>
                    label="Client"
                    value={selectedMiningCompanyID}
                    onChange={(event) => setSelectedMiningCompanyID(event.target.value as string)}
                    options={miningCompanyOptions.filter((miningCompany) =>
                      !miningAccess.some((miningaccess) => miningaccess.ID_EA_MiningCompany === miningCompany.ID_EA_MiningCompany)
                    )}
                    disabled={!editMode}
                    valueField="ID_EA_MiningCompany"
                    labelField="MiningCompanyName"
                    loading={loading}
                  />
                  <Stack spacing={0} padding="5px">
                    <FormHelperText>&nbsp;</FormHelperText>   {/* This is so dumb - but it atleast keeps the height the same */}
                    <IconButton
                      sx={{
                        border: '1px solid',
                        borderColor: '#cbcbcb',
                        height: "56px",
                        width: "56px",
                        borderRadius: 1,
                        display: 'flex', // Aligns icon vertically
                        alignItems: 'center', // Centers the icon vertically
                        justifyContent: 'center', // Centers the icon horizontally
                      }}
                      disabled={selectedMiningCompanyID === '' ||
                        miningAccess.some((miningaccess) => miningaccess.ID_EA_MiningCompany == selectedMiningCompanyID)
                        || !editMode
                      }
                      color="primary"
                      onClick={handleAddMiningCompanyClick}
                    >
                      <AddIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <TeamMemberMiningAccessCards miningAccess={miningAccess} setMiningAccess={setMiningAccess} disabled={!editMode} />
                <EditSaveButton />
              </>
            }
          </>
        </>
      )
      )}
    </>
  );
};
export { TeamMemberPermissionsData };