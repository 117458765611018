
import React from "react";
import { randomId } from '@mui/x-data-grid-generator';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, } from '@mui/material';
import { GridRowModesModel, GridToolbarContainer, GridRowModes } from '@mui/x-data-grid-premium';
import AddIcon from "@mui/icons-material/Add";
import { height } from "@mui/system";
import GenericSelect from "./GenericSelect";
type CustomToolbarWithJustDropProps<T> = {
  label: string;
  plurificationText?: string;
  value: string;  // The value should match the value type from the options array.
  onChange: (event: SelectChangeEvent) => void;
  options: T[];
  disabled?: boolean;
  valueField: keyof T;
  labelField: keyof T;
  showAllOption?: boolean;
  loading?: boolean;
  customMessage?: string | null;
};

function CustomToolbarWithDropDown<T>({
  label,
  plurificationText = "s",
  value,
  onChange,
  options,
  disabled,
  valueField,
  labelField,
  loading = false,
  customMessage,
}: CustomToolbarWithJustDropProps<T>) {
  return (
    <GridToolbarContainer sx={{ height: "50px" }}>
      <GenericSelect<T>
        label={label}
        value={value}
        onChange={(event: SelectChangeEvent<string>) => onChange(event)}
        options={options}
        disabled={disabled}
        valueField={valueField}
        labelField={labelField}
        loading={loading}
        plurificationText={plurificationText}
        variant="standard"
        showSeperateLabel={false}
        customMessage={customMessage}
      />

    </GridToolbarContainer>
  );
}
export default CustomToolbarWithDropDown;