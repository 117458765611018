import React from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";

interface AllMiningCompaniesCardProps {
  TeamMemberName: string;
}

const AllMiningCompaniesCard: React.FunctionComponent<AllMiningCompaniesCardProps> = ({ TeamMemberName }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginLeft: 1.5,
        mt: 2,
        width: "40%",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Typography variant="body1">
          <strong>Gold card</strong>
          <br />
          <br />
          All Mining Companies, All Contracts, All Data
          <br />
          <br />
          <strong>{TeamMemberName}</strong> has access to all data
          in all Mining Companies and all Contracts
        </Typography>
      </CardContent>
    </Card>
  );
};

export { AllMiningCompaniesCard };