import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Api } from "../api";
import secureLocalStorage from "react-secure-storage";
import { IInstanceData } from "../Interfaces/InstanceData.interface";
import { CircularProgress, AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider, useMediaQuery, useTheme, Box, Menu, MenuItem, Tooltip, Button } from "@mui/material";
import { Menu as MenuIcon, DarkMode as DarkModeIcon, LightMode as LightModeIcon } from "@mui/icons-material";
import * as FaIcons from "react-icons/fa";
import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import getInstanceID from "../functions/getInstanceID";
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import getInstanceData from "../functions/getInstanceData";


const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

interface AppLayoutProps {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}


const UserProfileMenu = ({ name }: { name: string }) => {
  const [anchorEl, setAnchorEl] = useState(null); // For Menu anchor position
  const [open, setOpen] = useState(false); // To track if the menu is open

  // Handle menu open
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Handle menu close
  const handleClose = () => {
    setOpen(false);
  };

  // Handle Logout (you can replace this with your actual logout logic)
  const handleLogout = () => {
    secureLocalStorage.clear();
    handleClose(); // Close the menu after logout
    window.location.replace(`https://login.redochre.cloud`);
  };

  return (
    <React.Fragment>

      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"

          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> {name || 'Unknown'}
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        {/* //TODO:Probally add the dark mode / light mode switch here */}
      </Menu>
    </React.Fragment>
  );
};
const AppLayout: React.FC<AppLayoutProps> = ({ darkMode, setDarkMode }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const instanceData = getInstanceData();
  // Sync drawer state with screen size
  React.useEffect(() => {
    if (isLargeScreen) {
      setDrawerOpen(true); // Automatically open the drawer on larger screens
    } else {
      setDrawerOpen(false); // Collapse the drawer on smaller screens
    }
  }, [isLargeScreen]);

  const drawerWidth = 240;
  const hasRoles = instanceData.RoleAccess?.length > 0;

  const hasAccess = (path: string) => {
    if (!hasRoles) return false;
    const isAccess = instanceData.RoleAccess.find(
      (i: any) => i.Menu.toLowerCase() === path.toLowerCase() && (i.Active === "True" || i.Active === true)
    );
    return !!isAccess;
  };

  let wasPreviousSpacer = true;

  return (
    <div style={{ display: "flex" }}>
      {/* AppBar */}
      <AppBar color="default" position="fixed" sx={{ zIndex: 1300, boxShadow: "none" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!isLargeScreen && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(!drawerOpen)} sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
            )}
            {/* Logo */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" noWrap>
                Drill Down -
                {` ${instanceData.InstanceInfo.Plod_App_DrillingCompanyName} - `}
                {" "}{location.pathname == "/" ? "Home" : SidebarData.find((item) => item.path === location.pathname)?.title}
              </Typography>
            </div>
          </div>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            {/* Dark/Light Mode Toggle Button */}
            {(instanceData.UserInfo.ID == 42 || instanceData.UserInfo.ID == 14) && (
              <IconButton color="inherit" aria-label="toggle dark mode" onClick={() => {
                setDarkMode(!darkMode);
                localStorage.setItem("DarkMode", (!darkMode).toString());
              }}>
                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
            )}
            {/* User Profile Menu */}
            <UserProfileMenu name={instanceData.UserInfo.FirstName + " " + instanceData.UserInfo.LastName} />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant={isLargeScreen ? "persistent" : "temporary"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Toolbar />
        <List>
          <Link to={"/"} style={{ textDecoration: 'none' }} >
            {/* This allows for it to be right clicked, and scroll wheel clicked for open in new tab */}
            <ListItemButton onClick={() => navigate("/")}>
              <ListItemIcon sx={{ color: "#ffffff" }}>
                <FaIcons.FaHome />
              </ListItemIcon>
              <ListItemText sx={{ color: "#ffffff" }} primary="Home" />
            </ListItemButton>
          </Link>
          <Divider sx={{ backgroundColor: "#ffffff" }} />
          {
            SidebarData.map((item, index) => {
              if (item.title === "Spacer") {
                if (wasPreviousSpacer) {
                  // Skip rendering this spacer
                  return null;
                }
                wasPreviousSpacer = true; // Set the flag as the current item is a spacer
                return <Divider key={index} sx={{ backgroundColor: "#ffffff" }} />;
              } else if (hasAccess(item.title)) {
                wasPreviousSpacer = false; // Reset the flag as the current item is not a spacer
                return (

                  <Link key={index} to={item.path} style={{ textDecoration: 'none' }} > {/* This allows for it to be right clicked, and scroll wheel clicked for open in new tab */}
                    <ListItemButton onClick={() => navigate(item.path)}>
                      <ListItemIcon sx={{ color: "#ffffff" }}>{item.icon}</ListItemIcon>
                      <ListItemText sx={{ color: "#ffffff" }} primary={item.title === "Teams" ? "Team" : item.title} />
                    </ListItemButton>
                  </Link>

                );
              } else {
                return null;
              }
            })
          }
        </List>
      </Drawer>

      {/* Main Content */}
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "24px",
          transition: "margin 0.3s",
          minHeight: 0,
          overflow: "hidden",
          minWidth: '400px',
        }}
      >
        <Toolbar />
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            overflow: "auto",
          }}
        >
          {/* Main Content */}
          <main style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Outlet />
          </main>
        </Box>
      </main>
    </div>
  );
};

export default AppLayout;