import React from "react";
import NewPlodBox from "../newPlodBox";
import CustomPlodSheetDataGrid from "../CustomPlodSheetDataGrid";
import { GridColDef, GridRowModesModel } from "@mui/x-data-grid-premium";
import { getActionsForRow } from '../../EditableRowHandlers';
import { OneOffChargeRow } from "../../../Interfaces/Shift.interface";
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import { singleSelectComparator } from "../../../functions/singleSelectComparator";
import { IGenericDropDownOption } from "../../../Interfaces/GenericDropDownOption.interface";

interface OneOffChargesGridProps {
  oneOffChargeOptions: IGenericDropDownOption[], // TODO: Make this the proper type

  oneOffChargeRows: OneOffChargeRow[],
  oneOffChargeRowModesModel: GridRowModesModel,
  setOneOffChargeRowModesModel: (model: GridRowModesModel) => void,
  setOneOffChargeRows: React.Dispatch<React.SetStateAction<OneOffChargeRow[]>>;

  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const OneOffChargesGrid: React.FunctionComponent<OneOffChargesGridProps> = ({
  oneOffChargeOptions,

  oneOffChargeRows,
  oneOffChargeRowModesModel,
  setOneOffChargeRowModesModel,
  setOneOffChargeRows,

  loading,
  disabled,
  canEdit
}) => {

  const Handlers = useStandardEditableRowActions({
    dataRows: oneOffChargeRows,
    dataRowModesModel: oneOffChargeRowModesModel,
    setDataRowModesModel: setOneOffChargeRowModesModel,
    setDataRows: setOneOffChargeRows,
  });

  const Columns: GridColDef<any>[] = [
    {
      field: "ID_EDP_OneOffCharge",
      headerName: "One Off Charge",
      flex: 4,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: oneOffChargeOptions,
      sortComparator: singleSelectComparator,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 2.5,
      minWidth: 200,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: oneOffChargeRowModesModel,
          handlers: Handlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
          disabled: !canEdit
        }),
    }
  ];


  return (
    <NewPlodBox color="#17b964" text="One Off Charges" key="OneOffChargeDataGrid">
      <CustomPlodSheetDataGrid
        columns={Columns}
        rows={oneOffChargeRows}
        rowModesModel={oneOffChargeRowModesModel}
        setRows={setOneOffChargeRows}
        setRowModesModel={setOneOffChargeRowModesModel}
        fieldToFocus={"ID_EDP_OneOffCharge"}
        buttonText={"Add One Off Charge"}
        initialRow={{ ID_EDP_OneOffCharge: '', Quantity: 1, Comment: "" }}
        disabled={!canEdit || disabled}
        loading={loading}
        isCellEditable={(params) => canEdit && !disabled}
      />
    </NewPlodBox>
  );
};