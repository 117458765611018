import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";

interface GetDifferenceOfTwoFieldsCellProps {
  fieldfrom: string,
  fieldto: string,
}

export function GetDifferenceOfTwoFieldsCell(props: GridRenderCellParams & GetDifferenceOfTwoFieldsCellProps) {
  const { row, fieldfrom, fieldto } = props;
  const fieldToValue: number | undefined | null = row[fieldto];
  const fieldFromValue: number | undefined | null = row[fieldfrom];
  if (fieldToValue != null && fieldFromValue != null) {
    const difference: number = fieldToValue - fieldFromValue;
    return (
      <span>{difference}</span>
    );
  }
  else {
    return (
      <span>0</span>
    );
  }
}
