import React from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";

interface NoneMiningCompaniesProps {
  TeamMemberName: string;
}

const NoneMiningCompaniesCard: React.FunctionComponent<NoneMiningCompaniesProps> = ({ TeamMemberName }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        mt: 2,
        marginLeft: 1.5,
        width: "40%",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Typography variant="body1">
          <strong>Access Revoked</strong>
          <br />
          <br />
          No Mining Company Access
          <br />
          <br />
          <strong>{TeamMemberName}</strong> cannot access
          <br /> any mining company information.
          <br />
          <br />
          This team member may have access to add or delete other data
          pending on the menu items they have access to.
        </Typography>
      </CardContent>
    </Card>
  );
};

export { NoneMiningCompaniesCard };