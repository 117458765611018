import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Api } from "../api";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import GenericSelect from "./GenericSelect";

interface MiningCompanySelectProps {
  plodID: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  miningCompanyOptions: IMiningCompany[];
  setMiningCompanyOptions: React.Dispatch<React.SetStateAction<IMiningCompany[]>>;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
}

const MiningCompanySelect: React.FC<MiningCompanySelectProps> = ({
  plodID,
  value,
  onChange,
  errorMessage,
  disabled,
  miningCompanyOptions,
  setMiningCompanyOptions,
  setValue
}) => {
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState<AbortController | null>(null);

  const fetchMiningCompanyData = async () => {
    if (controller) {
      // Abort the previous request
      controller.abort();
    }

    const newController = new AbortController();
    setController(newController);

    setLoading(true);
    setMiningCompanyOptions([]);

    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/`,
        null,
        { signal: newController.signal }
      );

      let localitem = localStorage.getItem("ID_EA_MiningCompany"); // Read from localStorage
      if (localitem != null && setValue) {
        if (data.some((x: { ID_EA_MiningCompany: any }) => x.ID_EA_MiningCompany == localitem)) {
          setValue(localitem);
        } else {
          localStorage.removeItem("ID_EA_MiningCompany");
        }
      }
      setMiningCompanyOptions(data);
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching Clients", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMiningCompanyData();

    // Cleanup on component unmount
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [plodID]);

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    localStorage.setItem("ID_EA_MiningCompany", event.target.value); // Save to localStorage
    onChange(event.target.value);
  };

  return (
    <GenericSelect<IMiningCompany>
      label="Client"
      value={value}
      onChange={handleSelectChange}
      options={miningCompanyOptions}
      disabled={errorMessage != null || disabled}
      valueField="ID_EA_MiningCompany"
      labelField="MiningCompanyName"
      loading={loading}
    />
  );
};

export default MiningCompanySelect;
