import React from "react";
import { CircularProgress, Stack, FormHelperText, Box } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DatePickerWithLoadingProps {
  label: string;
  dateValue: any; // Replace 'any' with the correct type for your date library
  setDateValue: (newValue: any) => void; // Replace 'any' as needed
  disabled?: boolean;
  errorMessage?: string | null;
  loading?: boolean; // New loading prop
}

const DatePickerWithLoading: React.FC<DatePickerWithLoadingProps> = ({
  label,
  dateValue,
  setDateValue,
  disabled = false,
  loading = false,
}) => {
  return (
    <Stack spacing={0} padding={"5px"} paddingRight={"10px"}>
      <FormHelperText>{label}</FormHelperText>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box position="relative" sx={{ width: 250 }}>
          <DatePicker
            value={dateValue}
            disabled={disabled || loading}
            onChange={(newValue) => setDateValue(newValue)}
            format="DD/MM/YYYY"
            sx={{ width: 250 }}
          />
          {loading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="rgba(255, 255, 255, 0.6)" // Slight overlay effect
              zIndex={1}
            >
              <CircularProgress size={24} />
            </Box>
          )}
        </Box>
      </LocalizationProvider>
    </Stack>
  );
};

export default DatePickerWithLoading;
