//#region ITS JUST ANNOYING IMPORTS.
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridEditSingleSelectCellProps,
  GridEventListener,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { IEntireShiftData, IStatusWithOrder } from '../Interfaces/Shift.interface';
import {
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PlodDetails, { PlodDetailsConfigProps } from "../components/PlodSheet/PlodDetailsComponent";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";
import { AlertService } from "../services/AlertService";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { Stack } from "@mui/system";
import validateAndSet from "../functions/ValidAndSetData";
import { IContract } from "../Interfaces/Contract.interface";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import { CSSTransition } from 'react-transition-group';
import "../styles/PlodDetailsTransition.css"; // CSS for the transition
import { IShiftUpdateResponse } from "../Interfaces/ShiftUpdateResponse.interface";
import getInstanceData from "../functions/getInstanceData";

//#endregion
interface ShiftDataWithPermissions extends IEntireShiftData {
  CanEdit: boolean
}

interface ReviewAndSignoffShiftRow {
  ID: number;
  Date: string;
  DayNight: string;
  Rig: string;
  Site: string;
  ID_WF_Status: string; // needs to be updated at some point
  CanDelete: boolean
  StatusOptions: IStatusWithOrder[]
}
const ReviewAndSignoff: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const instanceData = getInstanceData();
  //#region State Variables
  const [dataRows, setDataRows] = React.useState<ReviewAndSignoffShiftRow[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = React.useState<GridRowModesModel>();

  const [selectedShiftData, setSelectedShiftData] = React.useState<ShiftDataWithPermissions | null>(null);
  const [selectedShiftStatusOptionsData, setSelectedShiftStatusOptionsData] = React.useState<IStatusWithOrder[]>([]);
  const [canEditSelectedShift, setCanEditSelectedShift] = React.useState<boolean>(false);

  const [shiftID, setShiftID] = React.useState<number | null>(null);


  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  //#endregion


  //#region Filters/selector things:

  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");
  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID(""); // Reset drilling program selections
    setDataRows([]);
    setShiftID(null);
    setSelectedShiftData(null);
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID(""); // Reset drilling program selection
    setDataRows([]);
    setShiftID(null);
    setSelectedShiftData(null);
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
    setDataRows([]);
    setShiftID(null);
    setSelectedShiftData(null);
  };

  //#endregion

  //#region fetches the data for grid.
  const fetchData = async () => {
    setLoading(true);
    setDataRows([]);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramSelectorID}/~GetAllShiftsWithDataFields`);

      validateAndSet(data, setDataRows, "Shifts are missing");

    } catch (e) {
      setDataRows([]);
      handleErrorResponse(e, "Error fetching Shifts");
      setDataGridError("Error fetching shifts.");
    };
    setLoading(false);
  };


  React.useEffect(() => {
    if (drillingProgramSelectorID) {
      fetchData();
    }
  }, [drillingProgramSelectorID]);
  //#endregion

  const [loadingShift, setLoadingShift] = useState<boolean>(false); // Loading state

  const fetchExistingShiftData = async () => {
    setLoadingShift(true);
    setSelectedShiftData(null);
    if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID && shiftID) {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramSelectorID}/Shifts/ID_${shiftID}/~GetEntireShift`);
        // Loop through the data and check the FromTime and ToTime fields
        const updateTimeFields = (list: any[]) => {
          return list.map(item => {
            // Update only FromTime and ToTime, preserve other fields like "name"
            item.FromTime = item.FromTime && dayjs(item.FromTime).isValid() ? dayjs(item.FromTime) : null;
            item.ToTime = item.ToTime && dayjs(item.ToTime).isValid() ? dayjs(item.ToTime) : null;
            return item;
          });
        };
        const { Statuses, ...shiftData } = data;
        // Update while keeping all other fields intact
        const updatedData: ShiftDataWithPermissions = {
          ...shiftData, // Spread the original data to keep all other fields intact
          DrillingDetails: updateTimeFields(shiftData.DrillingDetails), // Update shiftDetails
          Tasks: updateTimeFields(shiftData.Tasks),  // Update otherDetails
          TaskDrillingDetailsCombo: updateTimeFields(shiftData.TaskDrillingDetailsCombo)  // Update otherDetails
        };
        setSelectedShiftStatusOptionsData(Statuses);
        setSelectedShiftData(updatedData);

        setCanEditSelectedShift(updatedData.CanEdit);
      } catch (e) {
        setShiftID(null);
        setSelectedShiftData(null);
        handleErrorResponse(e, "Error fetching existing items");
      };
    }
    setLoadingShift(false);
  };

  React.useEffect(() => {
    fetchExistingShiftData();
  }, [miningCompanySelectorID, contractSelectorID, drillingProgramSelectorID, shiftID]);

  //#region For shift columns.

  // Update row modes model when any mode changes
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel); // Directly set the new model
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Shift.`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/ID_${drillingProgramSelectorID}/Shifts/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Shift.`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Shift.`);
      }
    }
  };

  const shiftColumns: GridColDef<any>[] = [
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      editable: false,
      type: "date",
      valueFormatter: (value, row, column, apiRef) => {
        if (!row.Date) {
          return ''; // Return an empty string if params or value is undefined
        }
        try {
          return dayjs(row.Date).format('DD/MMM/YYYY');
        } catch {
          return '';
        }
      },
      // valueGetter: ({ value }) => value && dayjs(value).format("MM/DD/YYYY"),
    },
    {
      field: "DayNight",
      headerName: "Day/Night",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "Rig",
      headerName: "Rig",
      flex: 1,
      minWidth: 200,
      editable: false,
    },
    {
      field: "Site",
      headerName: "Site",
      flex: 1,
      minWidth: 200,
      editable: false,
    },
    {
      field: "ID_WF_Status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      editable: false,
      type: "singleSelect",
      renderCell: (params: GridEditSingleSelectCellProps) => {
        const options: IStatusWithOrder[] = params.row.StatusOptions;
        const item = options.find((option) => option.ID == params.value);
        return <span>{item?.StatusName}</span>; //really basic rendering off it, wish to add colour or similar at some point.
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID, row }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
            disabled={!row.CanDelete} // If not allowed to delete, will make it so that the delete button does not appear :smile:
          />,
        ];
      },
    },
  ];

  const handleShiftRowClickEvent: GridEventListener<"rowClick"> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    setShiftID(params.row.ID);
  }; //TODO: got to look into better way for this.

  const handleShiftUpdated = (newShiftValues: IShiftUpdateResponse) => {
    if (newShiftValues.CanRead) {
      setDataRows((prev) => {
        const updatedRows = prev.map((row) =>
          row.ID == newShiftValues.ID
            ? {
              ...row,
              Date: dayjs(newShiftValues.Date).format('YYYY-MM-DD'),
              ID_WF_Status: newShiftValues.ID_WF_Status.toString(),
              DayNight: newShiftValues.DayNight,
              CanDelete: newShiftValues.CanDelete,
              StatusOptions: newShiftValues.Statuses
            }
            : row
        );
        return updatedRows;
      });
      setSelectedShiftStatusOptionsData(newShiftValues.Statuses);
      setCanEditSelectedShift(newShiftValues.CanEdit);
    }
    else {
      //remove from rows if it doesnt have view perms no more.
      //only do the setShiftID null if it doesn't have view perms anymore.  
      setShiftID(null);
      setSelectedShiftData(null);
      //remove it from the appearing when you dont have perms to view it anymore
      setDataRows((prev) => prev.filter((row) => row.ID!.toString() != newShiftValues.ID.toString()));
    }
  };



  //This just makes it a bit nicer while it scrolls back up.
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    //need some logic to deside if you are going to do this or not.
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const plodsheetConfig: PlodDetailsConfigProps = {
    DrillingDetailsTimeConfig: "FromToTime",
    TaskTimeConfig: "FromToTime",
    NewDrillingDetailsDefaultFromTime: "FromDrillingTask",
    NewTasksDefaultFromTime: "LatestToTime",
    showActivityOverviewTable: true,
    activityOverviewTableEditable: false,
    canChangeTaskChargeType: instanceData.UserRole.ID != 145, //TODO:MAKE THIS NOT HARD CODED!!!
    canEditPersonnelType: false,
  };

  return (
    <>
      <Stack >
        <Box
          sx={{
            marginLeft: '15px',
            display: 'flex',
            flexWrap: 'wrap', // Allow wrapping of items
            flexDirection: 'row',
            alignItems: 'center',
            gap: 0,
            '@media (max-width: 100px)': {
              flexDirection: 'column', // Stack items vertically on smaller screens
              alignItems: 'stretch', // Stretch to fill the available width
            },
          }}
        >
          <MiningCompanySelect
            plodID={plodID}
            value={miningCompanySelectorID}
            setValue={setMiningCompanySelectorID}
            onChange={handleMiningCompanyChange}
            miningCompanyOptions={miningCompanyOptions}
            setMiningCompanyOptions={setMiningCompanyOptions}
          />

          <ContractSelect
            plodID={plodID}
            miningCompanySelectorID={miningCompanySelectorID}
            value={contractSelectorID}
            setValue={setContractSelectorID}
            onChange={handleContractChange}
            contractOptions={contractOptions}
            setContractOptions={setContractOptions}
          />
          <DrillingProgramSelect
            plodID={plodID}
            miningCompanySelectorID={miningCompanySelectorID}
            contractSelectorID={contractSelectorID}
            value={drillingProgramSelectorID}
            setValue={setDrillingProgramSelectorID}
            onChange={handleDrillingProgramChange}
            drillingProgramOptions={drillingProgramOptions}
            setDrillingProgramOptions={setDrillingProgramOptions}
          />
        </Box>
        <Box
          sx={{
            height: '70vh',
            minHeight: 300,
            maxHeight: 700,
            margin: "20px",
          }}
        >
          <DataGridPremium
            columns={shiftColumns}
            rows={dataRows}
            editMode="row"
            getRowId={(row) => row.ID}
            rowModesModel={dataRowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowClick={handleShiftRowClickEvent}
            loading={loading}
            disableColumnSelector
            sx={{ height: '100%' }}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay
                  message={dataGridError ? dataGridError : "No Shift Data"}
                  onRetry={dataGridError ? () => fetchData() : undefined}
                />
              ),
            }}
          />

        </Box>

        <CSSTransition
          in={shiftID != null} // Condition for showing the component
          timeout={3000} // Duration of the transition in milliseconds
          classNames="plod-details" // Base name for transition classes
          unmountOnExit // Removes the component from the DOM when hidden
          onExiting={scrollToTop} // Scroll to top when the component has exited
        >
          <PlodDetails
            selectorData={{
              save: false,
              plodID: plodID,
              drillingProgramID: drillingProgramSelectorID,
              contractID: contractSelectorID,
              miningCompanyID: miningCompanySelectorID,
              shiftID: shiftID,
            }}
            existingRowsData={selectedShiftData}
            dropdownOptions={{
              Statuses: selectedShiftStatusOptionsData,
              loadingDropdownOptions: loadingShift,
            }}
            disabled={selectedShiftData == null}
            onStatusValueChange={handleShiftUpdated}
            canEdit={canEditSelectedShift}
            plodsheetConfig={plodsheetConfig}
          />
        </CSSTransition>
      </Stack>
    </>
  );
};

export default ReviewAndSignoff;
