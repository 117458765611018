import { GridEditSingleSelectCellProps } from '@mui/x-data-grid-premium';
import React from 'react';
import { TextField } from '@mui/material';

export function CustomLocationEditCell(props: GridEditSingleSelectCellProps) {
  const { id, value, field, api, colDef, row } = props;
  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    const regex = /^-?\d{0,3}(\.\d{0,6})?$/;
    if (regex.test(inputValue) || inputValue === '') {
      await api.setEditCellValue({ id: id, field: field, value: inputValue });
    }
  };
  return (
    <TextField
      type="text"
      inputMode="numeric"
      id="inputField"
      value={value}
      onChange={handleInputChange}
      fullWidth
    />
  );
}