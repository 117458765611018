import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells/SingleSelectOnlyOptionsNotUsedCell';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { IDPBitType } from '../../../Interfaces/DPBitType.interface';
import { IBitType } from '../../../Interfaces/BitType.interface';

interface DrillingProgramBitTypesGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramBitTypesGrid: React.FC<DrillingProgramBitTypesGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [bitTypeOptions, setBitTypeOptions] = React.useState<IBitType[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "ID_EA_BitType",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: bitTypeOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} valueField="ID" labelField="BitType" />;
      },
      getOptionValue: (value) => (value as IBitType).ID,
      getOptionLabel: (value) => (value as IBitType).BitType,
      sortComparator: singleSelectComparator,
    },
  ];

  const initialRow: Partial<IDPBitType> = {
    ID_EA_BitType: '',
  };

  const validateFields = async (row: IDPBitType): Promise<boolean> => {
    if (row.ID_EA_BitType === "") {
      await AlertService.showAlert("You must select a Bit Type", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  async function handleFetch(): Promise<IDPBitType[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    DPBitTypes {
                      ID
                      ID_EA_BitType
                    }
                  }
                }
              }
              BitTypes {
                ID
                BitType
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { BitTypes, MiningCompanys } = data.data.Plods[0];
        const { DPBitTypes } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!BitTypes) throw new Error("Missing BitTypes");
        if (!DPBitTypes) throw new Error("Missing DPBitTypes");

        //Sets the location options and returns the sites.
        setBitTypeOptions(BitTypes);
        return DPBitTypes;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDPBitType>
        name="Bit Type"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPBitTypes`}
        columns={columns}
        primaryField="ID_EA_BitType"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) {
              return true;
            }
            else {
              return false;
            }
          }
        }
        canEdit={false}
      />
    </Box>
  );
};
