import React from "react";
import { IDPTaskOption } from '../../../Interfaces/DPTaskOption.interface';
import { GridEditSingleSelectCellProps, } from "@mui/x-data-grid-premium";
import { ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { TaskToolTip } from '../styled/TaskToolTip.styled';

export function CustomTaskEditColumnCell(props: GridEditSingleSelectCellProps & { taskOptions: IDPTaskOption[] },) {
  // const apiRef = useGridApiContext();
  const { id, value, field, api, colDef, row } = props;

  const handleValueChange = async (e: any) => {
    await api.setEditCellValue({
      id,
      field,
      value: e.target.value
    }, e);

    const currentTask: IDPTaskOption | undefined = props.taskOptions.find(
      (row: IDPTaskOption) => row.value === e.target.value
    );
    if (currentTask) {
      const chargeType: number = currentTask.ID_EDP_Task_ChargeType;

      await api.setEditCellValue({
        id: props.id,
        field: "ID_EDP_Task_ChargeType",
        value: chargeType,
      }, e);
      await api.setEditCellValue({
        id: props.id,
        field: "AltChargeQuantity",
        value: null,
      }, e); // just makes it retrigger itself so it works right.
    }
  };
  return (
    <Select
      value={value || ''}
      onChange={handleValueChange}
      fullWidth
    >
      {props.taskOptions.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <TaskToolTip
            key={option.value}
            title={
              <React.Fragment>
                <Typography color="inherit">{option.label}</Typography>
                <div>{option.LongDescription}</div>
              </React.Fragment>
            }
            arrow
            placement="right"
          >
            <ListItemText primary={option.label} />
          </TaskToolTip>
        </MenuItem>
      ))}
    </Select>
  );
}