import {
  Box,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import GenericSelect from "../GenericSelect";
import { TeamMemberPermissionsData } from "./Components";


const TeamMemberPermissionsManagement: React.FunctionComponent = () => {
  let InstanceID = getInstanceID();

  const [teamMemberID, setTeamMemberID] = React.useState('');
  const [teamMemberName, setTeamMemberName] = React.useState('');

  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataError, setDataError] = useState<string | null>(null);
  const [teamMemberOptions, setTeamMemberOptions] = useState<any[]>([]);
  //This needs logic for the selection to appear.
  const fetchData = async () => {
    setTeamMemberOptions([]);
    setDataError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/TeamMembers/`);
      let mappedData = data.map((item: any) => {
        return {
          ...item,
          FullName: item.FirstName + " " + item.LastName
        };
      });
      setTeamMemberOptions(mappedData);
    } catch (e) {
      setTeamMemberOptions([]);
      setDataError("Error occured while loading Team members.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (InstanceID) fetchData();
  }, [InstanceID]);


  function HandleTeamMemberSelectionChange(event: SelectChangeEvent): void {
    setTeamMemberID(event.target.value as string);
    let Name = teamMemberOptions.find((member) => member.ID == event.target.value).FullName;
    setTeamMemberName(Name);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start", // Aligns the button to the right
          alignItems: "flex-start",     // Aligns the button to the bottom
          marginLeft: "10px",          // Pushes the button to the bottom if there's space
        }}
      >
        <GenericSelect<any>
          label="Team Member"
          value={teamMemberID}
          onChange={(event) => HandleTeamMemberSelectionChange(event)}
          options={teamMemberOptions}
          disabled={false}
          valueField="ID"
          labelField="FullName"
          loading={loading}
        />
      </Box >
      {teamMemberID && <TeamMemberPermissionsData TeamMemberID={teamMemberID} TeamMemberName={teamMemberName} />}
    </>
  );
};

export { TeamMemberPermissionsManagement };