
import { Box, Button, FormHelperText, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, styled, TextareaAutosize, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import React from "react";

// Styled TextField to customize its styles
export const FullCellTextField = styled(TextField)(({ theme }) => ({
  marginLeft: '10px', // Space between label and text field
  '& .MuiInputBase-root': {
    color: 'black', // Change text color
    border: 'none', // Remove borders
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none', // Remove bottom border
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none', // Remove bottom border on focus
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove border for outlined variant
    },
  },
  '& .MuiFilledInput-root': {
    '&:before': {
      borderBottom: 'none', // Remove bottom border for filled variant
    },
    '&:after': {
      borderBottom: 'none', // Remove bottom border on focus for filled variant
    },
  },
}));