import React from 'react';
import dayjs from 'dayjs';
import { calculateHourAndMinuteDifference } from '../../functions/calculateHourAndMinuteDifference';
import { FullCellInputLabel } from '../PlodSheet/styled';
import { Box } from '@mui/material';

export const CustomHoursDifferenceColumnCell: React.FC<{ fromTime: dayjs.Dayjs | string | null, toTime: dayjs.Dayjs | string | null }> = ({ fromTime, toTime }) => {
  const diffInMinutes = calculateHourAndMinuteDifference(fromTime, toTime); // Convert to minutes
  const hours = Math.floor(diffInMinutes / 60); // Get hours
  const minutes = diffInMinutes % 60; // Get remaining minutes
  //TODO: Make it look way better than this.
  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      <FullCellInputLabel>{`${hours}h ${minutes}m`}</FullCellInputLabel>
    </Box>
  );
};