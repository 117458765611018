
import { Box, Button, FormHelperText, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, styled, TextareaAutosize, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import React from "react";



export const TaskToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));