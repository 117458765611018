import React from "react";
import { GridEditSingleSelectCellProps, GridSingleSelectColDef } from "@mui/x-data-grid-premium";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getOptionsNotInRows } from '../../functions/getOptionsNotInRows';

interface SingleSelectOnlyOptionsNotUsedCellProps<T> {
  idField?: string;
  labelField?: string;
  valueField?: string;
  getLabelField?: (option: T) => string;
}

//TODO: Update this to automatically work out what field to use. like how sortor does
export function SingleSelectOnlyOptionsNotUsedCell<T>(props: GridEditSingleSelectCellProps & SingleSelectOnlyOptionsNotUsedCellProps<T>) {
  // Set defaults if not provided
  const {
    api, id, field, value,
    valueField = 'value',  // Default to 'value' if not specified
    labelField = 'label',  // Default to 'label' if not specified
    idField = 'ID',         // Default to 'ID' if not specified
    getLabelField,
  } = props;
  // Access all rows
  const rows = Array.from(api.getRowModels().values());

  // Remove current row from the list of rows
  const rowsWithoutCurrent = rows.filter((row: any) => row[idField] !== id);

  // Get the column definition
  const colDefConv = props.colDef as GridSingleSelectColDef;

  // Get all the options
  const options = colDefConv.valueOptions as any[];

  const OptionsNotInRows = options.filter(
    (option) => !rowsWithoutCurrent.some((row: any) => row[field] === option[valueField])
  ); // Filter options not already used in other rows


  const handleChange = async (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    //Set the new value to the id and field.
    await api.setEditCellValue({
      id: id,
      field: field,
      value: newValue,
    });
  };
  return <Select
    fullWidth
    value={value}
    onChange={handleChange}
  >
    {OptionsNotInRows.map((item: any) =>
      <MenuItem value={item[valueField]} key={item[valueField]}>
        {getLabelField ? getLabelField(item) : item[labelField]}
      </MenuItem>
    )}
  </Select>;
}

