import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells/SingleSelectOnlyOptionsNotUsedCell';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { IPersonnelType } from '../../../Interfaces/PersonnelType.interface';
import { IPersonnel } from '../../../Interfaces/Personnel.interface';
import { IDPPersonnel } from '../../../Interfaces/DPPersonnel.interface';

interface DrillingProgramPersonnelsGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramPersonnelsGrid: React.FC<DrillingProgramPersonnelsGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [personnelOptions, setPersonnelOptions] = React.useState<IPersonnel[]>([]);
  const [personnelTypeOptions, setPersonnelTypeOptions] = React.useState<IPersonnelType[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "ID_EA_Personnel",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} valueField="ID" labelField="FirstName" getLabelField={(option: IPersonnel) => `${option.FirstName} ${option.LastName}`} />; // This only does by first which is a problem.
      },
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as IPersonnel).ID,
      getOptionLabel: (value) => (value as IPersonnel).FirstName + " " + (value as IPersonnel).LastName, // TODO: make it so sorter can take this in and exclude the charge by hours junk.
    },
    {
      headerName: "Type",
      field: "ID_EA_PersonnelType",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelTypeOptions,
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as IPersonnelType).ID,
      getOptionLabel: (value) => (value as IPersonnelType).PersonnelTypeName,
    },
  ];

  const initialRow: Partial<IDPPersonnel> = {
    ID_EA_Personnel: '',
    ID_EA_PersonnelType: '',
  };

  const validateFields = async (row: IDPPersonnel): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.ID_EA_Personnel) validationErrors.push("You must select a Personnel.");
    if (!row.ID_EA_PersonnelType) validationErrors.push("You must select a Personnel Type.");

    if (validationErrors.length) {
      //Need to make this show the personnel name. 'Personnel {name} row'
      await AlertService.showAlert(`Personnel row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<IDPPersonnel[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    DPPersonnels {
                      ID
                      ID_EA_Personnel
                      ID_EA_PersonnelType
                    }
                  }
                }
              }
              Personnels {
                ID
                FirstName
                LastName
                
                HRCode
              }
              PersonnelTypes {
                ID
                PersonnelTypeName
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { Personnels, PersonnelTypes, MiningCompanys } = data.data.Plods[0];
        const { DPPersonnels } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!Personnels) throw new Error("Missing Personnels");
        if (!PersonnelTypes) throw new Error("Missing PersonnelTypes");
        if (!DPPersonnels) throw new Error("Missing DPPersonnels");

        //Sets the location options and returns the sites.
        setPersonnelOptions(Personnels);
        setPersonnelTypeOptions(PersonnelTypes);
        return DPPersonnels;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDPPersonnel>
        name="Personnel"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPPersonnels`}
        columns={columns}
        primaryField="ID_EA_Personnel"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) return true;
            else if (params.field == "ID_EA_Personnel") return false;
            else return true;
          }
        }
      />
    </Box>
  );
};
