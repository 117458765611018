
import { InputLabel, styled } from "@mui/material";


//TODO: Move this into like a style sheet or smthing.
// Styled InputLabel to customize its styles
export const FullCellInputLabel = styled(InputLabel)(({ theme }) => ({
  color: 'black',
  marginLeft: '10px', // Space between label and text field
  whiteSpace: 'nowrap', // Prevent label from wrapping
  fontSize: '14px',
  '&.Mui-disabled': {
    color: 'black', // Set disabled label color to black
  },
  flexShrink: 0
}));