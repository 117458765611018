import { Button, SelectChangeEvent, Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DataGridPremium, GridActionsCellItem, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-premium';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from '../functions/getInstanceID';
import { AlertService } from '../services/AlertService';
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import useStandardEditableRowActions from '../components/useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../components/CustomToolbarWithDropdownAndAdd';
import { useNavigate } from 'react-router-dom';


const Contracts: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const navigate = useNavigate(); // Initialize navigate
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>('');
  const [miningCompanyOptions, setMiningCompanyData] = useState([]);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  useEffect(() => {
    if (plodID) fetchData();
  }, [plodID, miningCompanySelectorID]);

  const fetchDropDownData = async () => {
    setMiningCompanySelectorID('');
    setMiningCompanyData([]);
    try {
      const body = `
            query Plods {
                Plods(ID_AP_Plod: ${plodID}) {
                    MiningCompanys {
                        label:MiningCompanyName
                        value:ID_EA_MiningCompany
                    }
                }
            }`;
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const MyData = data?.data.Plods[0].MiningCompanys;
      let localitem = localStorage.getItem("ID_EA_MiningCompany"); //Reads it from tab storage, if it exists then it will set it, if it is in the return data.
      if (localitem != null && MyData.some((x: { value: any; }) => x.value == localitem)) {
        setMiningCompanySelectorID(localitem);
      }
      setMiningCompanyData(MyData);
    } catch (e) {
      setMiningCompanyData([]);
      handleErrorResponse(e, "Error while loading Client selectors"); //TODO: need a better way for this. that actually stops the page.
      setDataGridError(`An error occured while loading Client Selectors, Please try again later.`);
    }
  };

  const fetchData = async () => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (miningCompanySelectorID == '') {
      fetchDropDownData();
    }
    else {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts`);
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading Contracts, Please try again later.`);
      }
    }
    setLoading(false);
  };

  //#region Toolbar header thing 
  //TODO: This needs to have it improved so it actually passes the values in.
  function handleMiningCompanyChange(event: SelectChangeEvent<any>): void {
    localStorage.setItem("ID_EA_MiningCompany", event.target.value); //saves it to tab storage so if they stay on the tab, but go to another page it carrys across.
    setMiningCompanySelectorID(event.target.value);
  };

  //#endregion


  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0].ContractName;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Contract "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Contract "${currentItemName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Contract "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (!newRow.ContractName?.trim()) validationErrors.push("Contract Name cannot be empty.");
    if (!newRow.ContractDescription?.trim()) validationErrors.push("Contract Description cannot be empty.");

    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`Contract row has empty required fields.`, 'criticalerror', errors.join("\n"));
      return;
    }
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
      const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/${endpoint}`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        ID: data.ID ?? newRow.ID,
        isNew: false,
      };
      setDataRows((prev) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Contract "${newRow.ContractName}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Contract "${newRow.ContractName}".`);
    }
    return newRow;
  };

  //#region for the contract setup button
  const ContractConfigButton = (row: any) => {
    if (row.ID) {
      var contractRowsFiltered: any = dataRows.find((row2: any) => row2.ID == row.ID);
      //console.log(contractRowsFiltered);
      if (contractRowsFiltered) {
        function handleContractSetupClick(): void {
          localStorage.setItem("ID_EC_Contract", contractRowsFiltered.ID); //saves it to tab storage so if they stay on the tab, but go to another page it carrys across.
          navigate('/contract-setup');
        }

        return (
          <Button onClick={handleContractSetupClick}>
            Contract Setup
          </Button>
        );
      }
    };
    return (
      <Button disabled>
        Error
      </Button>
    );

  };
  //#endregion

  return (
    <>
      <Box sx={{ height: "90vh", margin: "10px" }}>
        <DataGridPremium sx={{ zIndex: 0 }}
          columns={[
            {
              field: 'ContractName',
              headerName: "Contract Name",
              editable: true,
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'ContractDescription',
              headerName: "Contract Description",
              editable: true,
              flex: 1,
              minWidth: 250,
            },
            {
              field: 'contractSetup',
              type: 'actions',
              headerName: 'Contract Setup',
              width: 150,
              cellClassName: 'Contract Setup',
              getActions: ({ id: ID }) => {
                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;

                return isInEditMode ? [] : [
                  <ContractConfigButton ID={ID} />
                ];
              }
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id: ID }) => {
                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
                return isInEditMode ? [
                  <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
                  <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(ID)} color="inherit" />,
                ] : [
                  <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(ID)} color="inherit" />,
                  <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
                ];
              },
            },
          ]}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          slots={{
            toolbar: () => (<CustomToolBarWithDropDownToolbar
              setDataRows={setDataRows} // where setRigs updates the rig rows
              setDataRowModesModel={setDataRowModesModel} // for setting row modes
              rowTemplate={{ "ContractName": "" }} // define initial fields for rigs
              fieldName={"ContractName"} // focus on RigName field
              itemName={"Contract"}
              disableItems={dataGridError != null || loading}
              displayDropDown={true}  // Show the dropdown
              dropDownOptions={miningCompanyOptions}  // Provide the dropdown options
              dropDownSelectedID={miningCompanySelectorID}  // Current selected value
              handleDropDownChange={handleMiningCompanyChange}  // Correctly typed change handler
              dropdownDataName="Client"  // Name to display in the label
            />),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : !miningCompanySelectorID ? "Please Select a Client" : "No Contract Data"}
                onRetry={dataGridError ? () => fetchData() : undefined}
              />
            ),
          }}
        />
      </Box>
    </>
  );
};

export default Contracts;