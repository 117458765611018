import React, { useEffect } from "react";
import getInstanceID from "../../functions/getInstanceID";
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import CustomCardWrapper from "./CustomCardWrapper";
import CustomCardHeader from "./CustomCardHeader";
import { Api } from "../../api";



export default function TasksPastThirtyDaysPieChart() {
  let instanceID = getInstanceID();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [mydata, setMyData] = React.useState<any[]>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm') || theme.breakpoints.down('md'));

  const fetchData = async () => {
    setMyData([]);
    setLoading(true);
    setErrorMessage(null);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/~GetAllDrillingTaskTypesPastThirtyDays`);

      const uniqueColors = [
        'red', 'blue', 'green', 'orange', 'purple', 'yellow', 'pink'
      ];
      // Sort the data by value in descending order
      const sortedData = data.sort((a: { value: number; }, b: { value: number; }) => b.value - a.value);
      // Process the sorted data
      const processedData: any[] = sortedData.map((item: { [x: string]: any; label: any; }, index: number) => {
        let { label, ...rest } = item; // Destructure to access label and other fields
        const base = { ...rest, customLabel: label }; // Add customLabel

        if (index < 7) {
          // Add color and retain the label field for the top 7

          return { ...base, label, color: uniqueColors[index] || 'grey' };
        } else {
          // Add color and remove the label field for the rest
          return { ...base, color: 'grey' };
        }
      });
      if (processedData.length > 7) {
        const newRow = { label: 'Other', value: 0, color: 'grey' };
        processedData.push(newRow);
      }
      setMyData(processedData);
    } catch (e) {
      setErrorMessage("Failed to fetch data");

      setMyData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  function CustomItemTooltip({ itemData, series }: any) {
    const tooltipData = series.data[itemData.dataIndex];

    return (
      <Box
        sx={{
          p: 1.5,
          backgroundColor: "background.paper",
          borderRadius: "4px",
          boxShadow: 3,
          color: "text.primary",
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          minWidth: "120px",
        }}
      >
        {/* Display Label */}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {tooltipData?.customLabel || "Unknown Label"}
        </Typography>

        {/* Display Value */}
        <Typography variant="body2">
          <strong>Value:</strong> {tooltipData?.value ?? "N/A"}
        </Typography>
      </Box>
    );
  }
  // var x :PieChartProps = 
  return (
    <CustomCardWrapper title={"Total Tasks"} sx={{ height: isSmallScreen ? 250 : 400 }} loading={loading} errorMessage={errorMessage}>
      <CustomCardHeader
        description={"Tasks used in shifts for the past 30 days"}
      />
      {/* Pie Chart from MUI X Charts */}


      <PieChart

        series={[
          {
            data: mydata,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          },
        ]}
        height={isSmallScreen ? 200 : 275}
        width={isSmallScreen ? undefined : 700}
        margin={{ top: 20, bottom: 20, left: 20, right: 130 }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: 0,
            labelStyle: {
              fontSize: isSmallScreen ? 10 : 12, // Adjust font size for small screens
              fill: 'currentColor',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: isSmallScreen ? 80 : 200, // Adjust max width for small screens
            },
          },
        }}
        tooltip={{ trigger: 'item' }}
        slots={{
          itemContent: CustomItemTooltip,
        }}
      />
    </CustomCardWrapper>
  );
}