import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { IUnitOfMeasurement } from '../../../Interfaces/UnitOfMeasurement.interface';
import { ITaskChargeTypes } from "../../../Interfaces/TaskChargeTypes.interface";

interface DrillingProgramTaskChargeRatesGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramTaskChargeRatesGrid: React.FC<DrillingProgramTaskChargeRatesGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = React.useState<IUnitOfMeasurement[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Charge Type",
      field: "ChargeType",
      flex: 1,
      minWidth: 200,
      editable: true,
    },
    {
      headerName: "Rate",
      field: "Rate",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Unit Of Measurement",
      field: "ID_EA_UnitOfMeasurement",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: unitOfMeasurementOptions,
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as IUnitOfMeasurement).ID,
      getOptionLabel: (value) => {
        const parsedValue: IUnitOfMeasurement = value as IUnitOfMeasurement;
        return `${parsedValue.Name}` + (parsedValue.ChargeByHours ? ' (Charged By Hours)' : '');
      }
    },
  ];

  const initialRow: Partial<ITaskChargeTypes> = {
    ChargeType: '',
    Rate: 0,
    ID_EA_UnitOfMeasurement: '',
  };

  const validateFields = async (row: ITaskChargeTypes): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.ChargeType) validationErrors.push("Charge Type cannot be empty.");
    if (!row.Rate) validationErrors.push("Rate cannot be empty.");
    else if (row.Rate < 0) validationErrors.push("Rate cannot be less than 0.");
    if (!row.ID_EA_UnitOfMeasurement) validationErrors.push("You must select an Unit of Measurement.");

    if (validationErrors.length) {
      //Need to make this show the Task Charge Rates name. 'Task Charge Rates {name} row'
      await AlertService.showAlert(`Task Charge Rates row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<ITaskChargeTypes[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    TaskChargeTypes {
                      ID
                      ChargeType
                      Rate
                      ID_EA_UnitOfMeasurement
                    }
                  }
                }
              }
              UnitOfMeasurements{
                ID
                Name
                ChargeByHours
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { UnitOfMeasurements, MiningCompanys } = data.data.Plods[0];
        const { TaskChargeTypes } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!UnitOfMeasurements) throw new Error("Missing UnitOfMeasurements");
        if (!TaskChargeTypes) throw new Error("Missing TaskChargeTypes");

        //Sets the location options and returns the sites.
        setUnitOfMeasurementOptions(UnitOfMeasurements);
        return TaskChargeTypes;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<ITaskChargeTypes>
        name="Task Charge Rate"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/TaskChargeTypes`}
        columns={columns}
        primaryField="ChargeType"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) return true;
            else return true;
          }
        }
      />
    </Box>
  );
};
