import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells/SingleSelectOnlyOptionsNotUsedCell';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { IConsumable } from '../../../Interfaces/Consumable.interface';
import { IDPConsumable } from "../../../Interfaces/DPConsumable.interface";
import { IUnitOfMeasurement } from '../../../Interfaces/UnitOfMeasurement.interface';


interface DrillingProgramConsumablesGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramConsumablesGrid: React.FC<DrillingProgramConsumablesGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [consumableOptions, setConsumableOptions] = React.useState<IConsumable[]>([]);
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = React.useState<IUnitOfMeasurement[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "ID_EA_Consumable",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} valueField="ID" labelField="ConsumableName" />;
      },
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as IConsumable).ID,
      getOptionLabel: (value) => (value as IConsumable).ConsumableName,
    },
    {
      headerName: "Charge",
      field: "Charge",
      flex: 1,
      minWidth: 150,
      editable: true,
      type: "number",
      // Use valueFormatter if you want to format for other cases (e.g., export)
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      },
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Unit Of Measurement",
      field: "ID_EA_UnitOfMeasurement",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: unitOfMeasurementOptions,
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as IUnitOfMeasurement).ID,
      getOptionLabel: (value) => (value as IUnitOfMeasurement).Name + ((value as IUnitOfMeasurement).ChargeByHours ? " (Charged By Hours)" : ''), // TODO: make it so sorter can take this in and exclude the charge by hours junk.
      // // valueFormatter: (params) => {
      //   //? Use this to do the charged by hours thing?
      // }
    },
    {
      headerName: "Default Charge",
      field: "Default_Charge",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
  ];

  const initialRow: Partial<IDPConsumable> = {
    ID_EA_Consumable: '',
    Charge: 0,
    ID_EA_UnitOfMeasurement: '',
    Default_Charge: false,
  };

  const validateFields = async (row: IDPConsumable): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.ID_EA_Consumable) validationErrors.push("You must select a Consumable.");
    if (!row.Charge) validationErrors.push("Charge cannot be empty.");
    else if (row.Charge < 0) validationErrors.push("Charge cannot be less than 0.");
    if (!row.ID_EA_UnitOfMeasurement) validationErrors.push("You must select an Unit of Measurement.");
    if (validationErrors.length) {
      //Need to make this show the consumable name. 'Consumable {name} row'
      await AlertService.showAlert(`Consumable row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<IDPConsumable[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    DPConsumables {
                      ID
                      ID_EA_Consumable
                      Charge
                      ID_EA_UnitOfMeasurement
                      Default_Charge
                    }
                  }
                }
              }
              Consumables {
                ID
                ConsumableName
              }
              UnitOfMeasurements{
                ID
                Name
                ChargeByHours
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { Consumables, UnitOfMeasurements, MiningCompanys } = data.data.Plods[0];
        const { DPConsumables } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!Consumables) throw new Error("Missing Consumables");
        if (!UnitOfMeasurements) throw new Error("Missing UnitOfMeasurements");
        if (!DPConsumables) throw new Error("Missing DPConsumables");

        //Sets the location options and returns the sites.
        setConsumableOptions(Consumables);
        setUnitOfMeasurementOptions(UnitOfMeasurements);
        return DPConsumables;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDPConsumable>
        name="Consumable"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPConsumables`}
        columns={columns}
        primaryField="ID_EA_Consumable"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) return true;
            else if (params.field == "ID_EA_Consumable") return false;
            else return true;
          }
        }
      />
    </Box>
  );
};
