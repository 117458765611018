import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { IPersonnelType } from '../../../Interfaces/PersonnelType.interface';

export const PersonnelTypesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "PersonnelTypeName",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  const initialRow: Partial<IPersonnelType> = {
    PersonnelTypeName: '',
  };

  const validateFields = async (row: IPersonnelType): Promise<boolean> => {
    if (!row.PersonnelTypeName || row.PersonnelTypeName === "") {
      await AlertService.showAlert("Personnel Type Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IPersonnelType>
        name="Personnel Type"
        baseURL={`/api/Plods/ID_${instanceID}/PersonnelTypes/`}
        columns={columns}
        primaryField="PersonnelTypeName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
