import React, { ChangeEvent } from "react";
import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { IDrillingDetailsRow } from '../../../Interfaces/Shift.interface';

export function RenderDrillingDetailsHoleEditCell(props: GridRenderEditCellParams) {
  // Set defaults if not provided
  const {
    api, id, field,
  } = props;

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    //just set the cells value
    api.setEditCellValue({ id, field, value: event.target.value });

    //Whole query takes ~ 3ms max -> may look at making cancel run if a new value is inputed -> will have to look into.
    //#region Get editing state
    // Extract edited rows from the editing state
    const editedRows = Object.entries(api.state.editRows).map(([id, editState]) => {
      const editedRowValues = Object.fromEntries(
        Object.entries(editState).map(([field, fieldState]) => [field, fieldState.value ?? fieldState])
      );
      return { ID: id, ...editedRowValues };
    });

    // Create a Map for quick lookup of edited rows by ID
    const editedRowMap = new Map(editedRows.map(row => [row.ID, row]));

    //Get all the rows available on the grid (could in theory fetch from datarows directly but i much rather do it this way as its more "accurate") -> also helps keep from having to pass down function
    const rows: IDrillingDetailsRow[] = Array.from(api.getRowModels().values()) as IDrillingDetailsRow[];

    // Combine rows with their edited versions where applicable so that I get the most "up to date" version of the rows.
    const combinedRows: IDrillingDetailsRow[] = rows.map(row =>
      editedRowMap.has(row.ID.toString()) ? { ...editedRowMap.get(row.ID.toString()) } : row
    ) as IDrillingDetailsRow[];


    // Get rows with the same hole name as the current row (excluding current row)
    const rowsWithSameName: IDrillingDetailsRow[] = combinedRows.filter(row => row.ID !== id && row.Hole === event.target.value);

    //ensures it has some data, otherwise reduce will throw an error.
    if (rowsWithSameName.length > 0) {

      // Find the row with the latest ToDepth
      const latestDepthRow: IDrillingDetailsRow = rowsWithSameName.reduce((latest, row) => {
        return row.ToDepth > latest.ToDepth ? row : latest;
      }
        , rowsWithSameName[0] // <- initial value, may want to look at doing to make single row.
      );

      //sets value of from depth
      api.setEditCellValue({ id, field: "FromDepth", value: latestDepthRow.ToDepth });
    }
    else {
      //None with same, so set from depth to 0 (default value).
      api.setEditCellValue({ id, field: "FromDepth", value: 0 });

    }
    //#endregion
  }

  return (
    <GridEditInputCell
      {...props}
      onChange={handleChange}
    />
  );
}