import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, useTheme, ThemeProvider, createTheme, Skeleton } from '@mui/material';

type MigratePermission = {
  ID: number;
  statusName: string;
  colour: string;
  statusOrder: number;
  canMigrate: boolean;
};

type Status = {
  ID: number;
  statusName: string;
  colour: string;
  createDoc: boolean;
  readDoc: boolean;
  updatDoc: boolean;
  deleteDoc: boolean;
  viewDoc: boolean;
  statusOrder: number;
  MigratePermission: MigratePermission[];
};


//This is legit due to just the way the data is handled we need to do it this way.
function transformData(data: any[]): Status[] {
  const statusMap: { [key: number]: Status } = {};

  const orderedData = [...data].sort((a, b) => { // this orders it to make sure that the first item is "status" - this is to avoid it skipping data if it can't find the parent.
    if (a.type === "status" && b.type !== "status") {
      return -1; // "status" comes first
    } else if (b.type === "status" && a.type !== "status") {
      return 1; // "status" comes first
    }
    return 0; // If both types are the same, no change
  });

  // Create a map of statuses
  orderedData.forEach(item => {
    const { statusName, colour, statusOrder, actualID, parentActualID, type } = item;
    // Create or update the status object
    if (type == "status" && !statusMap[actualID]) {
      const { createDoc, readDoc, updatDoc, deleteDoc, viewDoc } = item;
      statusMap[actualID] = {
        ID: actualID,
        statusName: statusName,
        colour: colour,
        createDoc: createDoc,
        readDoc: readDoc,
        updatDoc: updatDoc,
        deleteDoc: deleteDoc,
        viewDoc: viewDoc,
        statusOrder: statusOrder,
        MigratePermission: []
      };
    }

    // Handle migration permissions (MigratePermission) for child statuses
    if (parentActualID) {
      const { canMigrate } = item;
      const migratePermission: MigratePermission = {
        ID: actualID,
        statusName: statusName,
        colour: colour,
        statusOrder: statusOrder,
        canMigrate: canMigrate
      };

      // Add the migratePermission to the parent status
      if (statusMap[parentActualID]) {
        statusMap[parentActualID].MigratePermission.push(migratePermission);
      }
    }
  });

  // Return the values from the statusMap as an array
  return Object.values(statusMap);
}

type WorkflowRoleStatusPermissionsDiagramProps = {
  data: any[];
  RoleName: string;
  WorkflowID: string;
  loading: boolean;
};

const WorkflowRoleStatusPermissionsDiagram: React.FC<WorkflowRoleStatusPermissionsDiagramProps> = ({ data, RoleName, WorkflowID, loading }) => {
  const theme = useTheme(); // Access the current theme (light or dark)

  const convertedData: Status[] = transformData(data);
  // Sort statuses by their statusOrder
  const sortedData = [...convertedData].sort((a, b) => a.statusOrder - b.statusOrder);

  // Define circle positions
  const radius = 50;
  const gap = 200; // Space between circles
  const positions = sortedData.map((status, index) => ({
    x: index * gap + radius + 10,
    y: 200, // Start y position for the first status
  }));
  const diagramWidth = (sortedData.length * (gap)) - 80;

  // Track the hovered circle ID
  const [hoveredCircleId, setHoveredCircleId] = useState<number | null>(null);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
      sx={{
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
        height: '500px',
      }}
    >

      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          width: '100%',
          height: '500px',
          backgroundColor: theme.palette.background.paper,
          overflow: 'auto',
        }}
      >

        <Typography sx={{ p: 2 }} variant="h6" gutterBottom color={theme.palette.text.primary}>
          Status Migration Diagram  {WorkflowID ? `- ${RoleName}` : ''}
        </Typography>
        {WorkflowID && <>

          {/* Show Skeleton while loading */}
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width='100%' height='429px' /> //TODO: need to replace this with something better at some point - like a couple circles emulating the "status circles"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', minWidth: diagramWidth, overflow: 'auto' }}
            >
              <svg width={diagramWidth} height="400">
                {/* Render Circles */}
                {sortedData.map((status, index) => {

                  const StrokeDash = status.readDoc ? undefined : "1%"; // If the role doesn't have perms to read, then make it so that the status is dotted.
                  const isArrowFromHoveredCircle = status.ID === hoveredCircleId;
                  const strokeWidth = isArrowFromHoveredCircle ? 4 : 2;

                  return (
                    <g
                      key={status.ID}
                      onMouseEnter={() => setHoveredCircleId(status.ID)}
                      onMouseLeave={() => setHoveredCircleId(null)}
                    >
                      {/* Circle */}
                      <circle
                        cx={positions[index].x}
                        cy={positions[index].y}
                        r={radius}  // Apply scaling to radius instead of transform
                        fill={theme.palette.background.default}
                        fillOpacity={0}
                        stroke={theme.palette.text.primary}
                        strokeWidth={strokeWidth}
                        strokeDasharray={StrokeDash}
                        style={{
                          transition: 'stroke-width 0.3s, stroke-dasharray 0.3s', // Smooth transition for radius and stroke-width
                        }}
                      />
                      {/* Status Name */}
                      <foreignObject
                        x={positions[index].x - radius}
                        y={positions[index].y - radius}
                        width={radius * 2}
                        height={radius * 2}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '14px',  // Transition font-size
                            color: theme.palette.text.primary,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            lineHeight: '1.2',
                            fontWeight: 550,
                          }}
                        >
                          {status.statusName}
                        </div>
                      </foreignObject>
                    </g>
                  );
                }
                )}

                {/* Render Curved Arrows */}
                {sortedData.map((status, index) => {
                  const sourcePosition = positions[index];
                  const sourceTopY = sourcePosition.y - radius;
                  const sourceBottomY = sourcePosition.y + radius;

                  return status.MigratePermission.map((permission) => {
                    if (!permission.canMigrate) return null;
                    if (permission.ID == status.ID) return null;

                    const targetIndex = sortedData.findIndex(
                      (s) => s.ID === permission.ID
                    );
                    const targetPosition = positions[targetIndex];

                    if (targetIndex === -1) return null;

                    const targetTopY = targetPosition.y - radius;
                    const targetBottomY = targetPosition.y + radius;

                    const isGoingUp = permission.statusOrder < status.statusOrder;
                    const midX = (sourcePosition.x + targetPosition.x) / 2;

                    let controlY;
                    let sourceY;
                    let targetY;

                    const distanceX = Math.abs(sourcePosition.x - targetPosition.x);
                    const curveHeight = Math.min(distanceX / 4, 200);

                    if (isGoingUp) {
                      sourceY = sourceBottomY;
                      targetY = targetBottomY;
                      controlY = Math.max(sourceBottomY, targetBottomY) + curveHeight;
                    } else {
                      sourceY = sourceTopY;
                      targetY = targetTopY;
                      controlY = Math.min(sourceTopY, targetTopY) - curveHeight;
                    }

                    const targetX = targetPosition.x - 5;

                    // Determine whether the arrow should be smaller and more transparent
                    const isArrowFromHoveredCircle = status.ID === hoveredCircleId;
                    const arrowOpacity = hoveredCircleId == null || isArrowFromHoveredCircle ? 1 : 0.3; // Dim the arrow if it's not from the hovered circle
                    const strokeWidth = isArrowFromHoveredCircle ? 2.4 : 2; // Shrink the arrow if it's not from the hovered circle


                    const StrokeDash = status.readDoc ? undefined : "1%"; // If the role doesn't have perms to read, then make it so that the status is dotted.
                    return (
                      <path
                        key={`${status.ID}-${permission.ID}`}
                        d={`M ${sourcePosition.x} ${sourceY} Q ${midX} ${controlY} ${targetX} ${targetY}`}
                        stroke={theme.palette.text.primary}
                        strokeDasharray={StrokeDash}
                        fill="transparent"
                        markerEnd="url(#arrowhead)"
                        style={{
                          opacity: arrowOpacity,
                          transformOrigin: 'center',
                          strokeWidth: strokeWidth,
                          transition: 'opacity 0.3s,  stroke-width 0.3s', // Smooth transition
                        }}
                      />
                    );
                  });
                })}

                {/* Arrowhead Marker */}
                <defs>
                  <marker
                    id="arrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="10"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon points="0 0, 10 3.5, 0 7" fill={theme.palette.text.primary} />
                  </marker>
                </defs>
              </svg>
            </Box>
          )}

        </>}
      </Paper>

    </Box>
  );
};


export default WorkflowRoleStatusPermissionsDiagram;
