import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { IUnitOfMeasurement } from '../../../Interfaces/UnitOfMeasurement.interface';

export const UnitOfMeasurementsGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'Name',
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Charge By Hours",
      field: "ChargeByHours",
      editable: true,
      flex: 1,
      minWidth: 50,
      type: 'boolean'
    },
  ];

  const initialRow: Partial<IUnitOfMeasurement> = {
    Name: '',
    ChargeByHours: false,
  };

  const validateFields = async (row: IUnitOfMeasurement): Promise<boolean> => {
    if (!row.Name || row.Name === "") {
      await AlertService.showAlert("Unit of Measurement Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IUnitOfMeasurement>
        name="Unit Of Measurement"
        baseURL={`/api/Plods/ID_${instanceID}/UnitOfMeasurements/`}
        columns={columns}
        primaryField="Name"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
