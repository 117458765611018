import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells/SingleSelectOnlyOptionsNotUsedCell';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { ITask } from '../../../Interfaces/Task.interface';
import { ITaskMetric } from '../../../Interfaces/TaskMetric.interface';
import { IDPTask } from "../../../Interfaces/DPTask.interface";
import { ITaskChargeTypes } from "../../../Interfaces/TaskChargeTypes.interface";

interface ChargeTypeOption extends ITaskChargeTypes {
  UnitOfMeasurement: {
    Name: string
  }[]
}

interface DrillingProgramTasksGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramTasksGrid: React.FC<DrillingProgramTasksGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [taskOptions, setTaskOptions] = React.useState<ITask[]>([]);
  const [chargeTypeOptions, setChargeTypeOptions] = React.useState<ChargeTypeOption[]>([]);
  const [taskMetricOptions, setTaskMetricOptions] = React.useState<ITaskMetric[]>([]);


  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "ID_EA_Task",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} valueField="ID" labelField="TaskName" />;
      },
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as ITask).ID,
      getOptionLabel: (value) => (value as ITask).TaskName,
    },
    {
      headerName: "Charge Rate",
      field: "ID_EDP_Task_ChargeType",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: chargeTypeOptions,
      sortComparator: (v1, v2, cellParams1, cellParams2) => singleSelectComparator(v1, v2, cellParams1, cellParams2, (value) => `${value.ChargeType}`), // this is just so that it doesn't sort by the rate or unit of measurement name
      getOptionValue: (value) => (value as ChargeTypeOption).ID,
      getOptionLabel: (value) => {
        const parsedValue: ChargeTypeOption = value as ChargeTypeOption;
        return `${parsedValue.ChargeType} (${parsedValue.UnitOfMeasurement[0].Name}) $${parsedValue.Rate}`;
      }
      , // TODO: make it so sorter can take this in and exclude the charge by hours junk.
      // // valueFormatter: (params) => {
      //   //? Use this to do the Unit Of Measurement Name thing?
      // }
    },
    {
      headerName: "Charge Rate Locked",
      field: "ChargeType_Locked",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Is Drilling Task",
      field: "IsDrillingTask",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Task Metric",
      field: "Task_Metric",
      flex: 1,
      minWidth: 200,
      editable: true,
      headerAlign: "left",
      align: "left",
      type: "singleSelect",
      valueOptions: taskMetricOptions,
      sortComparator: singleSelectComparator,
      getOptionValue: (value) => (value as ITaskMetric).ID,
      getOptionLabel: (value) => (value as ITaskMetric).TaskMetric,
    },
  ];

  const initialRow: Partial<IDPTask> = {
    ID_EA_Task: '',
    ID_EDP_Task_ChargeType: '',
    ChargeType_Locked: false,
    IsDrillingTask: false,
  };

  const validateFields = async (row: IDPTask): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.ID_EA_Task) validationErrors.push("You must select a Task.");
    if (!row.ID_EDP_Task_ChargeType) validationErrors.push("You must select a Charge Rate.");
    if (validationErrors.length) {
      //Need to make this show the Task name. 'Task {name} row'
      await AlertService.showAlert(`Task row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<IDPTask[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    DPTasks {
                      ID
                      ID_EA_Task
                      ID_EDP_Task_ChargeType
                      ChargeType_Locked
                      IsDrillingTask
                      Task_Metric
                    }
                    TaskChargeTypes {
                      ID
                      ChargeType
                      Rate
                      ID_EA_UnitOfMeasurement
                      UnitOfMeasurement {
                        Name
                      }
                    }
                  }
                }
              }
              Tasks {
                ID
                TaskName
              }
              TaskMetrics {
                ID
                TaskMetric
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { Tasks, TaskMetrics, MiningCompanys } = data.data.Plods[0];
        const { DPTasks, TaskChargeTypes } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!Tasks) throw new Error("Missing Tasks");
        if (!TaskMetrics) throw new Error("Missing TaskMetrics");
        if (!DPTasks) throw new Error("Missing DPTasks");
        if (!TaskChargeTypes) throw new Error("Missing TaskChargeTypes");

        //Sets the options and returns the sites.
        setTaskOptions(Tasks);
        setTaskMetricOptions(TaskMetrics);
        setChargeTypeOptions(TaskChargeTypes);
        return DPTasks;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDPTask>
        name="Task"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPTasks`}
        columns={columns}
        primaryField="ID_EA_Task"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) return true;
            else if (params.field == "ID_EA_Task") return false;
            else return true;
          }
        }
      />
    </Box>
  );
};
