import React from "react";
import { GridEditSingleSelectCell, GridEditSingleSelectCellProps, GridSingleSelectColDef } from "@mui/x-data-grid-premium";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface SetAnotherFieldFromSelectedOptionCellProps {
  idField?: string;
  labelField?: string;
  valueField?: string;
  fieldstogetandsetonchange?: {
    field: string,
    fieldtogetvalue: any,
  }[];
  fieldstoeditonchange?: {
    field: string,
    value: any,
  }[],
  disabled?: boolean;
}

export function SetAnotherFieldFromSelectedOptionCell(props: GridEditSingleSelectCellProps & SetAnotherFieldFromSelectedOptionCellProps) {
  // Set defaults if not provided
  const {
    api, id, field,
    valueField = 'value',  // Default to 'value' if not specified
    fieldstogetandsetonchange,
    fieldstoeditonchange,
  } = props;
  // Get the column definition
  const colDefConv = props.colDef as GridSingleSelectColDef;

  // Get all the options
  const options = colDefConv.valueOptions as any[];

  // Handle the change
  const handleChange = async (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    //Set the new value to the id and field.
    await api.setEditCellValue({
      id: id,
      field: field,
      value: newValue,
    });

    //This loops through and does editing of the event
    if (fieldstogetandsetonchange) {
      const selecteditem: any = options.find(
        (option: any) => option[valueField] == newValue
      );
      fieldstogetandsetonchange.forEach(fieldToEdit => {
        let availableValue = null;
        if (selecteditem) {
          availableValue = selecteditem[fieldToEdit.fieldtogetvalue];
        }
        api.setEditCellValue({
          id,
          field: fieldToEdit.field,
          value: availableValue
        });
      });
    }

    //Go through all the ones with a set value.
    if (fieldstoeditonchange) {
      fieldstoeditonchange.forEach(fieldToEdit => {
        api.setEditCellValue({ id, field: fieldToEdit.field, value: fieldToEdit.value });
      });
    }
  };

  return <GridEditSingleSelectCell {...props} onValueChange={handleChange} disabled={props.disabled} />;
}

