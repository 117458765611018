import { SelectChangeEvent, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGridPremium, GridColDef, GridEditSingleSelectCellProps, GridRowId, GridRowModel, GridRowModesModel } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../../../api";
import getInstanceID from '../../../functions/getInstanceID';
import { AlertService } from '../../../services/AlertService';
import CustomNoRowsOverlay from '../../CustomNoRowsOverlay';
import useStandardEditableRowActions from '../../useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../../CustomToolbarWithDropdownAndAdd';
import { getActionsForRow } from '../../EditableRowHandlers';
import { IGenericDropDownOption } from '../../../Interfaces/GenericDropDownOption.interface';
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';

export const WorkflowConfigGrid: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [workflowID, setWorkflowID] = useState<string>('');
  const [workflowOptions, setWorkflowOptions] = useState<IGenericDropDownOption[]>([]);
  const [statusOptions, setStatusOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  useEffect(() => {
    if (plodID) fetchData();
  }, [plodID, workflowID]);

  //A big query for all the values it will need.
  const fetchDropDownData = async () => {
    setWorkflowID('');
    setWorkflowOptions([]);
    setStatusOptions([]);
    try {
      const body = {
        query: `{
                  Plods(ID_AP_Plod: ${plodID}) {
                    Workflows {
                      label:WorkflowName
                      value:ID_WF_Workflow
                    }
                    Statuses {
                      label:StatusName
                      value:ID_WF_Status
                    }
                  }
                }`
      };
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const { Workflows, Statuses } = data.data.Plods[0];
      setWorkflowOptions(Workflows);
      setStatusOptions(Statuses);
    } catch (e) {
      setWorkflowOptions([]);
      setStatusOptions([]);
      // probably could put somewhere else tbh
      setDataGridError(`An error occured while loading Selectors, Please try again later.`);
    }
  };

  const fetchData = async () => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (!workflowID) {
      fetchDropDownData();
    }
    else {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${plodID}/Workflows/ID_${workflowID}/WorkflowStatuses`);
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading Workflow Statuses Please try again later.`);
      }
    }
    setLoading(false);
  };

  function handleWorkflowChange(event: SelectChangeEvent<any>): void {
    setWorkflowID(event.target.value);
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemID = await dataRows.filter((row) => row.ID === ID)[0].ID_WF_Status;
    const currentItemName: string = await statusOptions.filter((status) => status.value == currentItemID)[0]?.label;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Workflow Status "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Workflows/ID_${workflowID}/WorkflowStatuses/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Workflow Status "${currentItemName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Workflow Status "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors: string[] = [];
    if (newRow.ID_WF_Status == null) validationErrors.push("Status cannot be empty.");
    if (newRow.StatusOrder == null) validationErrors.push("Status Order Number cannot be empty.");
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`Workflow Status has empty required fields.`, 'criticalerror', errors.join("\n"));
      return;
    }
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
      const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/Workflows/ID_${workflowID}/WorkflowStatuses/${endpoint}`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        ID: data.ID ?? newRow.ID,
        isNew: false,
      };
      setDataRows((prev) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Workflow Status "${newRow.BitName}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Workflow Status "${newRow.BitName}".`);
    }
    return newRow;
  };

  const columns: GridColDef[] = [
    {
      field: "ID_WF_Status",
      headerName: "Status",
      editable: true,
      flex: 1,
      type: "singleSelect",
      valueOptions: statusOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} />;
      },
      sortComparator: singleSelectComparator

    },
    {
      field: "StatusOrder",
      headerName: "Status Order Number",
      editable: true,
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      //TODO: Make it so that this has to be unique.
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      resizable: false,
      getActions: ({ id: ID }) => getActionsForRow({
        ID,
        rowModesModel: dataRowModesModel,
        handlers: { handleDeleteClick, handleCancelClick, handleEditClick, handleSaveClick },
        actions: { cancel: true, edit: true, save: true, delete: true },
      }),
    }
  ];

  return (
    <>
      <Box sx={{ height: "90vh", margin: "10px" }}>
        <DataGridPremium sx={{ zIndex: 0 }}
          columns={columns}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          slots={{
            toolbar: () => (
              <CustomToolBarWithDropDownToolbar
                setDataRows={setDataRows}
                setDataRowModesModel={setDataRowModesModel}
                rowTemplate={{}}
                fieldName={"ID_WF_Status"}
                itemName={"Workflow Status"}
                disableItems={dataGridError != null || loading || !workflowID}
                displayDropDown={true}
                dropDownOptions={workflowOptions}
                dropDownSelectedID={workflowID}
                handleDropDownChange={handleWorkflowChange}
                dropdownDataName="Workflows"
              />),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : !workflowID ? "Please Select a Workflow" : "No Workflow Status Data"}
                onRetry={dataGridError ? () => fetchData() : undefined}
              />
            ),
          }}
          disableAggregation
          disableColumnPinning
          disableRowGrouping
          disableColumnFilter
          disableColumnSelector
        />
      </Box>
    </>
  );
};
