//#region Imports!
import React, { useEffect, useState } from "react";
import { getActionsForRow, useEditableRowHandlers } from '../EditableRowHandlers';
import CustomPlodSheetDataGrid from './CustomPlodSheetDataGrid';
import {
  GridColDef,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Radio, RadioGroup, SelectChangeEvent, Stack, TextField, } from "@mui/material";
import { IGenericDropDownOption } from '../../Interfaces/GenericDropDownOption.interface';
import { StatusMigrateOption } from './StatusMigrateOption';
import NewPlodBox from "./newPlodBox";
import dayjs, { Dayjs } from "dayjs";
import { Api, handleErrorResponse } from "../../api";
import { AlertService } from "../../services/AlertService";
import GenericSelect from "../GenericSelect";
import validateAndSet from "../../functions/ValidAndSetData";
import DatePickerWithLoading from "../DatePickerWithLoading";
import { ConsumableRow, IDrillingDetailsRow, IEntireShiftData, OneOffChargeRow, IPersonnelRow, ITaskRow, ITaskDrillingDetailsComboRow, IStatusWithOrder, IShiftDropdownOptions } from "../../Interfaces/Shift.interface";
import { IDPTaskOption } from '../../Interfaces/DPTaskOption.interface';
import { ITaskChargeTypeOption } from "../../Interfaces/ITaskChargeTypeOption.interface";
import { IDPPersonnelOption } from "../../Interfaces/DPPersonnelOption";
import { IDPConsumableOption } from "../../Interfaces/DPConsumableOption.interface";
import { DrillingDetailsGrid, TasksGrid, ActivityOverviewTable, PersonnelsGrid, ConsumablesGrid, OneOffChargesGrid } from "./Grids";
import { ValidateFields } from ".";
import { IShiftUpdateResponse } from '../../Interfaces/ShiftUpdateResponse.interface';
//#endregion

//#region TYPES





type BigFiltersRequest = {
  DPConsumables: IGenericDropDownOption[];
  DPTasks: IDPTaskOption[];
  DPPersonnels: IDPPersonnelOption[];
  DPSites: IGenericDropDownOption[];
  DPRigs: IGenericDropDownOption[];
  DPObjectives: IGenericDropDownOption[]
  OneOffCharges: IGenericDropDownOption[];
  TaskChargeTypes: ITaskChargeTypeOption[];
  Statuses: IStatusWithOrder[];
  PersonnelTypes: IGenericDropDownOption[];
  BitSizes: IGenericDropDownOption[];
}


//#endregion

//#region turning things on and off.
// let DrillingDetailsTimeConfig: "FromToTime" | "Hours" | "None" = 'FromToTime'; // FromToTime | Hours | None

// let TaskTimeConfig: "FromToTime" | "Hours" = 'FromToTime'; // FromToTime | Hours | None

// let NewDrillingDetailsDefaultFromTime: string = 'FromDrillingTask'; // LatestToTime | FromDrillingTask | None

// let NewTasksDefaultFromTime: string = 'LatestToTime'; // FromToTime | None

// let showActivityOverviewTable: boolean = true;
// let activityOverviewTableEditable: boolean = false;

//#endregion


export interface PlodDetailsConfigProps {
  DrillingDetailsTimeConfig: 'FromToTime' | 'Hours' | 'None';
  TaskTimeConfig: 'FromToTime' | 'Hours';
  NewDrillingDetailsDefaultFromTime: 'LatestToTime' | 'FromDrillingTask' | 'None';
  NewTasksDefaultFromTime: 'LatestToTime' | 'None';
  showActivityOverviewTable: boolean;
  activityOverviewTableEditable: boolean;
  canChangeTaskChargeType: boolean;
  canEditPersonnelType: boolean;
}

interface PlodDetailsProps {
  existingRowsData?: IEntireShiftData | null
  onStatusValueChange?: (newShiftValues: IShiftUpdateResponse) => void;
  selectorData: {
    save: boolean;
    plodID: string;
    drillingProgramID: string;
    contractID: string;
    miningCompanyID: string;
    shiftID: number | null;
  }
  dropdownOptions: IShiftDropdownOptions
  disabled?: boolean;
  canEdit: boolean;
  plodsheetConfig: PlodDetailsConfigProps
}


const PlodDetails: React.FC<PlodDetailsProps> = (
  {
    selectorData,
    existingRowsData,
    onStatusValueChange,
    disabled = false,
    dropdownOptions,
    canEdit,
    plodsheetConfig
  }
) => {
  const { save, plodID, drillingProgramID, contractID, miningCompanyID, shiftID } = selectorData; // just makes it neater and then expands when we use.

  // const { shiftOptions, consumableOptions } = optionsData;
  //This so dont have to do props. all the time (will look into better ways)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);


  //#region Rows setup
  const [personnelRowModesModel, setPersonnelRowModesModel] = React.useState<GridRowModesModel>({});
  const [personnelRows, setPersonnelRows] = useState<IPersonnelRow[]>([]); // Assuming operatorRows is of type any[]

  const [drillingDetailsRowModesModel, setDrillingDetailsRowModesModel] = React.useState<GridRowModesModel>({});
  const [drillingDetailsRows, setDrillingDetailsRows] = React.useState<IDrillingDetailsRow[]>([]);
  const [taskRowModesModel, setTaskRowModesModel] = React.useState<GridRowModesModel>({});
  const [taskRows, setTaskRows] = React.useState<ITaskRow[]>([]);
  const [consumableRowModesModel, setConsumableRowModesModel] = React.useState<GridRowModesModel>({});
  const [consumableRows, setConsumableRows] = React.useState<ConsumableRow[]>([]);
  const [oneOffChargeRowModesModel, setOneOffChargeRowModesModel] = React.useState<GridRowModesModel>({});
  const [oneOffChargeRows, setOneOffChargeRows] = React.useState<OneOffChargeRow[]>([]);
  const [shiftComments, setShiftComments] = React.useState<string>("");
  const [clientComments, setClientComments] = React.useState<string>("");

  //#endregion

  //#region Selector Setup
  const shiftOptions: IGenericDropDownOption[] = [{ label: "Day", value: "Day" }, { label: "Night", value: "Night" }];
  const [consumableOptions, setConsumableOptions] = React.useState<IDPConsumableOption[]>([]);
  const [taskOptions, setTaskOptions] = React.useState<IDPTaskOption[]>([]);
  const [personnelOptions, setPersonnelOptions] = React.useState<IDPPersonnelOption[]>([]);
  const [siteOptions, setSiteOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [rigOptions, setRigOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [objectiveOptions, setObjectiveOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [oneOffChargeOptions, setOneOffChargeOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [taskChargeTypeOptions, setTaskChargeTypeOptions] = React.useState<ITaskChargeTypeOption[]>([]);
  const [personnelTypeOptions, setPersonnelTypeOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [bitSizeOptions, setBitSizeOptions] = React.useState<IGenericDropDownOption[]>([]);
  const [bitTypeOptions, setBitTypeOptions] = React.useState<IGenericDropDownOption[]>([]);

  //#endregion

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);
  const [objectiveFilter, setObjectiveFilter] = React.useState<string>('');
  const [dayNightFilter, setDayNightFilter] = React.useState<string>('');
  const [siteFilter, setSiteFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('');
  const [rigFilter, setRigFilter] = React.useState<string>('');

  const [statusPromoteFilter, setStatusPromoteFilter] = React.useState<string>('');


  //#region Populating Shift Options Data. (Look at moving this out.)
  const ClearAllData = () => {
    //The rows and stuff
    setPersonnelRows([]);
    setDrillingDetailsRows([]);
    setTaskRows([]);
    setConsumableRows([]);
    setOneOffChargeRows([]);
    setShiftComments("");
    setClientComments("");
    //The filters
    setConsumableOptions([]);
    setTaskOptions([]);
    setPersonnelOptions([]);
    setSiteOptions([]);
    setRigOptions([]);
    setObjectiveOptions([]);
    setOneOffChargeOptions([]);
    setTaskChargeTypeOptions([]);


    setSiteFilter('');
    setRigFilter('');
    setObjectiveFilter('');
    setDayNightFilter('');
    setDateValue(null);
  };

  React.useEffect(() => {
    if (drillingProgramID) {
      fetchDropdownData();
    };
  }, [drillingProgramID]);


  const fetchDropdownData = async () => {
    if (!drillingProgramID) return;
    setLoading(true);
    ClearAllData();
    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~GetAllValuesForShift` //?Look into potentially making this come off of shift, then can have it point status correctly.
      );

      validateAndSet(data.DPConsumables, setConsumableOptions, "Consumables are missing");
      validateAndSet(data.DPTasks, setTaskOptions, "Tasks are missing");
      validateAndSet(data.DPPersonnels, setPersonnelOptions, "Personnels are missing");
      validateAndSet(data.DPSites, setSiteOptions, "Sites are missing");
      validateAndSet(data.DPRigs, setRigOptions, "Rigs are missing");
      validateAndSet(data.DPObjectives, setObjectiveOptions, "Objectives are missing");
      validateAndSet(data.OneOffCharges, setOneOffChargeOptions, "One Off Charges are missing");
      validateAndSet(data.TaskChargeTypes, setTaskChargeTypeOptions, "Task Charge Types are missing");
      validateAndSet(data.PersonnelTypes, setPersonnelTypeOptions, "PersonnelTypes are missing");
      validateAndSet(data.BitSizes, setBitSizeOptions, "Bit Sizes are missing");
      validateAndSet(data.BitTypes, setBitTypeOptions, "Bit Types are missing");


    } catch (e) {
      ClearAllData();
      handleErrorResponse(e, "Error fetching filter options");
    }
    setLoading(false);
  };


  React.useEffect(() => {
    if (save) {
      if (dropdownOptions.Statuses.length > 0 && save) { // this is purely just to set the initial value lol.
        setStatusFilter(dropdownOptions.Statuses[0].ID.toString());
      }
      else {
        setStatusFilter('');
      }
    }
  }, [dropdownOptions.Statuses]);



  React.useEffect(() => {
    setExistingRows();
  }, [existingRowsData]);

  const setExistingRows = () => {
    if (shiftID && existingRowsData) {
      setPersonnelRows(existingRowsData.Operators);
      setDrillingDetailsRows(existingRowsData.DrillingDetails);
      setTaskRows(existingRowsData.Tasks);
      setConsumableRows(existingRowsData.Consumables);
      setOneOffChargeRows(existingRowsData.OneOffCharges);
      setShiftComments(existingRowsData.ShiftComment);
      setClientComments(existingRowsData.ClientComments);
      setSiteFilter(existingRowsData.ID_EA_Site);
      setRigFilter(existingRowsData.ID_EA_Rig);
      setObjectiveFilter(existingRowsData.ID_EA_Objective ?? ''); // probs better way to do this, but once they required will be easier.
      setStatusFilter(existingRowsData.ID_WF_Status);
      setDayNightFilter(existingRowsData.DayNight);
      if (existingRowsData.Date != null) {
        setDateValue(dayjs(existingRowsData.Date));
      }
    }
  };

  //#endregion 


  const createDataJSON = (): IEntireShiftData => {
    const updateTimeFields = (list: any[]) => {
      return list.map(item => {
        return {
          ...item, // Preserve other fields
          FromTime: item.FromTime && dayjs(item.FromTime).isValid() ? dayjs(item.FromTime).format("YYYY/MM/DDTHH:mm:ss") : null,//? idk why its this format
          ToTime: item.ToTime && dayjs(item.ToTime).isValid() ? dayjs(item.ToTime).format("YYYY/MM/DDTHH:mm:ss") : null, //? idk why its this format
        };
      });
    };

    return {
      Date: dateValue ? dateValue.format("MM/DD/YYYY").toString() : "",
      DayNight: dayNightFilter,
      ID_EA_Site: siteFilter,
      ID_EA_Objective: objectiveFilter,
      ID_EA_Rig: rigFilter,
      ID_WF_Status: save ? statusFilter : statusPromoteFilter, // if we are not saving, use the promotes value.
      Operators: personnelRows,
      DrillingDetails: updateTimeFields(drillingDetailsRows), // Returns new array
      Tasks: updateTimeFields(taskRows), // Returns new array
      Consumables: consumableRows,
      OneOffCharges: oneOffChargeRows,
      ShiftComment: shiftComments,
      ClientComments: clientComments,
    };
  };


  const handleDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    interface IEntireShiftDataExport extends IEntireShiftData {
      ID_EA_MiningCompany: string | number;
      ID_EC_Contract: string | Number;
      ID_EDP_Drilling_Program: string | number;
      ID_SH_Shift?: string | number | null; // this is an optional value
    }

    const dataJSON: IEntireShiftData = createDataJSON();

    //Give it the selectors values as well.
    const exportData: IEntireShiftDataExport = {
      ...dataJSON,
      ID_EA_MiningCompany: miningCompanyID,
      ID_EC_Contract: contractID,
      ID_EDP_Drilling_Program: drillingProgramID,
      ID_SH_Shift: shiftID, // this is optional to pass in.
    };

    const stringData = JSON.stringify(exportData);
    const blob = new Blob([stringData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const name = `${dateValue ? dateValue.format('DD_MM_YYYY') : "UnknownDate"}_${dayNightFilter ? dayNightFilter : "UnknownShift"}_PlodSheet.plod`; // is a .plod cause it looks cool, might encrypt idk.
    link.download = name;
    link.click();
  };


  const handleSubmitButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {

    if (!canEdit && !save) {
      if (statusFilter == "") {
        await AlertService.showAlert(`Error with data.`, 'criticalerror', "Status cannot be empty");
      }
      else {
        try {
          const url = `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/Shifts/ID_${shiftID}/~UpdateShiftStatus`;
          const { data } = await Api.post(url, { ID_WF_Status: save ? statusFilter : statusPromoteFilter });
          let responseData: IShiftUpdateResponse = data;
          if (onStatusValueChange) {
            //Uses response data
            onStatusValueChange(responseData);
          }
          await AlertService.showAlert(`Successfully Updated Shift Status.`, "success");
        } catch (e) {
          handleErrorResponse(e, "Failed to Update Shift Status.");
        }
      }
    }
    else {

      const dataJSON: IEntireShiftData = createDataJSON();


      const validationRules = {
        MustHaveObjective: true,
        PersonnelMustHaveData: true,
        DrillingDetailsTimeConfig: plodsheetConfig.DrillingDetailsTimeConfig,
        TaskTimeConfig: plodsheetConfig.TaskTimeConfig,
      };
      const errors: string[] = ValidateFields(dataJSON, validationRules);


      if (errors.length) {
        await AlertService.showAlert(`Error with data.`, 'criticalerror', errors.join("\n"));
      }

      else {
        try {
          const url = save
            ? `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~AddEntireShift`
            : `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/Shifts/ID_${shiftID}/~UpdateEntireShift`;
          const { data } = await Api.post(url, dataJSON);
          let responseData: IShiftUpdateResponse = data;
          if (onStatusValueChange) {
            //Uses response data
            onStatusValueChange(responseData);
          }
          if (!save) {
            setStatusFilter(statusPromoteFilter);//Set the display one to its new one.
          }
          await AlertService.showAlert(`Successfully ${save ? "Added" : "Updated"} Shift.`, "success");
        } catch (e) {
          handleErrorResponse(e, save ? "failed to save Shift." : "failed to update data.");
        }
      };
    }
  };
  //#endregion

  //#region Shift Information

  const handleObjectiveFilterChange = (event: SelectChangeEvent) => {
    setObjectiveFilter(event.target.value as string);
  };

  const handleDayNightFilterChange = (event: SelectChangeEvent) => {
    setDayNightFilter(event.target.value as string);
  };
  const handleSiteFilterChange = (event: SelectChangeEvent) => {
    setSiteFilter(event.target.value as string);
  };
  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
  };
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
  };
  //#endregion




  let isDisabled = errorMessage != null || disabled || !canEdit || dropdownOptions.loadingDropdownOptions || loading;

  return (
    <>
      <Stack style={{ marginLeft: 10, marginRight: 10 }}>
        <NewPlodBox
          color="rgb(145, 149, 154)"
          text="Shift Information"
          height="100%"
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap', // Allow wrapping of items
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0,
              '@media (max-width: 100px)': {
                flexDirection: 'column', // Stack items vertically on smaller screens
                alignItems: 'stretch', // Stretch to fill the available width
              },
            }}
          >
            <DatePickerWithLoading
              label="Date"
              dateValue={dateValue}
              setDateValue={setDateValue}
              disabled={isDisabled}
              loading={loading} // Pass your loading state here
            />
            <GenericSelect<IGenericDropDownOption>
              label="Shift"
              value={dayNightFilter}
              onChange={handleDayNightFilterChange}
              options={shiftOptions}
              disabled={isDisabled}
              valueField="value"
              labelField="label"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap', // Allow wrapping of items
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0,
              '@media (max-width: 100px)': {
                flexDirection: 'column', // Stack items vertically on smaller screens
                alignItems: 'stretch', // Stretch to fill the available width
              },
            }}
          >
            <GenericSelect<IGenericDropDownOption>
              label="Rig"
              value={rigFilter}
              onChange={handleRigFilterChange}
              options={rigOptions}
              disabled={!save || isDisabled}
              valueField="value"
              labelField="label"
              loading={loading}
            />
            <GenericSelect<IGenericDropDownOption>
              label="Site"
              value={siteFilter}
              onChange={handleSiteFilterChange}
              options={siteOptions}
              disabled={!save || isDisabled}
              valueField="value"
              labelField="label"
              loading={loading}
            />
            <GenericSelect<IGenericDropDownOption>
              label="Objective"
              value={objectiveFilter}
              onChange={handleObjectiveFilterChange}
              options={objectiveOptions}
              disabled={isDisabled}
              valueField="value"
              labelField="label"
              loading={loading}
            />
            <GenericSelect<IStatusWithOrder>
              label="Status"
              plurificationText="es"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              options={dropdownOptions.Statuses}
              disabled={true} // locks it to the first item
              valueField="ID"
              labelField="StatusName"
              loading={dropdownOptions.loadingDropdownOptions}
            />
          </Box>
        </NewPlodBox >

        <PersonnelsGrid
          canEditPersonnelType={plodsheetConfig.canEditPersonnelType}
          personnelOptions={personnelOptions}
          personnelTypeOptions={personnelTypeOptions}
          personnelRows={personnelRows}
          personnelRowModesModel={personnelRowModesModel}
          setPersonnelRowModesModel={setPersonnelRowModesModel}
          setPersonnelRows={setPersonnelRows}
          loading={loading}
          disabled={isDisabled}
          canEdit={canEdit}
        />

        <TasksGrid
          canChangeTaskChargeType={plodsheetConfig.canChangeTaskChargeType}
          TaskTimeConfig={plodsheetConfig.TaskTimeConfig}
          taskOptions={taskOptions}
          taskChargeTypeOptions={taskChargeTypeOptions}
          tasksRows={taskRows}
          tasksRowModesModel={taskRowModesModel}
          setTasksRowModesModel={setTaskRowModesModel}
          setTasksRows={setTaskRows}
          NewTasksDefaultFromTime={plodsheetConfig.NewTasksDefaultFromTime}
          loading={loading}
          disabled={isDisabled}
          canEdit={canEdit}
        />

        <DrillingDetailsGrid
          DrillingDetailsTimeConfig={plodsheetConfig.DrillingDetailsTimeConfig}
          bitSizeOptions={bitSizeOptions}
          bitTypeOptions={bitTypeOptions}
          drillingDetailsRows={drillingDetailsRows}
          drillingDetailsRowModesModel={drillingDetailsRowModesModel}
          setDrillingDetailsRowModesModel={setDrillingDetailsRowModesModel}
          setDrillingDetailsRows={setDrillingDetailsRows}
          NewDrillingDetailsDefaultFromTime={plodsheetConfig.NewDrillingDetailsDefaultFromTime}
          loading={loading}
          disabled={isDisabled}
          canEdit={canEdit}
          taskRows={taskRows}
          taskOptions={taskOptions}
        />

        {//The activity slider grid will only appear if there is a visible grid with FromToTime configured
          (((plodsheetConfig.TaskTimeConfig === "FromToTime") ||
            (plodsheetConfig.DrillingDetailsTimeConfig === "FromToTime")) && (plodsheetConfig.showActivityOverviewTable)) && (
            <ActivityOverviewTable
              DrillingDetailsTimeConfig={"FromToTime"}
              TaskTimeConfig={"FromToTime"}
              taskRows={taskRows}
              setDrillingDetailsRows={setDrillingDetailsRows}
              setTaskRows={setTaskRows}
              taskOptions={taskOptions}
              disabled={isDisabled || loading}
              canEdit={canEdit && plodsheetConfig.activityOverviewTableEditable} />
          )}

        <ConsumablesGrid
          consumableOptions={consumableOptions}
          consumableRows={consumableRows}
          consumableRowModesModel={consumableRowModesModel}
          setConsumableRowModesModel={setConsumableRowModesModel}
          setConsumableRows={setConsumableRows}
          loading={loading}
          disabled={isDisabled}
          canEdit={canEdit}
        />

        <OneOffChargesGrid
          oneOffChargeOptions={oneOffChargeOptions}
          oneOffChargeRows={oneOffChargeRows}
          oneOffChargeRowModesModel={oneOffChargeRowModesModel}
          setOneOffChargeRowModesModel={setOneOffChargeRowModesModel}
          setOneOffChargeRows={setOneOffChargeRows}
          loading={loading}
          disabled={isDisabled}
          canEdit={canEdit}
        />

        <Grid
          container
          spacing={{ xs: 0, lg: 2 }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-end"
          sx={{
            margin: '10px',
          }}
          columns={{ xs: 8, lg: 16 }} // make it so the lines are smaller when it is a mobile screen.
        >
          <Grid
            size={8}
            marginBottom={{ xs: 1, lg: 0 }} //allows for some space on small screens
          >
            <NewPlodBox
              color="#0a0808"
              text="Comments about the shift"
              height="150px"
              containermargin="0px"
              labelmargin="0px"
            >
              <TextField
                style={{
                  width: "100%", // Full width of the container
                  maxHeight: "200px", // Fixed height
                }}
                multiline
                value={shiftComments}
                onChange={(e) => setShiftComments(e.target.value)}
                rows={5}
                disabled={isDisabled}
              />
            </NewPlodBox>
          </Grid>
          <Grid size={8}>
            <NewPlodBox
              color="#0a0808"
              text="Client Comments"
              height="150px"
              containermargin="0px"
              labelmargin="0px"
            >
              <TextField
                style={{
                  width: "100%", // Full width of the container
                  maxHeight: "200px", // Fixed height
                }}
                multiline
                value={clientComments}
                onChange={(e) => setClientComments(e.target.value)}
                rows={5}
                disabled={isDisabled}
              />
            </NewPlodBox>
          </Grid>
        </Grid>

        {!save && // this is a way to make it easier for "can promote"
          <StatusMigrateOption
            statusOptions={dropdownOptions.Statuses}
            statusFilter={statusFilter}
            statusPromoteFilter={statusPromoteFilter}
            setStatusPromoteFilter={setStatusPromoteFilter}
            loading={dropdownOptions.loadingDropdownOptions}
            disabled={errorMessage != null || disabled || dropdownOptions.loadingDropdownOptions || loading}
          />
        }

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-end"
          sx={{
            marginX: '10px', // shorthand for both marginLeft and marginRight
          }}
          columns={{ xs: 4, sm: 16 }} // make it so the lines are smaller when it is a mobile screen.
        >
          <Grid size={4} hidden={true}>
            <Button

              variant="contained"
              onClick={handleDownloadButtonClick}
              fullWidth
              disabled={isDisabled || !drillingProgramID || !dayNightFilter || !dateValue}
            >
              Download
            </Button>
          </Grid>

          <Grid size={'grow'} >
            <Button
              variant="contained"
              onClick={handleSubmitButtonClick}
              fullWidth
              disabled={errorMessage != null || disabled || dropdownOptions.loadingDropdownOptions || loading || !drillingProgramID || !dayNightFilter || !dateValue}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

      </Stack >
    </>
  );
};

export default PlodDetails;
