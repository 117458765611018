
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

export function calculateHourAndMinuteDifference(
  fromTime: Dayjs | string | null,
  toTime: Dayjs | string | null
): number {

  if (!fromTime || !toTime) {
    return 0;
  }

  // Parse the times using dayjs
  const from = dayjs(fromTime);
  const to = dayjs(toTime);

  if (!from.isValid() || !to.isValid()) {
    return 0;
  }

  // Calculate total minutes for fromTime and toTime
  const fromMinutes = from.minute();
  const fromHoursMinutes = from.hour() * 60;
  const fromTotalMinutes = fromMinutes + fromHoursMinutes;

  const toMinutes = to.minute();
  const toHoursMinutes = to.hour() * 60;
  const toTotalMinutes = toMinutes + toHoursMinutes;

  // Difference in minutes
  const diffInMinutes = toTotalMinutes - fromTotalMinutes;

  return diffInMinutes;
};