import React from "react";
import { FullCellTextField, FullCellInputLabel } from '../styled';
import { Box } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { ITaskChargeTypeOption } from "../../../Interfaces/ITaskChargeTypeOption.interface";

export function CustomAltChargeQuantityEditColumnCell(props: GridRenderEditCellParams & { taskChargeTypeOptions: ITaskChargeTypeOption[] }) {
  // const altChargeTypeValue = params.row.charge;
  const chargeItem = props.taskChargeTypeOptions.find(x => x.value === props.row.ID_EDP_Task_ChargeType);
  if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
    return <FullCellInputLabel disabled />;// Display value without editing
  }
  // Render TextField for editing if AltChargeType has a value
  const label = chargeItem.UnitOfMeasurement_Name + " QTY: "; // Variable for the label
  return (
    <Box display="flex" alignItems="center">
      <FullCellInputLabel sx={{ fontSize: '16px' }} disabled>{label}</FullCellInputLabel>
      <FullCellTextField
        sx={{ fontSize: '16px' }}
        variant="standard"
        value={props.value ?? ''}
        type="number"
        onChange={(event) => {
          props.api.setEditCellValue({ id: props.id, field: 'AltChargeQuantity', value: event.target.value });
        }} />
    </Box>
  );
};