import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { TabPanel } from "../components/TabPanel";
import { RoleManagementGrid, RoleAccessTab } from "../components/Roles";

const Roles: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '10px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="edit option">
          <Tab label="Roles" />
          <Tab label="Role Access" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {value === 0 && <RoleManagementGrid />}  {/* Load only when selected */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 && <RoleAccessTab />}
      </TabPanel>
    </Box>
  );
};

export default Roles;
