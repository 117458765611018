import React from "react";
import { GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface SingleSelectUseOptionsFromRowFieldProps {
  optionsField: string;
  labelField?: string;
  valueField?: string;
}

export function SingleSelectUseOptionsFromRowField(props: GridEditSingleSelectCellProps & SingleSelectUseOptionsFromRowFieldProps) {
  // Set defaults if not provided
  const {
    api, id, field, value, row,
    labelField = 'label',  // Default to 'label' if not specified
    valueField = 'value',  // Default to 'value' if not specified
    optionsField = 'ID'         // Default to 'ID' if not specified
  } = props;


  // Get all the options
  const options = row[optionsField];


  const handleChange = async (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    //Set the new value to the id and field.
    await api.setEditCellValue({
      id: id,
      field: field,
      value: newValue,
    });
  };
  return <Select
    fullWidth
    value={value}
    onChange={handleChange}
  >
    {options.map((item: any) =>
      <MenuItem value={item[valueField]} key={item[valueField]}>
        {item[labelField]}
      </MenuItem>
    )}
  </Select>;
}

