import React from 'react';
import GenericSelect from './GenericSelect';
import { Button, SelectChangeEvent } from '@mui/material';
import { GenericToolbarProps } from './GenericToolbar';
import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';


export interface GenericToolbarWithDropdownProps<TDropdown, TToolbar extends object> extends GenericToolbarProps<TToolbar> {
  dropdownOptions?: {
    label: string;
    plurificationText?: string;
    value: string;  // The value should match the value type from the options array.
    onChange: (event: SelectChangeEvent) => void;
    options: TDropdown[];
    disabled?: boolean;
    valueField: keyof TDropdown;
    labelField: keyof TDropdown;
    getLabelText?: (option: TDropdown) => string;
    loading?: boolean;
    customMessage?: string | null;
  };
}


export const GenericToolbarWithDropdown = <TDropdown, TToolbar extends object>({
  setRows,
  setRowModesModel,
  initialRow,
  fieldToFocus,
  buttonText,
  disabled,
  showButton = true,
  dropdownOptions,
}: GenericToolbarWithDropdownProps<TDropdown, TToolbar>) => {
  const handleAddClick = () => {
    const ID = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { ...initialRow, ID, isNew: true } as TToolbar,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer sx={{ height: "50px" }}>
      {dropdownOptions && (
        <GenericSelect<TDropdown>
          label={dropdownOptions.label}
          value={dropdownOptions.value}
          onChange={(event: SelectChangeEvent<string>) =>
            dropdownOptions.onChange(event)
          }
          options={dropdownOptions.options}
          disabled={dropdownOptions.disabled}
          valueField={dropdownOptions.valueField}
          labelField={dropdownOptions.labelField}
          loading={dropdownOptions.loading}
          plurificationText={dropdownOptions.plurificationText}
          variant="standard"
          showSeperateLabel={false}
          customMessage={dropdownOptions.customMessage}
          getLabelText={dropdownOptions.getLabelText}
        />
      )}

      <br /> {/* This just allows for it to have spacing when nothing is loaded. */}

      {/* Checks if it should show the button or not. */}
      {showButton && (
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
    </GridToolbarContainer>
  );
};
