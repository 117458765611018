import React from "react";
import NewPlodBox from "../newPlodBox";
import CustomPlodSheetDataGrid from "../CustomPlodSheetDataGrid";
import { GridColDef, GridRenderEditCellParams, GridRowModesModel } from "@mui/x-data-grid-premium";
import { getActionsForRow } from '../../EditableRowHandlers';
import { ConsumableRow, IPersonnelRow } from "../../../Interfaces/Shift.interface";
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import { SetAnotherFieldFromSelectedOptionCell } from "../../CustomCells";
import { singleSelectComparator } from "../../../functions/singleSelectComparator";
import { IDPConsumableOption } from '../../../Interfaces/DPConsumableOption.interface';

interface ConsumablesGridProps {
  consumableOptions: IDPConsumableOption[],

  consumableRows: ConsumableRow[],
  consumableRowModesModel: GridRowModesModel,
  setConsumableRowModesModel: (model: GridRowModesModel) => void,
  setConsumableRows: React.Dispatch<React.SetStateAction<ConsumableRow[]>>;

  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const ConsumablesGrid: React.FunctionComponent<ConsumablesGridProps> = ({
  consumableOptions,

  consumableRows,
  consumableRowModesModel,
  setConsumableRowModesModel,
  setConsumableRows,

  loading,
  disabled,
  canEdit
}) => {

  const Handlers = useStandardEditableRowActions({
    dataRows: consumableRows,
    dataRowModesModel: consumableRowModesModel,
    setDataRowModesModel: setConsumableRowModesModel,
    setDataRows: setConsumableRows,
  });

  const Columns: GridColDef<any>[] = [
    {
      field: "ID_EA_Consumable",
      headerName: "Consumable",
      flex: 4,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SetAnotherFieldFromSelectedOptionCell
            {...params}
            fieldstogetandsetonchange={[{
              field: 'Charge',
              fieldtogetvalue: 'Default_Charge'
            }]}
          />
        );
        // return (
        //   <CustomConsumableSetDefaultCharge
        //     {...params}
        //     consumableOptions={consumableOptions}
        //   />
        // );
      },
      sortComparator: singleSelectComparator
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
    },
    {
      field: "Charge",
      headerName: "Charge?",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: consumableRowModesModel,
          handlers: Handlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
          disabled: !canEdit
        }),
    }
  ];


  return (
    <NewPlodBox color="#0055a2" text="Consumables" key="ConsumableDataGrid">
      <CustomPlodSheetDataGrid
        columns={Columns}
        rows={consumableRows}
        rowModesModel={consumableRowModesModel}
        setRows={setConsumableRows}
        setRowModesModel={setConsumableRowModesModel}
        fieldToFocus={"ID_EA_Consumable"}
        buttonText={"Add Consumable"}
        initialRow={{ ID_EA_Consumable: '', Quantity: 0, Charge: false }}
        disabled={!canEdit || disabled}
        loading={loading}
        isCellEditable={(params) => canEdit && !disabled}
      />
    </NewPlodBox>
  );
};