import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from '../components/TabPanel'; // Import TabPanel component
import { TasksAndConsumablesTab, PersonnelAndDrillingTab } from "../components/Lists";

const Lists: React.FC = () => {
  const [mainTab, setMainTab] = useState(0);

  // State for tracking the sub-tab selection
  const [tasksAndConsumablesTab, setTasksAndConsumablesTab] = useState(0);
  const [personnelAndDrillingTab, setPersonnelAndDrillingTab] = useState(0);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTab(newValue);
  };
  return (
    // Return your main component JSX with data grid, columns, and other elements
    (<>
      <Box sx={{ width: "100%" }}>
        <Tabs //TODO: Put this into a custom component so that I can have it smaller etc.
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={mainTab}
          onChange={handleMainTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="main tabs"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            marginLeft: '10px', // Ensure no left margin
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab sx={{ width: 175, height: 60 }} label="Tasks and Consumables" /> //TODO: Put these into custom "styled" component
          <Tab sx={{ width: 175, height: 60 }} label="Personnel and Drilling" />
        </Tabs>
        <TabPanel value={mainTab} index={0}>
          <TasksAndConsumablesTab
            tabValue={tasksAndConsumablesTab}
            onTabChange={setTasksAndConsumablesTab}
          />
        </TabPanel>
        <TabPanel value={mainTab} index={1}>
          <PersonnelAndDrillingTab
            tabValue={personnelAndDrillingTab}
            onTabChange={setPersonnelAndDrillingTab}
          />
        </TabPanel>
      </Box>
    </>)
  );
};

export default Lists;
