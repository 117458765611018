import { SelectChangeEvent, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGridPremium, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../../../api";
import getInstanceID from '../../../functions/getInstanceID';
import { AlertService } from '../../../services/AlertService';
import CustomNoRowsOverlay from '../../CustomNoRowsOverlay';
import useStandardEditableRowActions from '../../useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../../CustomToolbarWithDropdownAndAdd';
import { getActionsForRow } from '../../EditableRowHandlers';
import { singleSelectComparator } from "../../../functions/singleSelectComparator";
import { GenericDataGrid } from '../../GenericDatagrid';
import { IDrillingCharge } from '../../../Interfaces/DrillingCharge.interface';
import { IDPBitSize } from '../../../Interfaces/DPBitSize.interface';
import { IBitSize } from '../../../Interfaces/BitSize.interface';
import { GenericToolbarProps } from '../../GenericToolbar';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import GenericSelect from '../../GenericSelect';
import { GenericToolbarWithDropdown } from '../../GenericToolbarWithDropdown';


interface IBitSizeDropdownOption extends IDPBitSize {
  BitSize: IBitSize[]
}


interface DrillingProgramPerMRatesGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramPerMRatesGrid: React.FC<DrillingProgramPerMRatesGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [dataError, setDataError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [bitSizeID, setBitSizeID] = useState<string>('');
  const [bitSizeOptions, setBitSizeOptions] = React.useState<IBitSizeDropdownOption[]>([]);

  //A big query for all the values it will need.
  const fetchDropDownData = async () => {
    setLoading(true);
    setBitSizeOptions([]);
    setBitSizeID('');
    setDataError(null);
    if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
      try {
        const body = {
          query: `{
          Plods(ID_AP_Plod: ${instanceID}) {
            MiningCompanys(ID: ${ID_EA_MiningCompany}) {
              Contracts(ID: ${ID_EC_Contract}) {
                DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                  DPBitSizes {
                    ID
                    BitSize{
                      Size
                    }
                  }
                }
              }
            }
          }
        }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { MiningCompanys } = data.data.Plods[0];
        const { DPBitSizes } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!DPBitSizes) throw new Error("Missing DPBitSizes");

        //Sets the Bit Size
        setBitSizeOptions(DPBitSizes);

      } catch (e) {
        setBitSizeID('');
        setBitSizeOptions([]);
        // probably could put somewhere else tbh
        setDataError(`An error occured while loading Selectors, Please try again later.`);
      }
    }
    setLoading(false);
  };

  //TODO: This needs to have it improved so it actually passes the values in.
  function handleBitSizeChange(event: SelectChangeEvent<any>): void {
    setBitSizeID(event.target.value);
  };

  const columns: GridColDef[] = [
    {
      headerName: 'From Meter',
      field: 'From_Meter',
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: 'To Meter',
      field: 'To_Meter',
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: 'Charge Per Meter',
      field: 'Charge_Per_Meter',
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
      headerAlign: "left",
      align: "left",
    },
  ];

  const initialRow: Partial<IDrillingCharge> = {
    From_Meter: 0,
    To_Meter: 0,
    Charge_Per_Meter: 0,
  };

  const validateFields = async (row: IDrillingCharge): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (row.From_Meter == null) validationErrors.push("From cannot be empty.");
    else if (row.From_Meter < 0) validationErrors.push("From Meter cannot be less than 0.");
    if (row.To_Meter == null) validationErrors.push("To Meter cannot be empty.");
    else if (row.To_Meter < 0) validationErrors.push("To Meter cannot be less than 0.");
    if (row.From_Meter != null && row.To_Meter != null && row.From_Meter > row.To_Meter) validationErrors.push("From Meter cannot be larger than the To Meter.");
    if (row.Charge_Per_Meter == null) validationErrors.push("Charge Per Meter cannot be empty.");
    else if (row.Charge_Per_Meter < 0) validationErrors.push("Charge Per Meter cannot be less than 0.");
    if (validationErrors.length) {
      //Need to make this show the Task Charge Rates name. 'Task Charge Rates {name} row'
      await AlertService.showAlert(`Per M Rate "${row.From_Meter}m - ${row.To_Meter}m @ $${row.Charge_Per_Meter}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };

  async function handleFetch(): Promise<IDrillingCharge[]> {
    if (!bitSizeID) {
      fetchDropDownData();
      return [];
    }
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const { data } = await Api.post(
          `/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPBitSizes/ID_${bitSizeID}/DrillingCharges/`,
        );
        if (!data) throw new Error("Missing Drilling Charges");
        return data as IDrillingCharge[];
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = dataError ? dataError
    : !ID_EA_MiningCompany ? "Please select a Client"
      : !ID_EC_Contract ? "Please select a Contract"
        : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
          : !bitSizeID ? "Please select a Bit Size"
            : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDrillingCharge>
        name="Per M Rate"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPBitSizes/ID_${bitSizeID}/DrillingCharges/`}
        columns={columns}
        primaryField="From_Meter"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram || !bitSizeID}
        gridMessage={gridMessage}
        showGridRetryFetchButton={!!dataError}
        getRowName={(row: IDrillingCharge) => `"${row.From_Meter}m - ${row.To_Meter}m @ $${row.Charge_Per_Meter}"`}
        ToolbarComponent={
          (props: GenericToolbarProps<IDrillingCharge>) =>
            <GenericToolbarWithDropdown<IBitSizeDropdownOption, IDrillingCharge>
              {...props}
              dropdownOptions={
                {
                  label: 'Bit Size',
                  value: bitSizeID,
                  onChange: handleBitSizeChange,
                  options: bitSizeOptions,
                  disabled: !ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram,
                  valueField: 'ID',
                  labelField: 'ID',
                  getLabelText: (option: IBitSizeDropdownOption) => `${option.BitSize[0].Size}`,
                  loading: loading,
                }
              }
            />
        }
      />
    </Box>
  );
};
