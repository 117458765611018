// GenericToolbar.tsx
import React from 'react';
import { Button } from '@mui/material';
import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import { randomId } from '@mui/x-data-grid-generator';

export interface GenericToolbarProps<T extends object> {
  setRows: (newRows: (oldRows: T[]) => T[]) => void;
  setRowModesModel: (newModel: (oldModel: any) => any) => void;
  initialRow: Partial<Omit<T, "ID" | "isNew">>; // Exclude "ID" and "isNew"
  fieldToFocus: keyof Omit<T, "ID" | "isNew">; // Ensure focusable fields also exclude "ID" and "isNew"
  buttonText: string;
  disabled: boolean;
  showButton?: boolean;
}


export function GenericToolbar<T extends object>({
  setRows,
  setRowModesModel,
  initialRow,
  fieldToFocus,
  buttonText,
  disabled,
  showButton = true,
}: GenericToolbarProps<T>) {
  const handleAddClick = () => {
    const ID = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { ...initialRow, ID, isNew: true } as T, // Add excluded fields back here dynamically
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer sx={{ height: "50px" }}>
      <br /> {/* This just allows for it to have spacing when nothing is loaded. */}

      {/* Checks if it should show the button or not. */}
      {showButton && <Button
        style={{ marginLeft: "auto", marginRight: 0 }}
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        disabled={
          disabled
        }
      >
        {buttonText}
      </Button>}
    </GridToolbarContainer>
  );
}
