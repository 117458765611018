import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { IPersonnel } from '../../../Interfaces/Personnel.interface';

export const PersonnelsGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "First Name",
      field: "FirstName",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Last Name",
      field: "LastName",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "HR Code",
      field: "HRCode",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  const initialRow: Partial<IPersonnel> = {
    FirstName: '',
    LastName: '',
  };


  const validateFields = async (row: IPersonnel): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.FirstName?.trim()) validationErrors.push("First Name cannot be empty.");
    if (!row.LastName?.trim()) validationErrors.push("Last Name cannot be empty.");
    if (!row.FirstName?.trim()) validationErrors.push("HRCode cannot be empty."); //TODO: This can technically be nullable.
    if (validationErrors.length) {
      await AlertService.showAlert(`Personnel "${row.FirstName} ${row.LastName}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IPersonnel>
        name="Personnel"
        baseURL={`/api/Plods/ID_${instanceID}/Personnels/`}
        columns={columns}
        primaryField="FirstName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
