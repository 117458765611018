import {
  Box,
} from "@mui/material";

import {
  DataGridPremium,
  GridColDef,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { Api, handleErrorResponse } from "../../api";

import getInstanceID from "../../functions/getInstanceID";

import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import { AlertService } from "../../services/AlertService";
import useStandardEditableRowActions from "../useStandardEditableRowActions";
import { getActionsForRow } from "../EditableRowHandlers";
import { singleSelectComparator } from "../../functions/singleSelectComparator";

const TeamMemberManagmentGrid: React.FunctionComponent = () => {
  let InstanceID = getInstanceID();

  const [dataRowModesModel, setDataRowModesModel] = React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [DataGridPremiumError, setDataGridPremiumError] = useState<string | null>(null);
  const [roleOptions, setRoleOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);


  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });


  let columns: GridColDef<any>[] = [
    {
      headerName: "First Name",
      field: "FirstName",
      flex: 1,
      minWidth: 200,
      editable: false,
      type: "string",
    },
    {
      headerName: "Last Name",
      field: "LastName",
      flex: 1,
      minWidth: 200,
      editable: false,
      type: "string",
    },
    {
      headerName: "Role",
      field: "RoleID",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: roleOptions,
      getOptionValue: (value: any) => value.ID,
      getOptionLabel: (value: any) => value.RoleName,
      sortComparator: singleSelectComparator,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => getActionsForRow({
        ID,
        rowModesModel: dataRowModesModel,
        handlers: { handleCancelClick, handleEditClick, handleSaveClick },
        actions: { cancel: true, edit: true, save: true, delete: false },
      }),
    },
  ];


  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.RoleID == null || newRow.RoleID <= 0) {
      await AlertService.showAlert(
        `Team Member "${newRow.FirstName} ${newRow.LastName}" row has fields causing errors.`,
        'criticalerror', "You must select a Role."
      );
      return;
    }
    try {
      const rawData = JSON.stringify({ "Link_Value": { "ID": newRow.RoleID } });
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/TeamMembers/ID_${newRow.ID}/Roles/~AddLinkTo`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        RoleID: data.ID,
      };
      setDataRows((prev) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Rig "${newRow.RigName}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Rig "${newRow.RigName}".`);
      return;
    }
  };


  const fetchData = async () => {
    setDataRows([]);
    setDataGridPremiumError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/~GetTeamMembersAndRoles`);
      setDataRows(data.TeamMembers);
      setRoleOptions(data.Roles);
    } catch (e) {
      setDataRows([]);
      setDataGridPremiumError("An error occured while loading Team Members, Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (InstanceID) fetchData();
  }, [InstanceID]);

  return (
    <Box sx={{ height: "70vh", maxHeight: '800px', margin: "10px" }}>
      <DataGridPremium
        rows={dataRows}
        columns={columns}
        getRowId={(row) => row.ID} // Gets the id from database, not the local style one
        editMode="row"
        rowModesModel={dataRowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        loading={loading}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={DataGridPremiumError ? DataGridPremiumError : "No Team Member Data"}
              onRetry={DataGridPremiumError ? fetchData : undefined}
            />
          ),
        }}
      />
    </Box>
  );
};
export { TeamMemberManagmentGrid };