
import React from "react";
import { randomId } from '@mui/x-data-grid-generator';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, } from '@mui/material';
import { GridRowModesModel, GridToolbarContainer, GridRowModes } from '@mui/x-data-grid-premium';
import AddIcon from "@mui/icons-material/Add";
import { height } from "@mui/system";
import { IGenericDropDownOption } from "../Interfaces/GenericDropDownOption.interface";

type CustomToolBarWithDropDownToolbarProps<T> = {
  setDataRows: React.Dispatch<React.SetStateAction<T[]>>;
  setDataRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  rowTemplate: Partial<T>; // Define the structure of a new row
  fieldName: string; // The field to focus when editing
  itemName: string; // The field to display next to "Add"
  disableItems?: boolean
  // Make displayDropDown optional, defaulting to false
  displayDropDown?: boolean;  // Optional prop, defaults to false

  // When displayDropDown is true, these props are required
  dropDownOptions?: IGenericDropDownOption[];
  dropDownSelectedID?: string | null;
  handleDropDownChange?: (event: SelectChangeEvent<string>) => void;
  dropdownDataName?: string;// This is the name when it needs to select etc.
};

function CustomToolBarWithDropDownToolbar<T>({
  setDataRows,
  setDataRowModesModel,
  rowTemplate,
  fieldName,
  itemName,
  disableItems = false,
  displayDropDown = false,  // Default to false if not provided
  dropDownOptions,
  dropDownSelectedID,
  handleDropDownChange,
  dropdownDataName
}: CustomToolBarWithDropDownToolbarProps<T>) {
  const handleAddClick = () => {
    const ID = randomId();
    setDataRows((prev) => [
      ...prev,
      { ID, ...rowTemplate, isNew: true } as T & { ID: any; isNew: boolean }
    ]);
    setDataRowModesModel((prev) => ({
      ...prev,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus: fieldName },
    }));
  };

  // Ensure all dropdown-related props are provided when displayDropDown is true
  if (displayDropDown) {
    if (!dropDownOptions || !handleDropDownChange || !dropdownDataName) {
      throw new Error("Dropdown fields are required when displayDropDown is true.");
    }
  }

  return (
    <GridToolbarContainer sx={{ height: "50px" }}>
      {displayDropDown && dropDownOptions && handleDropDownChange && dropdownDataName && (
        <FormControl variant="standard" sx={{ marginLeft: 1 }}>
          <InputLabel id={`${dropdownDataName}-selector-label`} sx={{ fontSize: 15 }}>
            {`${dropDownOptions.length === 0 ? "No " : "Select a "} ${dropdownDataName}`}
          </InputLabel>
          <Select
            labelId={`${dropdownDataName}-selector-label`}
            id={`${dropdownDataName}Selector`}
            value={dropDownSelectedID ?? ''}
            onChange={handleDropDownChange}
            disabled={dropDownOptions.length === 0}
            sx={{ width: 300, fontSize: 15 }}
          >
            {dropDownOptions.map((data: any) => (
              <MenuItem value={data.value} key={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        sx={{ ml: "auto" }}
        disabled={disableItems || (displayDropDown == true && dropDownSelectedID == null)}

      >
        Add {itemName}
      </Button>
    </GridToolbarContainer>
  );
}
export default CustomToolBarWithDropDownToolbar;