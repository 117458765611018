import React, { useState } from "react";
import {
  Button,
  FormHelperText,
  SelectChangeEvent,
  Box,
  Tab,
  Tabs,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Api, handleErrorResponse } from "../api";

import getInstanceID from "../functions/getInstanceID";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { IRig } from "../Interfaces/Rig.interface";
import GenericSelect from "../components/GenericSelect";
import { IContract } from "../Interfaces/Contract.interface";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import getInstanceData from "../functions/getInstanceData";
const Reports: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const instanceInfo = getInstanceData();
  // const shift: any = localStorage.getItem("shiftFilter") || null; // ? WHY IS THIS HERE
  // const miniCo: any = localStorage.getItem("miningCompanyFilter") || null; // ? WHY IS THIS HERE
  // const contract: any = localStorage.getItem("contractFilter") || null; // ? WHY IS THIS HERE
  // const dril: any = localStorage.getItem("drillingTypeFilter") || null; // ? WHY IS THIS HERE
  // const rig: any = localStorage.getItem("rigFilter") || null; // ? WHY IS THIS HERE

  const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>(dayjs());
  const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>(dayjs());

  const [tabValue, setTabValue] = React.useState<string>("DetailedDrillingReport");
  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
  };



  const [downloadFilter, setDownloadFilter] = React.useState<string>("Yes");
  const handleDownloadFilterChange = (event: SelectChangeEvent) => {
    setDownloadFilter(event.target.value as string);
  };

  //#region Standard Selectors
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    if (value == "-1") {
      setDrillingProgramSelectorID("-1");
    }
    else {
      setDrillingProgramSelectorID(""); // Reset drilling program selection
    }
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
  };
  //#endregion

  //#region unused stuffyet 

  // const [statusFilter, setStatusFilter] = React.useState("All");
  // const handleStatusFilterChange = (event: SelectChangeEvent) => {
  //   setStatusFilter(event.target.value as string);
  //   localStorage.setItem("shiftFilter", event.target.value); //?TF is this here for.
  // };

  const [rigSelectorID, setRigFilter] = React.useState<string>('');
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
  };

  //#region Fetching data for Rigs Selector.
  const [rigOptions, setRigData] = React.useState<IRig[]>([]);
  React.useEffect(() => {
    const fetchRigData = async () => {
      await Api.post(`/api/Plods/ID_${plodID}/Rigs`)
        .then(({ data }) => {
          if (data.length == 0) {
            setRigFilter("All");
            setRigData([]);
          } else {
            setRigFilter('');
            setRigData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e, "Failed to get rigs.");
        });
    };
    fetchRigData();
  }, []);
  //#endregion

  const handleDownload = async (reportType: string) => {
    try {

      const dataJSON = {
        PlodID: plodID,
        MiningCompanyID: miningCompanySelectorID,
        ContractID: contractSelectorID,
        DrillingProgramID: drillingProgramSelectorID,
        Rig: rigSelectorID == "All" ? -1 : rigSelectorID,
        StartDate: startDateValue,
        EndDate: endDateValue,
      };
      let url = "";
      switch (reportType) {
        case 'DDRReport':
          url = `/api/Plods/ID_${plodID}/Reports/0/~DailyDrillingReport`;
          break;
        case 'AllHolesReport':
          url = `/api/Plods/ID_${plodID}/Reports/0/~DailyDrillingReportAllHoles`;
          break;
        case 'PowerBI':
          url = `/api/Plods/ID_${plodID}/Reports/0/~PowerBI`;
          break;
        default:
          throw new Error("Unkown report type.");
      }

      const data: string = JSON.stringify(dataJSON);
      const response = await Api.post(
        url,
        dataJSON,
        {
          responseType: "blob", // Important to set the response type to blob
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data]);

      // Create a link element
      const downloadLink = document.createElement("a");

      // Set the href attribute of the link to the blob URL
      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      switch (reportType) {
        case 'DDRReport':
          downloadLink.download = `DailyDrillingReport.xlsx`;
          break;
        case 'AllHolesReport':
          downloadLink.download = `DailyDrillingReport_AllDrilling.xlsx`;
          break;
        case 'PowerBI':
          downloadLink.download = `PowerBIReport.xlsx`;
          break;
        default:
          throw new Error("Unkown report type.");
      }

      // Append the link to the document body
      document.body.appendChild(downloadLink);
      // Trigger the click event to initiate the download
      downloadLink.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(downloadLink);
    } catch (error) {
      handleErrorResponse(error, "Failed to download Report");
    }
  };

  //#endregion

  return (
    <>
      {/* <FormHelperText style={{ marginLeft: 25, marginTop: 25 }}>
        Report Type
      </FormHelperText>
      <hr style={{ marginLeft: 25, marginRight: 25 }} /> */}
      {/* <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20 }}
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="DetailedDrillingReport"
            label="Detailed Drilling Report (DDR)"
          />
          <Tab value="BitReport" label="Bit Report" disabled />
          <Tab value="WeeklyProjectReport" label="Weekly Project Report" disabled />
        </Tabs>
      </Box> */}

      <div className="my-4">
        <FormHelperText style={{ marginLeft: 25 }}>Filters</FormHelperText>
        <hr style={{ marginLeft: 25, marginRight: 25, marginBottom: 25 }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              marginLeft: 25,
              marginRight: 25,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <MiningCompanySelect
              plodID={plodID}
              value={miningCompanySelectorID}
              setValue={setMiningCompanySelectorID}
              onChange={handleMiningCompanyChange}
              miningCompanyOptions={miningCompanyOptions}
              setMiningCompanyOptions={setMiningCompanyOptions}
            />

            <ContractSelect
              plodID={plodID}
              miningCompanySelectorID={miningCompanySelectorID}
              value={contractSelectorID}
              setValue={setContractSelectorID}
              onChange={handleContractChange}
              showAllOption={true}
              contractOptions={contractOptions}
              setContractOptions={setContractOptions}
            />
            <DrillingProgramSelect
              plodID={plodID}
              miningCompanySelectorID={miningCompanySelectorID}
              contractSelectorID={contractSelectorID}
              value={drillingProgramSelectorID}
              setValue={setDrillingProgramSelectorID}
              onChange={handleDrillingProgramChange}
              showAllField={true}
              drillingProgramOptions={drillingProgramOptions}
              setDrillingProgramOptions={setDrillingProgramOptions}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: 25,
              marginRight: 25,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <Stack spacing={0} padding="5px">
              <FormHelperText>Start Date</FormHelperText>
              <DatePicker
                sx={{ width: "250px" }}
                value={startDateValue}
                // maxDate={endDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                }}
                format="DD/MM/YYYY"
              />
            </Stack>
            <Stack spacing={0} padding="5px">
              <FormHelperText>End Date</FormHelperText>
              <DatePicker
                sx={{ width: "250px" }}
                value={endDateValue}
                // minDate={startDateValue}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                }}
                format="DD/MM/YYYY" />
            </Stack>
            <GenericSelect<IRig>
              label="Rig"
              value={rigSelectorID}
              onChange={handleRigFilterChange}
              options={rigOptions}
              disabled={false}
              valueField="ID_EA_Rig"
              labelField="RigName"
              showAllOption={rigOptions.length != 0}

            />
            {/* <FilterSpacing>
              <FormHelperText>Download</FormHelperText>
              <Select
                id="downloadFilter"
                value={downloadFilter}
                onChange={handleDownloadFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"Yes"} key={"Yes"}>
                  Yes
                </MenuItem>
                <MenuItem value={"No"} key={"No"}>
                  No
                </MenuItem>
              </Select>
            </FilterSpacing> */}
          </div>
        </LocalizationProvider>
      </div>

      <FormHelperText style={{ marginLeft: 25, marginTop: 5 }}>
        Download
      </FormHelperText>
      <hr
        style={{
          marginLeft: 25,
          marginRight: 25,
          marginBottom: 25,
          marginTop: 5,
        }}
      />
      <div
        style={{
          display: "flex",
          marginLeft: 25,
          marginRight: 25,
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {/* <Button variant="contained" onClick={() => handleDownload("DDRReport")} disabled={!miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID || !rigSelectorID} style={{ margin: 25, width: 300 }} >
          {" "}
          Download DDR REPORT
        </Button> */}
        <Button variant="contained" onClick={() => handleDownload("AllHolesReport")} disabled={!miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID || !rigSelectorID} style={{ margin: 25, width: 300 }} >
          {" "}
          Download DDR All Drilling Report
        </Button>
        {(instanceInfo.UserInfo.IsOwner || instanceInfo.UserRole.ID == 142) && ( //TODO: Make this no longer hard coded, and instead make it a selectable option in the permissions thing.
          <Button variant="contained" onClick={() => handleDownload("PowerBI")} disabled={!miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID || !rigSelectorID} style={{ margin: 25, width: 300 }} >
            {" "}
            Download PowerBI Report
          </Button>
        )}
      </div>
    </>
  );
};

export default Reports;
