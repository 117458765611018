import { SelectChangeEvent, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGridPremium, GridColDef, GridRowId, GridRowModel, GridRowModesModel } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../../api";
import getInstanceID from '../../functions/getInstanceID';
import { AlertService } from '../../services/AlertService';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import useStandardEditableRowActions from '../useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../CustomToolbarWithDropdownAndAdd';
import { getActionsForRow } from '../EditableRowHandlers';
import { singleSelectComparator } from "../../functions/singleSelectComparator";

export const BitsGrid: React.FunctionComponent = () => {
  const plodID = getInstanceID();
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [manufacturerID, setManufacturerID] = useState<string>('');
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [bitTypeOptions, setBitTypeOptions] = React.useState([]);
  const [bitSizeOptions, setBitSizeOptions] = React.useState([]);

  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  useEffect(() => {
    if (plodID) fetchData();
  }, [plodID, manufacturerID]);

  //A big query for all the values it will need.
  const fetchDropDownData = async () => {
    setManufacturerOptions([]);
    setBitTypeOptions([]);
    setBitSizeOptions([]);
    try {
      const body = {
        query: `{
          Plods(ID_AP_Plod: ${plodID}) {
            BitSizes {
              label:Size
              value:ID_EA_BitSize
            }
            BitTypes {
              label:BitType
              value:ID_EA_BitType
            }
            Manufacturers {
              label:ManufacturerName
              value:ID_EA_Manufacturer
            }
          }
        }`
      };
      const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
      const { BitSizes, BitTypes, Manufacturers } = data.data.Plods[0];
      setManufacturerOptions(Manufacturers);
      setBitTypeOptions(BitTypes);
      setBitSizeOptions(BitSizes);
    } catch (e) {
      setManufacturerOptions([]);
      setBitTypeOptions([]);
      setBitSizeOptions([]);
      // probably could put somewhere else tbh
      setDataGridError(`An error occured while loading Selectors, Please try again later.`);
    }
  };

  const fetchData = async () => {
    setDataRows([]);
    setLoading(true);
    setDataGridError(null);
    if (!manufacturerID) {
      fetchDropDownData();
    }
    else {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${plodID}/Manufacturers/ID_${manufacturerID}/MBits`);
        setDataRows(data);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading Bits Please try again later.`);
      }
    }
    setLoading(false);
  };

  //TODO: This needs to have it improved so it actually passes the values in.
  function handleManufacturerChange(event: SelectChangeEvent<any>): void {
    setManufacturerID(event.target.value);
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0].BitName;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Bit "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Manufacturers/ID_${manufacturerID}/MBits/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Bit "${currentItemName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Bit "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors: string[] = [];
    if (!newRow.BitName?.trim()) validationErrors.push("Bit Name cannot be empty.");
    if (newRow.ID_EA_BitSize == null) validationErrors.push("Bit Size cannot be empty."); // TODO: NEed to fix
    if (newRow.ID_EA_BitType == null) validationErrors.push("Bit Type cannot be empty."); //TODO : Need to fix
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`Bit row has empty required fields.`, 'criticalerror', errors.join("\n"));
      return;
    }
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
      const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/Manufacturers/ID_${manufacturerID}/MBits/${endpoint}`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        ID: data.ID ?? newRow.ID,
        isNew: false,
      };
      setDataRows((prev) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Bit "${newRow.BitName}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Bit "${newRow.BitName}".`);
    }
    return newRow;
  };



  const columns: GridColDef[] = [
    {
      field: "BitName",
      headerName: "Bit Name",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "ID_EA_BitSize",
      headerName: "Bit Size",
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
      sortComparator: singleSelectComparator
    },
    {
      field: "ID_EA_BitType",
      headerName: "Bit Type",
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "singleSelect",
      valueOptions: bitTypeOptions,
      sortComparator: singleSelectComparator
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      resizable: false,
      getActions: ({ id: ID }) => getActionsForRow({
        ID,
        rowModesModel: dataRowModesModel,
        handlers: { handleDeleteClick, handleCancelClick, handleEditClick, handleSaveClick },
        actions: { cancel: true, edit: true, save: true, delete: true },
      }),
    }
  ];


  return (
    <>
      <Box sx={{ height: "80vh", margin: "10px" }}>
        <DataGridPremium sx={{ zIndex: 0 }}
          columns={columns}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          slots={{
            toolbar: () => (
              <CustomToolBarWithDropDownToolbar
                setDataRows={setDataRows}
                setDataRowModesModel={setDataRowModesModel}
                rowTemplate={{ "BitName": "" }}
                fieldName={"BitName"}
                itemName={"Bit"}
                disableItems={dataGridError != null || loading || !manufacturerID}
                displayDropDown={true}
                dropDownOptions={manufacturerOptions}
                dropDownSelectedID={manufacturerID}
                handleDropDownChange={handleManufacturerChange}
                dropdownDataName="Manufacturer"
              />),
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : !manufacturerID ? "Please Select a Manufacturer" : "No Bit Data"}
                onRetry={dataGridError ? () => fetchData() : undefined}
              />
            ),
          }}
          disableAggregation
          disableColumnPinning
          disableRowGrouping
          disableColumnFilter
          disableColumnSelector
        />
      </Box>
    </>
  );
};
