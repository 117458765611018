import React from "react";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { CustomHoursDifferenceColumnCell } from '../../CustomCells';
import { CustomTimePickerEditCell, } from '../../CustomCells';

export const HoursColumns = (TimeConfig: string): GridColDef<any>[] => {
  const hoursColumns: GridColDef<any>[] =
    TimeConfig === 'FromToTime'
      ? [
        {
          field: 'FromTime',
          headerName: 'From Time',
          width: 150,
          editable: true,
          valueFormatter: (value: any) => {
            try {
              if (!value) return '';
              return dayjs(value).format('HH:mm');
            } catch {
              return 'error';
            }
          },
          renderEditCell: (params: GridRenderEditCellParams) => <CustomTimePickerEditCell
            {...params}
            fieldstoeditonchange={[{
              field: 'HoursDifference',
              value: null
            }]}
            maxtimefield="ToTime"
          />,
        },
        {
          field: 'ToTime',
          headerName: 'To Time',
          width: 150,
          editable: true,
          valueFormatter: (value: any) => {
            try {
              if (!value) return '';
              return dayjs(value).format('HH:mm');
            } catch {
              return 'error';
            }
          },
          renderEditCell: (params: GridRenderEditCellParams) => <CustomTimePickerEditCell
            {...params}
            fieldstoeditonchange={[{
              field: 'HoursDifference',
              value: null,
            }]}
            mintimefield="FromTime"
          />,
        },
        {
          field: "HoursDifference",
          headerName: "Hours",
          flex: 1,
          minWidth: 100,
          editable: true, // dumb but you have to put this so it starts loading the editable cell...
          renderCell: (params: GridRenderEditCellParams) => {
            const fromTime = params.row.FromTime;
            const toTime = params.row.ToTime;
            return <CustomHoursDifferenceColumnCell fromTime={fromTime} toTime={toTime} />;
          },
          renderEditCell: (params: GridRenderEditCellParams) => {
            const fromTime = params.row.FromTime;
            const toTime = params.row.ToTime;
            return <CustomHoursDifferenceColumnCell fromTime={fromTime} toTime={toTime} />;
          },
        },
      ]
      : TimeConfig === 'Hours'
        ? [
          {
            field: "Hours",
            headerName: "Hours",
            flex: 1,
            minWidth: 100,
            type: "number",
            editable: true,
          },
        ]
        : [];
  return hoursColumns;
};
