import getInstanceID from '../functions/getInstanceID';
import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../api";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import MiningCompanySelect from '../components/MiningCompanySelect';
import ContractSelect from '../components/ContractSelect';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { IContract } from '../Interfaces/Contract.interface';
import { IMiningCompany } from '../Interfaces/MiningCompany.interface';
import { AlertService } from '../services/AlertService';
import { IDrillingProgram } from '../Interfaces/DrillingProgram.interface';
import validateAndSet from '../functions/ValidAndSetData';
import DrillingProgramSelect from '../components/DrillingProgramSelect';
import { randomId } from '@mui/x-data-grid-generator';
import CustomDialog, { ICustomDialogComponent } from '../components/CustomDialogComponent';
import GenericSelect from '../components/GenericSelect';

// Define data types
interface InvoiceLine {
  ID_IN_Invoice_Line: number;
  Description: string;
  Quantity: number;
  UnitOfMeasurement: string;
  Total: number;
  ID_AP_Plod: number;
  ID_EC_Contract: number;
  ID_IN_Invoice: number;
  ID_IN_Invoice_Header: number;
  LineType: string;
  PoNumber: null;
  SubTotal: number;
  IsTaxable: boolean;

}

interface InvoiceHeader {
  ID_AP_Plod: number;
  ID_EC_Contract: number;
  ID_IN_Invoice: number;
  ID_IN_Invoice_Header: number;
  Name: string;
  ID_IN_Parent_Header: number | null;
  InvoiceLines: InvoiceLine[];
  InvoiceHeaders: InvoiceHeader[];
}

const InvoiceEdit: React.FunctionComponent = () => {
  let InstanceID: string = getInstanceID();
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [invoiceHeaderOptions, setInvoiceHeaderOptions] = React.useState<any[]>([]);
  const [invoiceOptions, setInvoiceOptions] = React.useState<any[]>([]);
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingDropDown, setLoadingDropDown] = React.useState(false);

  const [invoiceSelectorID, setInvoiceSelectorID] = React.useState('');

  //#region Filters/selector things:
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID("");
    setInvoiceSelectorID('');
    setInvoiceOptions([]);
    setDataRows([]);
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID("");
    setInvoiceSelectorID('');
    setInvoiceOptions([]);
    setDataRows([]);
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
    setInvoiceSelectorID('');
    setInvoiceOptions([]);
    setDataRows([]);
  };
  //#endregion


  const [invoiceHeaderDialogOpen, setInvoiceHeaderDialogOpen] = React.useState(false);
  const [invoiceHeaderDialogAdding, setInvoiceHeaderDialogAdding] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({});
  const handleInvoiceDialogSubmit = async () => {

    console.log('Form Data:', formData);
    try {
      const NewData = {
        ...formData,
        isTaxable: formData.isTaxable ?? false,
        Total: parseFloat(formData.Quantity) * parseFloat(formData.SubTotal),
        ID_IN_Invoice_Header: formData.ID_IN_Invoice_Header.toString().replace("Header-", "")
      };
      const rawData = JSON.stringify({ ...NewData, });
      let currentRow = invoiceHeaderOptions.filter((row: any) => row.ID.toString() == formData.ID_IN_Invoice_Header)[0];
      let RequestURL = getUrl(currentRow.Hierarchy, 'Line', formData.ID);
      RequestURL += invoiceHeaderDialogAdding ? `/~AddItem` : `/~UpdateItem`;
      const { data } = await Api.post(RequestURL,
        rawData
      );
      if (invoiceHeaderDialogAdding) {
        setDataRows((prev) => [
          ...prev,
          {
            ...NewData,
            RowType: "Line",
            ID: 'Line-' + data.ID,
            Hierarchy: [...currentRow.Hierarchy, data.ID],
          }
        ]);
      }
      else {
        setDataRows((prev) =>
          prev.map((row) => (row.ID === "Line-" + formData.ID ? NewData : row))
        );
      }

      setFormData({});
      setInvoiceHeaderDialogOpen(false);
      await AlertService.showAlert(`Successfully Edited Invoice Header.`, "success");
    } catch (e) {
      handleErrorResponse(e, `Error Edited Invoice Header.`); // need to put the message in here
    };
  };

  const handleEditLineClick = (ID: GridRowId) => async () => {
    const currentRow = await dataRows.filter((row) => row.ID === ID)[0];
    setFormData({ ...currentRow, ID: currentRow.ID.toString().replace("Line-", ""), ID_IN_Invoice_Header: 'Header-' + currentRow.ID_IN_Invoice_Header.toString() });
    setInvoiceHeaderDialogAdding(false);
    setInvoiceHeaderDialogOpen(true);
  };


  const dialogOptions: ICustomDialogComponent[] = [
    {
      headerName: 'Header',
      field: 'ID_IN_Invoice_Header',
      type: 'singleSelect',
      valueOptions: invoiceHeaderOptions,
      required: true,
      editable: invoiceHeaderDialogAdding,
    },
    {
      headerName: 'Description',
      field: 'Description',
      type: 'string',
      editable: true,
      required: true,
    },
    {
      headerName: 'Po Number',
      field: 'PoNumber',
      type: 'string',
      editable: true,
    },
    {
      headerName: 'Quantity',
      field: 'Quantity',
      type: 'number',
      editable: true,
      required: true,
    },
    {
      headerName: 'Unit Of Measurement',
      field: 'UnitOfMeasurement',
      type: 'string',
      editable: true,
      required: true,
    },
    {
      headerName: 'Unit Price',
      field: 'SubTotal',
      type: 'number',
      editable: true,
      required: true,
    },
    {
      headerName: 'Taxable?',
      field: 'isTaxable',
      type: 'boolean',
      editable: true,
    },
  ];

  function getUrl(Hierachy: number[], type: string, currentID: string): string {
    const currentRowHierarchyExcludingSelf = Hierachy.filter((row: number) => row.toString() != currentID);
    let RequestURL = `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${invoiceSelectorID}`;
    RequestURL += currentRowHierarchyExcludingSelf.reduce((accumulator: string, currentValue: number) => accumulator + '/InvoiceHeaders/ID_' + currentValue.toString(), '');
    RequestURL += type == "Header" ? "/InvoiceHeaders" : "/InvoiceLines";
    return RequestURL;
  };

  const handleDeleteLineClick = (ID: GridRowId) => async () => {
    const currentRow = await dataRows.filter((row) => row.ID === ID)[0];
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Invoice Line"${currentRow.Description}".`, "question");
    if (confirmed) {
      try {
        const currentRowID = currentRow.ID.toString().replace("Line-", "");
        let RequestURL = getUrl(currentRow.Hierarchy, currentRow.RowType, currentRowID);
        console.log(RequestURL);
        const { data } = await Api.post(
          `${RequestURL}/~DeleteItem`, // this is gonna be trick as it has to loop through the refrence ID's...
          JSON.stringify({ ID: currentRowID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== ID));
        await AlertService.showAlert(`Successfully Deleted Invoice Line "${currentRow.Description}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Invoice Line"${currentRow.Description}".`);
      }
    }
  };



  const columns: GridColDef<any>[] = [
    {
      field: 'UnitOfMeasurement',
      headerName: 'Unit Of Measurement',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'Quantity',
      headerName: 'Quantity',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'SubTotal',
      headerName: 'Unit Price',
      flex: 1,
      minWidth: 150,
      type: 'number',
      valueFormatter: (params) => {
        if (params == null) {
          return "";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
    },
    {
      field: 'Total',
      headerName: 'Total',
      flex: 1,
      minWidth: 200,
      type: 'number',
      valueFormatter: (params) => {
        if (params == null) {
          return "";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      align: 'center',
      getActions: ({ id: ID, row }) => {
        const actionItems: JSX.Element[] = [];
        if (row.RowType == "Line") {
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Edit Line" key={`edit-${ID}`}>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  color="inherit"
                  key={`edit-${ID}`}
                  onClick={handleEditLineClick(ID)}
                />
              </Tooltip>
            </React.Fragment>
          );
          actionItems.push(
            <React.Fragment>
              <Tooltip title="Delete Line" key={`delete-${ID}`}>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  color="inherit"
                  key={`delete-${ID}`}
                  onClick={handleDeleteLineClick(ID)}
                />
              </Tooltip>
            </React.Fragment>
          );
        }
        return actionItems;
      },
    }
  ];


  const fetchData = async () => {
    setDataRows([]);
    setDataGridError(null);
    if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID && invoiceSelectorID) {
      try {
        setLoading(true);
        const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${invoiceSelectorID}/~GetInvoiceHeaderFieldsAndChildren`);
        let ProcessedRows: any[] = [];
        data.forEach((header: InvoiceHeader) => {
          ProcessedRows.push({
            Hierarchy: [header.ID_IN_Invoice_Header],
            RowType: 'Header',
            ID: 'Header-' + header.ID_IN_Invoice_Header,
            Name: header.Name
          });
          header.InvoiceLines.forEach((line) => {
            ProcessedRows.push({
              Hierarchy: [header.ID_IN_Invoice_Header, line.ID_IN_Invoice_Line],
              RowType: 'Line',
              ID: 'Line-' + line.ID_IN_Invoice_Line,
              ...line
            });
          });
          header.InvoiceHeaders.forEach((secondHeader) => {
            ProcessedRows.push({
              Hierarchy: [header.ID_IN_Invoice_Header, secondHeader.ID_IN_Invoice_Header],
              RowType: 'Header',
              ID: 'Header-' + secondHeader.ID_IN_Invoice_Header,
              Name: secondHeader.Name
            });
            secondHeader.InvoiceLines.forEach((line) => {
              ProcessedRows.push({
                Hierarchy: [header.ID_IN_Invoice_Header, secondHeader.ID_IN_Invoice_Header, line.ID_IN_Invoice_Line],
                RowType: 'Line',
                ID: 'Line-' + line.ID_IN_Invoice_Line,
                ...line
              });
            });
          });
        });

        const HeaderOptions = ProcessedRows.filter(item => item.RowType === "Header").map((x) => ({ ...x, label: x.Name, value: x.ID }));
        setInvoiceHeaderOptions(HeaderOptions);
        console.log(ProcessedRows);
        setDataRows(ProcessedRows);
      } catch (e) {
        setDataRows([]);
        setDataGridError(`An error occured while loading Contracts, Please try again later.`);
      }
    }
    else if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID) {
      setLoadingDropDown(true);
      setInvoiceOptions([]);
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/~GetInvoicesWithStatuses`,
          JSON.stringify({ ID_EDP_DrillingProgram: drillingProgramSelectorID })
        );
        console.log(data);
        setInvoiceOptions(data);

      } catch (e) {
        setInvoiceOptions([]);
        setDataGridError("Error fetching Invoices.");
      };
    };
    setLoadingDropDown(false);
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();

  }, [miningCompanySelectorID, contractSelectorID, drillingProgramSelectorID, invoiceSelectorID]);


  // Handle dropdown change with correct typing for SelectChangeEvent
  const handleDropDownChange = (event: SelectChangeEvent<any>) => {
    setInvoiceSelectorID(event.target.value);
  };


  function EditInvoiceToolbar() { //TODO: Use the generic component for this, not my own version.
    const handleAddClick = async () => {
      setFormData({
        LineType: "CustomLine",
        ID: ''
      });
      setInvoiceHeaderDialogAdding(true);
      setInvoiceHeaderDialogOpen(true);
    };
    return (
      <GridToolbarContainer sx={{ height: "50px" }}>
        <GenericSelect<any> //TODO: Make this a proper type.
          label={"Invoice"}
          value={invoiceSelectorID}
          onChange={(event: SelectChangeEvent<string>) => handleDropDownChange(event)}
          options={invoiceOptions}
          valueField={"ID"}
          labelField={"DocumentNumber"}
          loading={loadingDropDown}
          variant="standard"
          showSeperateLabel={false}
        />

        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          sx={{ ml: "auto" }}
          disabled={dataGridError != null || loading || !miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID || !invoiceSelectorID}
        >
          Add Invoice Line
        </Button>
      </GridToolbarContainer>
    );

  }


  // State to manage expanded row IDs
  const [expandedRowIds, setExpandedRowIds] = useState<Set<GridRowId>>(new Set());

  // Handler for row expansion changes
  const handleRowExpansionChange = (newExpandedRowIds: GridRowId[]) => {
    setExpandedRowIds(new Set(newExpandedRowIds));
  };

  return (<>
    <Box
      sx={{
        marginLeft: '15px',
        display: 'flex',
        flexWrap: 'wrap', // Allow wrapping of items
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0,
        '@media (max-width: 100px)': {
          flexDirection: 'column', // Stack items vertically on smaller screens
          alignItems: 'stretch', // Stretch to fill the available width
        },
      }}
    >

      <MiningCompanySelect
        plodID={InstanceID}
        value={miningCompanySelectorID}
        setValue={setMiningCompanySelectorID}
        onChange={handleMiningCompanyChange}
        miningCompanyOptions={miningCompanyOptions}
        setMiningCompanyOptions={setMiningCompanyOptions}
      />

      <ContractSelect
        plodID={InstanceID}
        miningCompanySelectorID={miningCompanySelectorID}
        value={contractSelectorID}
        setValue={setContractSelectorID}
        onChange={handleContractChange}
        contractOptions={contractOptions}
        setContractOptions={setContractOptions}
      />
      <DrillingProgramSelect
        plodID={InstanceID}
        miningCompanySelectorID={miningCompanySelectorID}
        contractSelectorID={contractSelectorID}
        value={drillingProgramSelectorID}
        setValue={setDrillingProgramSelectorID}
        onChange={handleDrillingProgramChange}
        drillingProgramOptions={drillingProgramOptions}
        setDrillingProgramOptions={setDrillingProgramOptions}
      />
    </Box>
    <Box
      sx={{
        height: "80vh",
        margin: "15px",
      }}
    >
      <DataGridPremium
        treeData
        rows={dataRows}
        columns={columns}
        getTreeDataPath={(row) => row.Hierarchy}
        isCellEditable={(params) => params.row.RowType != "Header"}
        groupingColDef={{
          headerName: "Name", // Only one grouping column
          valueGetter: (value, row) => {
            return row.RowType == 'Header' ? row.Name : row.Description;
          },
          flex: 2,
          minWidth: 250,
        }}
        loading={loading}
        getRowId={(row) => row.ID}
        disableRowSelectionOnClick
        defaultGroupingExpansionDepth={-1}
        slots={{
          toolbar: () => (<EditInvoiceToolbar />),
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={dataGridError ? dataGridError
                : !miningCompanySelectorID
                  ? "Please select a Client"
                  : !contractSelectorID
                    ? "Please select a Contract"
                    : !drillingProgramSelectorID
                      ? "Please select a Drilling Program"
                      : !invoiceSelectorID
                        ? "Please select an Invoice"
                        : "No Invoice Lines"}
              onRetry={dataGridError ? () => fetchData() : undefined}
            />
          ),
        }}
      />
    </Box>
    <CustomDialog
      open={invoiceHeaderDialogOpen}
      onClose={() => setInvoiceHeaderDialogOpen(false)}
      options={dialogOptions}
      data={formData}
      onFieldChange={(field, value) =>
        setFormData((prev: any) => ({ ...prev, [field]: value }))
      }
      handleSubmit={handleInvoiceDialogSubmit}
      title={invoiceHeaderDialogAdding ? "Add Invoice Header" : "Edit Invoice Header"}
    />
  </>
  );
};

export default InvoiceEdit;