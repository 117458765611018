import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { TabPanel } from "../TabPanel";
import { UnitOfMeasurementsGrid, TasksGrid, ConsumablesGrid, TaskMetricsGrid } from "./TasksAndConsumables";

interface TasksAndConsumablesTabProps {
  tabValue: number;
  onTabChange: (newValue: number) => void;
}

const TasksAndConsumablesTab: React.FC<TasksAndConsumablesTabProps> = ({ tabValue, onTabChange }) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '10px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Tasks And Consumables Tab Selector"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab sx={{ width: 175, height: 60 }} label="Unit Of Measurements" />
          <Tab sx={{ width: 175, height: 60 }} label="Tasks" />
          <Tab sx={{ width: 175, height: 60 }} label="Consumables" />
          <Tab sx={{ width: 175, height: 60 }} label="Task Metric" />
        </Tabs>
      </Box>
      {/*The Grids that show underneath. */}
      <TabPanel value={tabValue} index={0}>
        <UnitOfMeasurementsGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TasksGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ConsumablesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <TaskMetricsGrid />
      </TabPanel>
    </Box>
  );
};


export { TasksAndConsumablesTab };