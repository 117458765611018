// App.tsx
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AlertProvider } from "./context/AlertContext";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import AppLayout from "./layout/AppLayout";
import Home from "./pages/Home";
import Lists from "./pages/Lists";
import Rigs from "./pages/Rigs";
import Bits from "./pages/Bits";
import Clients from "./pages/Clients";
import Contracts from "./pages/Contracts";
import ContractsSetup from "./pages/Contracts-Setup";
import NewPlod from "./pages/New-Plod";
import ReviewAndSignoff from "./pages/Review-And-Signoff";
import Reports from "./pages/Reports";
import Invoices from "./pages/Invoices";
import InvoiceEdit from './pages/Invoice-Edit';
import Teams from "./pages/Teams";
import Configuration from "./pages/Configuration";
import Roles from './pages/Roles';
import { createTheme, CssBaseline, ThemeProvider, ThemeOptions, CardContent } from '@mui/material';
import { AuthWrapper } from "./middleware";
import "./App.css";
import type { } from '@mui/x-data-grid/themeAugmentation';

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState((localStorage.getItem("DarkMode") ?? 'false') === 'true');

  const themeOptions: ThemeOptions = {

    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: darkMode ? "#53CBFB" : "#1565c0", // Blue for primary elements
      },
      // background: {
      //   paper: darkMode ? "#111518" : "#ffffff",
      // },

      secondary: {
        main: "#ff4081", // Pink accent color
      },
      text: {
        primary: darkMode ? "#ffffff" : "#000000",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "*": {
            transition: "background-color 0.3s ease, color 0s",
          },
        },
      },
      MuiPaper: {

      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#003788",
            color: "#ffffff",
          },
        },
      },
      MuiAppBar: {

      },
      MuiButton: {

      },
      // MuiCard: {
      //   styleOverrides: {
      //     root: ({ theme }) => ({
      //       backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
      //       color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
      //     }),
      //   },
      // },
      // MuiTab: {
      //   styleOverrides: {
      //     root: ({ theme }) =>

      //       theme.applyStyles('dark', {
      //         '&.Mui-selected': {
      //           color: '#03a9f4',
      //         }
      //       })

      //   }
      // }

    },
    // mixins: {

    //   MuiDataGrid: {

    //     containerBackground: darkMode ? '#333' : '#f5f5f5',




    //   },
    // },

    // }
  };

  const appTheme = createTheme(themeOptions);


  return (
    <ThemeProvider theme={appTheme} >
      <CssBaseline />
      <AlertProvider>
        <Router>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={
              <AuthWrapper>
                <AppLayout darkMode={darkMode} setDarkMode={setDarkMode} />
              </AuthWrapper>
            }>
              {/* Nested routes */}
              <Route index element={<Home />} />
              <Route path="lists" element={<Lists />} />
              <Route path="rigs" element={<Rigs />} />
              <Route path="bits" element={<Bits />} />
              <Route path="clients" element={<Clients />} />
              <Route path="contracts" element={<Contracts />} />
              <Route path="contract-setup" element={<ContractsSetup />} />
              <Route path="new-plod" element={<NewPlod />} />
              <Route path="review-and-signoff" element={<ReviewAndSignoff />} />
              <Route path="reports" element={<Reports />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="invoice-edit" element={<InvoiceEdit />} />
              <Route path="teams" element={<Teams />} />
              <Route path="roles" element={<Roles />} />
              <Route path="configuration" element={<Configuration />} />
            </Route>
          </Routes>
        </Router>
      </AlertProvider>
    </ThemeProvider >
  );
};

export default App;
