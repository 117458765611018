import React, { useState } from 'react';
import { Box, Button, Chip, IconButton, Menu, styled, Tooltip } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridEditSingleSelectCellProps, GridRenderCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { Api, handleErrorResponse } from "../api";
import getInstanceID from '../functions/getInstanceID';
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import MiningCompanySelect from '../components/MiningCompanySelect';
import ContractSelect from '../components/ContractSelect';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IContract } from '../Interfaces/Contract.interface';
import { IMiningCompany } from '../Interfaces/MiningCompany.interface';
import CustomDialog, { ICustomDialogComponent } from '../components/CustomDialogComponent';
import { AlertService } from '../services/AlertService';
import dayjs from 'dayjs';
import { IDrillingProgram } from '../Interfaces/DrillingProgram.interface';
import { IGenericDropDownOption } from '../Interfaces/GenericDropDownOption.interface';
import validateAndSet from '../functions/ValidAndSetData';
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import DrillingProgramSelect from '../components/DrillingProgramSelect';
import { SingleSelectUseOptionsFromRowField } from '../components/CustomCells';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import { singleSelectComparator } from '../functions/singleSelectComparator';
import { IStatus } from '../Interfaces/Status.interface';
import RefreshIcon from '@mui/icons-material/Refresh';
const processingStatusRenderer = (params: GridRenderCellParams) => {
  if (!params.value) {
    return <></>;
  }

  const chipProps = {
    size: "small",
    variant: "outlined",
  };

  let color: "success" | "error" | "info" = "info";
  let label = params.value;
  let icon = <InfoIcon />;

  switch (params.value) {
    case "Processed":
      color = "success";
      label = "Processed";
      icon = <DoneIcon />;
      break;

    case "Error":
      color = "error";
      label = "Error";
      icon = <ReportProblemIcon />;
      break;

    default:
      color = "info";
      label = params.value; // Use the value as the label for unknown statuses
      icon = <InfoIcon />;
      break;
  }

  return <Chip size="small"
    variant="outlined" color={color} label={label} icon={icon} />;
};

const Invoices: React.FunctionComponent = () => {
  let InstanceID: string = getInstanceID();

  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  //#region Filters/selector things:
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setDrillingProgramSelectorID("");
    setDataRows([]);
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setDrillingProgramSelectorID("");
    setDataRows([]);
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
    setDataRows([]);
  };
  //#endregion


  const fetchData = async () => {
    setLoading(true);
    setDataRows([]);
    setDataGridError(null);
    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/~GetInvoicesWithStatuses`,
        JSON.stringify({ ID_EDP_DrillingProgram: drillingProgramSelectorID })
      );
      console.log(data);
      setDataRows(data);
    } catch (e) {
      setDataRows([]);
      setDataGridError("Error fetching Invoices.");
    };
    setLoading(false);
  };


  React.useEffect(() => {
    if (miningCompanySelectorID && contractSelectorID && drillingProgramSelectorID) {
      fetchData();
    }
  }, [miningCompanySelectorID, contractSelectorID, drillingProgramSelectorID]);


  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentInvoiceName: string = await dataRows.filter((row) => row.ID === ID)[0].DocumentNumber;
    const confirmed = await AlertService.showAlert(
      `Are you sure you want to delete the Invoice "${currentInvoiceName}".`,
      "question"
    );
    const stringID = ID.toString();
    if (confirmed) {
      // Temporarily clear the timer
      let timer: NodeJS.Timeout | undefined;
      try {
        // Attempt to delete the invoice
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/~DeleteItem`,
          JSON.stringify({ ID })
        );

        // Update the data rows
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Invoice "${currentInvoiceName}".`, "success");
      } catch (e: unknown) {

        handleErrorResponse(e, `Error Deleting Invoice "${currentInvoiceName}".`);
      }
    }
  };

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });



  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      const rawData = JSON.stringify({ ID: newRow.ID, ...newRow, StatusOptions: null });
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/~UpdateItem`,
        rawData
      );
      const updatedRow = {
        ...newRow,
        isNew: false,
      };;
      setDataRows((prev: any[]) =>
        prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
      );
      await AlertService.showAlert(`Successfully Updated Invoice "${newRow.DocumentNumber}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error Updating Invoice "${newRow.DocumentNumber}".`);
    }
    return newRow;
  };

  function EditToolbar() {
    const handleClick = () => {
      setInvoiceDialogOpen(true);
    };
    return (
      <GridToolbarContainer sx={{ height: "50px" }}>
        <Button style={{ marginLeft: "auto", marginRight: 0 }} color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={!miningCompanySelectorID || !contractSelectorID || !drillingProgramSelectorID}>
          Add Invoice
        </Button>
      </GridToolbarContainer>
    );
  };

  //#region Dialog
  const options: ICustomDialogComponent[] = [
    {
      headerName: 'Invoice Type',
      field: 'Type',
      type: 'singleSelect',
      valueOptions: ['Rosond Standard'],
      required: true,
      editable: true,
    },
    {
      headerName: 'Document Number',
      field: 'DocumentNumber',
      type: 'string',
      required: true,
    },
    {
      headerName: 'Start Date',
      field: 'StartDate',
      type: 'date',
      required: true,
      format: 'DD-MMM-YYYY',
    },
    {
      headerName: 'End Date',
      field: 'EndDate',
      type: 'date',
      required: true,
      format: 'DD-MMM-YYYY',
    },
    {
      headerName: 'Remarks',
      field: 'Remarks',
      type: 'string',
      editable: true,
      required: false,
    },
  ];


  const [invoiceDialogOpen, setInvoiceDialogOpen] = React.useState(false);

  const [formData, setFormData] = React.useState<any>({});
  const handleInvoiceDialogSubmit = async () => {
    setInvoiceDialogOpen(false);
    console.log('Form Data:', formData);

    try {
      //TODO: Make the timezone bs not appear here as no one likes it
      const rawData = JSON.stringify({
        ID_EDP_DrillingProgram: drillingProgramSelectorID,
        ...formData,
        StartDate: dayjs(formData.StartDate).format('YYYY-MM-DD'),
        EndDate: dayjs(formData.EndDate).format('YYYY-MM-DD')
      });
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/~GenerateInvoice`,
        rawData
      );
      setDataRows((prev: any) => [...prev, { ID: data.ID_IN_Invoice, ProcessingStatus: 'Processing', ...data }]);

      //Need to make it appear in list if it works.
      await AlertService.showAlert(`Successfully Added Invoice.`, "success");


      // Schedule the function call after 30 seconds and store the timer ID
      const timer = setTimeout(() => {
        fetchIndividualInvoice(data.ID_IN_Invoice, true)();
      }, 30000);


    } catch (e) {
      handleErrorResponse(e, `Error Adding Invoice.`); // need to put the message in here
    };


    setFormData({});
  };

  //TODO: Make it so that if you manually refresh, it kills any existing refreshes that happened -> prevent double ups with bugs etc

  //TODO: Potentially make this return all the options, and make it so that it will add the new ones to the bottom, and remove ones silently that have been deleted.
  const fetchIndividualInvoice = (ID: string, auto: boolean = false) => async () => {

    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${ID}/~GetInvoiceWithStatuses`
      );


      if (data.ProcessingStatus == "Processing" && auto) {
        //If the status if still processing... then it needs to go and wait another 30 seconds
        // Schedule the function call after 30 seconds and store the timer ID
        const timer = setTimeout(() => {
          fetchIndividualInvoice(ID, true);
        }, 30000);

      }
      else {
        //If the data is not processing anymore then update the row
        setDataRows((prev: any[]) =>
          prev.map((row) => (row.ID == ID ? data : row)) //get the line that exists here
        );
      }
    }
    catch {
      //If the data is not processing anymore then update the row
      setDataRows((prev: any[]) =>
        prev.map((row) => (row.ID == ID ? { ...row, ProcessingStatus: "Please Refresh" } : row)) // if it errors, just tell them to refresh the page, easiest solution.
      );
    }
  };
  //#endregion

  const handleDownloadClick = (ID: GridRowId) => async () => {
    try {

      // Make the API request to get the file data as binary (arraybuffer)
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/Invoices/ID_${ID}/~DownloadInvoice`,
        "",
        {
          responseType: 'blob', // Specify that we're expecting binary data
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([data]);

      // Create a link element
      const downloadLink = document.createElement("a");

      // Set the href attribute of the link to the blob URL
      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      downloadLink.download = `Invoice_${ID}.xlsx`;

      // Append the link to the document body
      document.body.appendChild(downloadLink);

      // Trigger the click event to initiate the download
      downloadLink.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(downloadLink);
      // Optionally, show a success alert or do any other operations
      await AlertService.showAlert('Invoice download started!', 'success');
    } catch (e) {
      // Handle errors (both the API call and file download)
      handleErrorResponse(e, 'Error downloading invoice.');
    }
  };



  return (
    <>
      <Box
        sx={{
          marginLeft: '15px',
          display: 'flex',
          flexWrap: 'wrap', // Allow wrapping of items
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0,
          '@media (max-width: 100px)': {
            flexDirection: 'column', // Stack items vertically on smaller screens
            alignItems: 'stretch', // Stretch to fill the available width
          },
        }}
      >

        <MiningCompanySelect
          plodID={InstanceID}
          value={miningCompanySelectorID}
          setValue={setMiningCompanySelectorID}
          onChange={handleMiningCompanyChange}
          miningCompanyOptions={miningCompanyOptions}
          setMiningCompanyOptions={setMiningCompanyOptions}
        />
        <ContractSelect
          plodID={InstanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          value={contractSelectorID}
          setValue={setContractSelectorID}
          onChange={handleContractChange}
          contractOptions={contractOptions}
          setContractOptions={setContractOptions}
        />
        <DrillingProgramSelect
          plodID={InstanceID}
          miningCompanySelectorID={miningCompanySelectorID}
          contractSelectorID={contractSelectorID}
          value={drillingProgramSelectorID}
          setValue={setDrillingProgramSelectorID}
          onChange={handleDrillingProgramChange}
          drillingProgramOptions={drillingProgramOptions}
          setDrillingProgramOptions={setDrillingProgramOptions}
        />
      </Box>

      <Box
        sx={{
          height: "60vh",
          margin: "15px",
        }}
      >
        <DataGridPremium
          columns={[
            {
              field: 'DocumentNumber',
              headerName: "Document Number",
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'Type',
              headerName: 'Invoice Type',
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'InvoiceDate',
              headerName: 'Invoice Date',
              flex: 1,
              minWidth: 200,
              valueGetter: (value, row) => value && dayjs(value).isValid() ? dayjs(value).format("DD-MMM-YYYY") : "",// should never not be null, but just being safe 
              //TODO: Fix the sorting on this one.
            },
            {
              field: 'ID_WF_Status',
              headerName: "Status",
              flex: 1,
              minWidth: 200,
              editable: true,
              type: 'singleSelect', // TODO: make this colour like the config in status setup
              valueOptions: [], //TODO: Make this so it will render the cell from itself aswell, otherwise will not work.
              renderCell: (params: GridEditSingleSelectCellProps) => {
                const options: any[] = params.row.StatusOptions;
                const item = options.filter((option) => option.value == params.value);
                return <span>{item?.[0]?.label}</span>; //really basic rendering off it, wish to add colour or similar at some point.
              },
              renderEditCell: (params: GridEditSingleSelectCellProps) => {
                return <SingleSelectUseOptionsFromRowField {...params} optionsField='StatusOptions' />;
              },
            },
            {
              field: 'Remarks',
              headerName: "Remarks",
              flex: 3,
              minWidth: 200,
              editable: true,
            },
            {
              field: 'ProcessingStatus',
              headerName: "Processing",
              resizable: false,
              hideSortIcons: true,
              width: 175,
              renderCell: processingStatusRenderer,
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              resizable: false,
              getActions: ({ id: ID, row }) => {
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                };
                const handleMenuClose = () => {
                  setAnchorEl(null);
                };
                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
                return isInEditMode ? [
                  <React.Fragment>
                    <Tooltip title="Save" key={`save-${ID}`}>
                      <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        className="textPrimary"
                        onClick={handleSaveClick(ID)}
                        color="inherit"
                        key={`save-${ID}`}
                      />
                    </Tooltip>
                  </React.Fragment>,
                  <React.Fragment>
                    <Tooltip title="Cancel" key={`cancel-${ID}`}>
                      <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(ID)}
                        color="inherit"
                        key={`cancel-${ID}`}
                      />
                    </Tooltip>
                  </React.Fragment>
                ] : [
                  <React.Fragment>
                    <Tooltip title="Download" key={`download-${ID}`}>
                      <GridActionsCellItem
                        icon={<DownloadIcon />}
                        label="Download"
                        color="inherit"
                        onClick={handleDownloadClick(ID)}
                        disabled={row.ProcessingStatus != "Processed"}
                      />
                    </Tooltip>
                  </React.Fragment>,
                  <React.Fragment>
                    <Tooltip title="More" key={`more-${ID}`}>
                      <IconButton onClick={handleMenuOpen} color="inherit">
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>,
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    onClick={() => handleMenuClose()}
                  >
                    {/* <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Archive"
                      className="textPrimary"
                      // onClick={handleEditClick(ID)}
                      color="inherit"
                      key={`edit-${ID}`}
                      showInMenu={true}
                    /> */}
                    <GridActionsCellItem
                      icon={<RefreshIcon />}
                      label="Refresh"
                      className="textPrimary"
                      onClick={fetchIndividualInvoice(ID.toString())}
                      color="inherit"
                      key={`refresh-${ID}`}
                      showInMenu={true}
                    />
                    <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                      className="textPrimary"
                      onClick={handleDeleteClick(ID)}
                      color="inherit"
                      key={`delete-${ID}`}
                      showInMenu={true}
                      disabled={row.ProcessingStatus == "Processing"}
                    />
                  </Menu>,
                ];
              },
            }
          ]}
          rows={dataRows}
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          editMode="row"
          disableRowSelectionOnClick
          loading={loading}
          getRowId={(row) => row.ID} // Gets the id from database, not the local style one
          // sx={{ minHeight: 300 }}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                message={dataGridError ? dataGridError : "No Invoices"}
                onRetry={dataGridError ? () => fetchData() : undefined}
              />
            ),
          }}
          isCellEditable={(params) => params.row.ProcessingStatus != "Processing"}
          disableAggregation
          disableColumnPinning
          disableRowGrouping
          disableColumnFilter
          disableColumnSelector
        />
      </Box>

      <CustomDialog
        title='Add Invoice'
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        options={options}
        data={formData}
        onFieldChange={(field, value) =>
          setFormData((prev: any) => ({ ...prev, [field]: value }))
        }
        handleSubmit={handleInvoiceDialogSubmit}
      />

    </>
  );
};

export default Invoices;
