import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

interface CustomTimePickerEditCellProps {
  fieldstoeditonchange?: {
    field: string,
    value: any,
  }[];
  mintimefield?: string;
  maxtimefield?: string;
}

export function CustomTimePickerEditCell(props: GridRenderEditCellParams & CustomTimePickerEditCellProps) {
  //TODO: make this more generic, E.G make it so that you can set the mintime and maxtime field.
  const { id, value, field, api, row, fieldstoeditonchange, mintimefield, maxtimefield } = props;

  // Ensure value is always Dayjs or null
  const convertedValue: Dayjs | null =
    value && dayjs(value).isValid() ? dayjs(value) : null;

  return (
    <div style={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={convertedValue} // Ensure it's Dayjs or null
          onChange={(newTime) => {
            if (newTime) {
              if (dayjs(newTime).isValid())
                api.setEditCellValue({ id, field, value: newTime.toISOString() });
              //This will update other fields with whatever value we want it to do.
              if (fieldstoeditonchange) {
                fieldstoeditonchange.forEach(fieldToEdit => {
                  api.setEditCellValue({ id, field: fieldToEdit.field, value: fieldToEdit.value });
                });
              }
            }
          }}
          minTime={
            mintimefield && row[mintimefield]
              ? dayjs(row[mintimefield]) // Convert FromTime to Dayjs
              : undefined
          }
          maxTime={
            maxtimefield && row[maxtimefield]
              ? dayjs(row[maxtimefield]) // Convert ToTime to Dayjs
              : undefined
          }
          ampm={false}
          timeSteps={{ minutes: 1 }}
          views={['hours', 'minutes']} // Views for hours and minutes
          slotProps={{
            popper: {
              sx: {
                '& .MuiList-root': {
                  scrollbarWidth: 'none',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
