
import React from "react";
import { FullCellInputLabel } from '../styled';
import { Box } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-premium";
import { ITaskChargeTypeOption } from "../../../Interfaces/ITaskChargeTypeOption.interface";

export function CustomAltChargeQuantityColumnCell(props: GridRenderEditCellParams & { taskChargeTypeOptions: ITaskChargeTypeOption[] }) {

  const chargeItem = props.taskChargeTypeOptions.find(x => x.value === props.row.ID_EDP_Task_ChargeType);
  if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
    return <FullCellInputLabel disabled />; // Display value without editing
  }
  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      <FullCellInputLabel disabled sx={{ marginLeft: '0px' }}>{chargeItem.UnitOfMeasurement_Name} QTY: {props.value}</FullCellInputLabel>
    </Box>
  );
};

