import React from 'react';
import { Select, MenuItem, CircularProgress, Stack, FormHelperText, SelectChangeEvent } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface GenericSelectProps<T> {
  label: string;
  plurificationText?: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: T[];
  disabled?: boolean;
  valueField: keyof T;
  labelField: keyof T;
  getLabelText?: (option: T) => string;  //If you would rather get the label manually.
  showAllOption?: boolean;
  loading?: boolean;
  customMessage?: string | null;
  showSeperateLabel?: boolean;
  variant?: 'outlined' | 'standard';
  stacksx?: SxProps<Theme>;
  selectsx?: SxProps<Theme>;
}
//ToDO: allow to pass in the option so can manually get label field, like how MUI does with datagrid stuff.
function GenericSelect<T>({
  label,
  plurificationText = "s",
  value,
  onChange,
  options,
  disabled = false,
  valueField,
  labelField,
  getLabelText,
  showAllOption = false,
  loading = false,
  customMessage,
  showSeperateLabel = true,
  variant = 'outlined',
  stacksx,
  selectsx
}: GenericSelectProps<T>) {
  return (
    <Stack spacing={0}
      sx={[
        (theme) => ({
          padding: "5px",
          paddingRight: "10px",
          ...stacksx
        }),
        ...(Array.isArray(selectsx) ? selectsx : [selectsx]),
      ]}>
      {showSeperateLabel && <FormHelperText>{label}</FormHelperText>}
      <Select
        id={label + "Selector"}
        value={loading ? '' : value ?? ''}
        onChange={onChange}
        disabled={disabled || options.length === 0 || loading}
        sx={[
          { width: 250 },
          ...(Array.isArray(selectsx) ? selectsx : [selectsx]),
        ]}

        displayEmpty
        variant={variant}
        aria-live={loading ? 'assertive' : 'off'} // Accessibility improvement
      >
        <MenuItem value="" disabled>
          {loading
            ? <CircularProgress size={24} style={{ margin: '0 auto', display: 'block' }} />
            : customMessage != null
              ? customMessage
              : options.length === 0
                ? `No ${label}${plurificationText}`
                : `Select a ${label}`}
        </MenuItem>

        {!loading && showAllOption && (
          <MenuItem key="All" value="-1">
            All
          </MenuItem>
        )}

        {!loading && options.map((data) => (
          <MenuItem key={data[valueField] as string} value={data[valueField] as string}>
            {getLabelText ? getLabelText(data) : data[labelField] as string}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

export default GenericSelect;
