import React from "react";
import NewPlodBox from "../newPlodBox";
import { IDrillingDetailsRow, ITaskRow } from "../../../Interfaces/Shift.interface";
import { IDPTaskOption } from "../../../Interfaces/DPTaskOption.interface";
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Slider, Paper, styled, CircularProgress
} from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { IActivityOverview } from "../../../Interfaces/IActivityOverview.interface";
import { randomId } from "@mui/x-data-grid-generator";


interface ActivityOverviewTableProps {
  DrillingDetailsTimeConfig: "FromToTime" | "Hours" | "None";
  TaskTimeConfig: "FromToTime" | "Hours";

  drillingDetailsRows?: IDrillingDetailsRow[],
  taskRows?: ITaskRow[];

  setDrillingDetailsRows: React.Dispatch<React.SetStateAction<IDrillingDetailsRow[]>>;
  setTaskRows: React.Dispatch<React.SetStateAction<ITaskRow[]>>;

  taskOptions: IDPTaskOption[];

  disabled: boolean;
  canEdit: boolean;
}

export const ActivityOverviewTable: React.FunctionComponent<ActivityOverviewTableProps> = ({
  DrillingDetailsTimeConfig,
  TaskTimeConfig,

  drillingDetailsRows,
  taskRows,

  setDrillingDetailsRows,
  setTaskRows,
  taskOptions,

  disabled,
  canEdit
}) => {

  const [activityOverviewRows, setActivityOverviewRows] = React.useState<IActivityOverview[]>([]);
  const [activityOverviewLoading, setActivityOverviewLoading] = React.useState<boolean>(false);
  const convertTimeToMinutes = (time: Dayjs | string | null): number | null => {
    return time && dayjs(time).isValid() ? dayjs(time).hour() * 60 + dayjs(time).minute() : null;
  };

  const mapTimeSlots = (items: any[], type: 'DrillingDetail' | 'Task' | 'TaskDrillingDetailCombo'): IActivityOverview[] => {
    return items.map(item => ({
      ID: randomId(),
      Type: type,
      refrenceID: item.ID,
      Name: type === "DrillingDetail" ? item.Hole : taskOptions.find(task => task.value == item.ID_EA_Task)?.label, // Dynamically set Name based on type
      FromTime: convertTimeToMinutes(item.FromTime),
      ToTime: convertTimeToMinutes(item.ToTime),
    } as IActivityOverview)).filter(line => line.FromTime != null && line.ToTime != null); // Filter out invalid times
  };

  React.useEffect(() => {
    if (drillingDetailsRows || taskRows) {
      setActivityOverviewLoading(true);
      // Combine the lists
      let CombinedList: IActivityOverview[] = [
        ...(drillingDetailsRows && DrillingDetailsTimeConfig == 'FromToTime' ? mapTimeSlots(drillingDetailsRows, "DrillingDetail") : []),
        ...(taskRows && TaskTimeConfig == 'FromToTime' ? mapTimeSlots(taskRows, "Task") : []),
      ];
      //?Here i need to see if any are not correct and remove
      CombinedList.sort((a, b) => { return a.FromTime! - b.FromTime!; });
      setActivityOverviewRows(CombinedList);
      setActivityOverviewLoading(false);
    };
  }, [
    drillingDetailsRows,
    taskRows,
    taskOptions,//This is only here so that it wont have the first load error.
  ]);

  const handleActivitySliderCommit = (newRange: number[], row: IActivityOverview) => {
    const newFrom = newRange[0];
    const newTo = newRange[1];
    const newFromDate = dayjs().startOf('day').add(newFrom, 'minute');
    const newToDate = dayjs().startOf('day').add(newTo, 'minute');
    if (row.Type === 'DrillingDetail') {
      setDrillingDetailsRows((prevRows) =>
        prevRows.map((item) =>
          item.ID === row.refrenceID
            ? {
              ...item,
              FromTime: newFromDate,
              ToTime: newToDate,
            }
            : item
        )
      );
    } else if (row.Type === 'Task') {
      setTaskRows((prevRows) =>
        prevRows.map((item) =>
          item.ID === row.refrenceID
            ? {
              ...item,
              FromTime: newFromDate,
              ToTime: newToDate,
            }
            : item
        )
      );
    }
  };

  const handleActivitySliderChange = (newRange: number[], ID: string | number) => {
    setActivityOverviewRows(activityOverviewRows.map(row =>
      row.ID === ID ? { ...row, FromTime: newRange[0], ToTime: newRange[1] } : row
    ));
  };

  return (
    <NewPlodBox color="#8c0808" text="Activity Overview" height="400px">
      <SliderTable
        activityOverviewRows={activityOverviewRows}
        handleSliderChange={handleActivitySliderChange}
        handleSliderCommit={handleActivitySliderCommit}
        loading={activityOverviewLoading}
        disabled={disabled || !canEdit}
      />
    </NewPlodBox>
  );
};

// Utility function to convert minutes into hour:minute format
const formatTime = (minutes: number) => `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`;

const CustomSlider = styled(Slider)({
  color: '#ffffff',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    color: '#4ffffff'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 6,
    height: 3,
  },
});
interface CustomTimeVisionItemProps {
  row: {
    ID: string | number;
    refrenceID: string | number;
    Type: 'DrillingDetail' | 'Task' | 'TaskDrillingDetailCombo';
    Name: string | number | null; //need to remove number at later date
    FromTime: number; // From time in hours (0-23)
    ToTime: number; // To time in hours (0-23)
  };
  handleSliderChange: (newValue: number[], id: string | number) => void;
  handleSliderCommit: (newRange: number[], row: IActivityOverview) => void;
  disabled: boolean;
}
// Custom Time Vision Item Component with Slider
const CustomTimeVisionItem: React.FC<CustomTimeVisionItemProps> = ({
  row,
  handleSliderChange,
  handleSliderCommit,
  disabled,
}) => {
  return (
    <CustomSlider
      value={[row.FromTime, row.ToTime]}
      min={0}
      max={1439}
      step={5}
      valueLabelDisplay="auto"
      onChangeCommitted={(event: any, newValue: any) => handleSliderCommit(newValue as number[], row)}
      onChange={(event: any, newValue: any) => handleSliderChange(newValue as number[], row.ID)}
      valueLabelFormat={formatTime}
      sx={{ width: "90%", height: "3px" }}
      disableSwap
      color={"info"}
      disabled={disabled}
    />
  );
};

// TimeVisionTable Component that accepts props for rows and change handler
interface SliderTableProps {
  activityOverviewRows: any[];
  handleSliderChange: (newValue: number[], id: string | number) => void;
  handleSliderCommit: (newRange: number[], row: IActivityOverview) => void;
  loading: boolean;
  disabled: boolean;
}


function SliderTable({
  activityOverviewRows,
  handleSliderChange,
  handleSliderCommit,
  loading,
  disabled,
}: SliderTableProps) {
  return (
    <TableContainer component={Paper} sx={{ height: 400, overflow: 'auto' }}> {/* Scrollable Table */}
      <Table aria-label="time vision table" >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Time Range</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={100} sx={{ textAlign: 'center', height: '343px' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : activityOverviewRows.length > 0 ? (
            activityOverviewRows.map((row) => (
              <TableRow key={row.ID} sx={{ backgroundColor: (row.Type == "Task" ? "rgb(60, 124, 59)" : row.Type == "DrillingDetail" ? "rgb(242, 107, 49)" : "#a09074") }}>
                <TableCell style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  color: "rgb(255, 255, 255)",
                  fontSize: '1rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                  {row.Name}
                </TableCell>
                <TableCell style={{
                  minWidth: "100px",
                  maxWidth: "100px",
                  color: "rgb(255, 255, 255)",
                  fontSize: '1rem',
                }}>
                  {formatTime(row.FromTime) + " - " + formatTime(row.ToTime)}
                </TableCell>
                <TableCell style={{ minWidth: "500px" }} component="th" colSpan={2}>
                  <CustomTimeVisionItem row={row}
                    handleSliderChange={handleSliderChange}
                    handleSliderCommit={handleSliderCommit}
                    disabled={disabled || loading} />
                </TableCell>
              </TableRow>
            ))) : (
            <TableRow >
              <TableCell colSpan={100} sx={{ textAlign: 'center', height: '343px', }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}