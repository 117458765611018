import React from "react";
import NewPlodBox from "../newPlodBox";
import CustomPlodSheetDataGrid from "../CustomPlodSheetDataGrid";
import { GridColDef, GridEditSingleSelectCellProps, GridRenderCellParams, GridRenderEditCellParams, GridRowModesModel } from "@mui/x-data-grid-premium";
import { HoursColumns } from "../Columns/";
import { getActionsForRow } from '../../EditableRowHandlers';
import { ITaskRow } from "../../../Interfaces/Shift.interface";
import { IDPTaskOption } from "../../../Interfaces/DPTaskOption.interface";
import { GenericToolbarSetNewRowLatestTime } from '../GenericToolbarSetNewRowLatestTime';
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import {
  CustomTaskColumnCell,
  CustomTaskEditColumnCell,
  CustomAltChargeQuantityColumnCell,
  CustomAltChargeQuantityEditColumnCell,
} from '../CustomCells';
import { ITaskChargeTypeOption } from "../../../Interfaces/ITaskChargeTypeOption.interface";
import { SetAnotherFieldFromSelectedOptionCell } from "../../CustomCells";

interface DrillingDetailsGridProps {
  canChangeTaskChargeType: boolean;
  TaskTimeConfig: string,
  taskOptions: IDPTaskOption[],// TODO: Make this the proper type
  taskChargeTypeOptions: ITaskChargeTypeOption[],// TODO: Make this the proper type

  tasksRows: ITaskRow[],
  tasksRowModesModel: GridRowModesModel, // The row modes model
  setTasksRowModesModel: (model: GridRowModesModel) => void, // A setter function for updating row modes
  setTasksRows: React.Dispatch<React.SetStateAction<ITaskRow[]>>;

  NewTasksDefaultFromTime: string;
  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const TasksGrid: React.FunctionComponent<DrillingDetailsGridProps> = ({
  canChangeTaskChargeType,
  TaskTimeConfig,
  taskOptions,
  taskChargeTypeOptions,
  tasksRows,
  tasksRowModesModel,
  setTasksRowModesModel,
  setTasksRows,
  NewTasksDefaultFromTime,
  loading,
  disabled,
  canEdit
}) => {


  //Pull the generic time columns
  const taskTimeColumns: GridColDef<any>[] = HoursColumns(TaskTimeConfig);

  const Handlers = useStandardEditableRowActions({
    dataRows: tasksRows,
    dataRowModesModel: tasksRowModesModel,
    setDataRowModesModel: setTasksRowModesModel,
    setDataRows: setTasksRows,
  });


  const taskColumns: GridColDef<any>[] = [
    ...taskTimeColumns,
    {
      field: "ID_EA_Task",
      headerName: "Task",
      flex: 2,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions.sort((a, b) => a.label.localeCompare(b.label)),
      renderEditCell: (params: GridEditSingleSelectCellProps) => <CustomTaskEditColumnCell {...params} taskOptions={taskOptions} />,
      renderCell: (params: GridRenderCellParams) => <CustomTaskColumnCell {...params} taskOptions={taskOptions} />,
    },
    {
      field: "ID_EDP_Task_ChargeType",
      headerName: "Charge",
      flex: 2,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: taskChargeTypeOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (
        <SetAnotherFieldFromSelectedOptionCell
          {...params}
          fieldstoeditonchange={
            [
              {
                field: 'AltChargeQuantity',
                value: null
              }
            ]
          }
          disabled={!canChangeTaskChargeType}
        />
      ),
    },
    {
      field: "AltChargeQuantity",
      headerName: "Alt Charge",
      flex: 2,
      minWidth: 200,
      editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <CustomAltChargeQuantityColumnCell {...params} taskChargeTypeOptions={taskChargeTypeOptions} />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomAltChargeQuantityEditColumnCell {...params} taskChargeTypeOptions={taskChargeTypeOptions} />
      ),
    },
    {
      field: "Comment",
      headerName: "Comment",
      flex: 4,
      minWidth: 200,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: tasksRowModesModel,
          handlers: Handlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
          disabled: !canEdit
        }),
    }
  ];

  const TaskToolbarComponent = NewTasksDefaultFromTime === 'LatestToTime'
    ? GenericToolbarSetNewRowLatestTime
    : undefined;

  return (
    <NewPlodBox color="#3c7c3b" text="Task" key="TaskDataGrid">
      <CustomPlodSheetDataGrid
        columns={taskColumns}
        rows={tasksRows}
        rowModesModel={tasksRowModesModel}
        setRows={setTasksRows}
        setRowModesModel={setTasksRowModesModel}
        fieldToFocus={"ID_EA_Task"}
        buttonText={"Add Task"}
        initialRow={{ ID_EA_Task: '', ID_EDP_Task_ChargeType: '', Comment: '' }}
        ToolbarComponent={TaskToolbarComponent}
        disabled={!canEdit || disabled}
        loading={loading}
        isCellEditable={(params) => canEdit && !disabled}
      />
    </NewPlodBox>
  );
};