import React from "react";
import { IDPTaskOption } from '../../../Interfaces/DPTaskOption.interface';
import { GridRenderCellParams, } from "@mui/x-data-grid-premium";
import { Box, Typography } from "@mui/material";
import { TaskToolTip } from '../styled/TaskToolTip.styled';

export function CustomTaskColumnCell(props: GridRenderCellParams & { taskOptions: IDPTaskOption[] }) {
  const currentTask: IDPTaskOption | undefined = props.taskOptions.find((option: IDPTaskOption) => option.value === props.value);

  if (!currentTask) return null;

  return (
    <TaskToolTip
      key={currentTask.value}
      title={
        <React.Fragment>
          <Typography color="inherit">{currentTask.label}</Typography>
          <div>{currentTask.LongDescription}</div>
        </React.Fragment>
      }
      arrow
      placement="right"
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        <strong >{currentTask.label}</strong>
        <Box
          sx={{
            fontSize: '12px',
            color: 'gray',
            marginTop: '4px',
            whiteSpace: 'nowrap',
          }}
        >
          {currentTask.LongDescription}
        </Box>
      </Box>
    </TaskToolTip>
  );
}
