import React from "react";
import getInstanceID from "../functions/getInstanceID";
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import RigLocationWidget from "../components/widgets/RigLocationsWidget";
import ShiftStatusWeekByWeekWidget from "../components/widgets/ShiftStatusWeekByWeekWidget";
import DrillingDepthAndHoursWidget from "../components/widgets/DrillingDepthAndHoursWidget";
import TasksPastThirtyDaysPieChart from "../components/widgets/TasksPastThirtyDaysPieChart";


const Home: React.FunctionComponent = () => {
  let instanceID = getInstanceID();

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, }}>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 5,
            mb: 8,
          }}
        >
          <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
            {/* cards */}
            <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
              Overview
            </Typography>
            <Grid
              container
              spacing={2}
              columns={12}
              sx={{ mb: (theme) => theme.spacing(2) }}
            >
              <Grid size={{ xs: 12, md: 12, lg: 12, xl: 6 }} sx={{ minWidth: 500 }}>
                <ShiftStatusWeekByWeekWidget />
              </Grid>
              <Grid size={{ xs: 12, md: 12, lg: 12, xl: 6 }} sx={{ minWidth: 500 }}>
                <   TasksPastThirtyDaysPieChart />
              </Grid>
              <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ minWidth: 500 }}>
                <DrillingDepthAndHoursWidget />
              </Grid>
              <Grid size={{ xs: 24, md: 12 }} height={500} sx={{ minWidth: 500 }}>
                <RigLocationWidget />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Home;
