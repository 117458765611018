import React from "react";
import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid-premium";

interface FireEventOnEditCellProps {
  fieldstoeditonchange: {
    field: string,
    value: any,
  }[]
}

export function FireEventOnEditCell(props: GridRenderEditCellParams & FireEventOnEditCellProps) {
  const { id, field, api } = props;
  return (
    <GridEditInputCell
      {...props}
      onValueChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        //sets the value of said field.
        api.setEditCellValue({ id, field, value: newValue });
        //This loops through and does editing of the event
        props.fieldstoeditonchange.forEach(fieldToEdit => {
          api.setEditCellValue({ id, field: fieldToEdit.field, value: fieldToEdit.value });
        });
      }
      }
    />
  );
}