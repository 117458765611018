import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  FormControl,
  TextField,
  Typography,
  Grid,
  Stack,
  AccordionActions
} from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridEditSingleSelectCellProps,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridSingleSelectColDef,
  GridToolbarContainer,
  MuiEvent,
  ValueOptions,
} from "@mui/x-data-grid-premium";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from "react-router-dom";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";
import { AlertService } from "../services/AlertService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MiningCompanySelect from "../components/MiningCompanySelect";
import ContractSelect from "../components/ContractSelect";
import DrillingProgramSelect from "../components/DrillingProgramSelect";
import { IContract } from "../Interfaces/Contract.interface";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import { TabPanel } from "../components/TabPanel";
import { DrillingProgramSetupTab } from '../components/ContractSetup/DrillingProgramSetupTab';
import { DrillingProgramCostTab } from '../components/ContractSetup/DrillingProgramCostTab';
import { IWorkflow } from "../Interfaces/Workflow.interface";
import GenericSelect from "../components/GenericSelect";

const ContractsSetup: React.FunctionComponent = () => {
  const instanceID = getInstanceID();
  const [dataError, setDataError] = useState<string | null>(null);

  //#region For Tab Control
  const [mainTab, setMainTab] = useState(0);

  // State for tracking the sub-tab selection
  const [tasksAndConsumablesTab, setTasksAndConsumablesTab] = useState(0);
  const [personnelAndDrillingTab, setPersonnelAndDrillingTab] = useState(0);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTab(newValue);
  };
  //#endregion

  //#region Contract Settings Tab
  const handleContractSettingsSubmit = async () => {
    try {
      const rawData: string = JSON.stringify(selectedContractData);
      const { data } = await Api.post(
        `/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/~UpdateItem`,
        rawData
      );
      setContractOptions((prev: any) =>
        prev.map((row: IContract) => (row.ID === selectedContractData?.ID ? selectedContractData : row))
      );
      await AlertService.showAlert(`Successfully Updated Contract "${selectedContractData?.ContractName}".`, "success");
    }
    catch (e) {
      handleErrorResponse(e, `Error Updating Contract "${selectedContractData?.ContractName}".`);
    }
  };

  //#endregion

  //#region DropDown selectors
  const [miningCompanySelectorID, setMiningCompanySelectorID] = useState<string>("");
  const [contractSelectorID, setContractSelectorID] = useState<string>("");
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] = useState<string>("");

  const [miningCompanyOptions, setMiningCompanyOptions] = useState<IMiningCompany[]>([]);
  const [contractOptions, setContractOptions] = useState<IContract[]>([]);
  const [drillingProgramOptions, setDrillingProgramOptions] = useState<IDrillingProgram[]>([]);

  const handleMiningCompanyChange = (value: string) => {
    setMiningCompanySelectorID(value);
    setContractSelectorID(""); // Reset contract selection
    setSelectedContractData(null);
    setDrillingProgramSelectorID(""); // Reset drilling program selections
  };

  const handleContractChange = (value: string) => {
    setContractSelectorID(value);
    setSelectedContractData(null);
    setDrillingProgramSelectorID(""); // Reset drilling program selection
  };

  const handleDrillingProgramChange = (value: string) => {
    setDrillingProgramSelectorID(value);
  };

  const [selectedContractData, setSelectedContractData] = React.useState<IContract | null>(null);

  const getContractOptions = () => {
    let f = contractOptions.find((prev: IContract) => prev.ID.toString() == contractSelectorID) || null;
    setSelectedContractData(f);
  };

  useEffect(() => {
    getContractOptions(); //TODO: Surely there has to be a better way to do this
  }, [contractSelectorID]);

  const [loadingWorkflowOptions, setLoadingWorkflowOptions] = React.useState<boolean>(true);
  const [workflowOptions, setWorkflowOptions] = React.useState<IWorkflow[]>([]);

  const fetchData = async () => {
    setLoadingWorkflowOptions(true);
    setWorkflowOptions([]);
    setDataError(null);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${instanceID}/Workflows`);
      setWorkflowOptions(data);
    } catch (e) {
      setWorkflowOptions([]);
      setDataError(`An error occured while loading Workflows, Please try again later.`);
      // handleErrorResponse(e, "Error while loading selectors"); //TODO: need a better way for this. that actually stops the page.
    }
    setLoadingWorkflowOptions(false);
  };

  useEffect(() => {
    fetchData(); //TODO: Surely there has to be a better way to do this
  }, [instanceID]);

  //#endregion

  const handleFieldChange = (field: string, value: any) => {
    if (field == "StartDate" || field == "EndDate") { // THIS IS DUMB SHIT TO GET IT NOT TO LOCALISE ahfsdkh
      try {
        let InDateFormat = dayjs(value);
        let convertedToString = InDateFormat.format('MM/DD/YYYY');
        if (convertedToString != "Invalid Date") {
          value = convertedToString;
        }
        else {
          value = null;
        }
      }
      catch (e) {
        value = null; // maybe show an error message but idk it works.
      }
    }
    setSelectedContractData((prev) => ({
      ...prev!,
      [field]: value,
    }));
  };

  //#region For adding and updating drilling programs
  const [drillingProgramDialogOpen, setDrillingProgramDialogOpen] = React.useState(false);
  const [drillingProgramDialogName, setDrillingProgramDialogName] = React.useState('');
  const [drillingProgramDialogAdding, setDrillingProgramDialogAdding] = React.useState(false);

  const handleDrillingProgramDialogSubmit = async () => {
    setDrillingProgramDialogOpen(false);

    var raw: string = JSON.stringify({
      ID: drillingProgramSelectorID,
      DrillingProgramName: drillingProgramDialogName,
      TotalMeters: 0,
      ReviewMeters: 0,
    });
    try {
      const endpoint = drillingProgramDialogAdding ? "~AddItem" : "~UpdateItem";
      const { data } = await Api.post(
        `/api/Plods/ID_${instanceID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms/${endpoint}`,
        raw
      );
      let updatedRow = { DrillingProgramName: drillingProgramDialogName, ID_EDP_DrillingProgram: data.ID };
      setDrillingProgramOptions((prevData: any) => {
        // Check if there's an existing row with the matching ID
        const existingRowIndex = prevData.findIndex((row: any) => row.ID_EDP_DrillingProgram === data.ID);
        if (existingRowIndex !== -1) {
          // If a match is found, update the existing row
          return prevData.map((row: any, index: number) =>
            index === existingRowIndex ? updatedRow : row
          );
        } else {
          // If no match, add updatedRow to the data
          return [...prevData, updatedRow];
        }
      });
      setDrillingProgramDialogOpen(false);
      await AlertService.showAlert(`Successfully ${drillingProgramDialogAdding ? "Added" : "Updated"} Drilling Program "${drillingProgramDialogName}".`, "success");

    } catch (e) {
      handleErrorResponse(e, `Error ${drillingProgramDialogAdding ? "Adding" : "Updating"} Drilling Program "${drillingProgramDialogName}".`);
      return;
    }
  };

  const handleDrillingProgramButtonClick = (ButtonAction: string) => {
    if (ButtonAction == "Update") {
      const ActiveDrillingProgram = drillingProgramOptions.find(
        (row: any) => row.ID_EDP_DrillingProgram.toString() == drillingProgramSelectorID
      );
      setDrillingProgramDialogName(ActiveDrillingProgram!.DrillingProgramName); // could probably do this more compact.
      setDrillingProgramDialogAdding(false);
    }
    else {
      setDrillingProgramDialogName(''); // could probably do this more compact.
      setDrillingProgramDialogAdding(true);
    }
    setDrillingProgramDialogOpen(true);
  };
  //#endregion

  return (
    <>
      {/* The Selectors */}
      <>
        <Box
          sx={{
            marginLeft: '15px',
            display: 'flex',
            flexWrap: 'wrap', // Allow wrapping of items
            flexDirection: 'row',
            alignItems: 'center',
            gap: 0,
            '@media (max-width: 100px)': {
              flexDirection: 'column', // Stack items vertically on smaller screens
              alignItems: 'stretch', // Stretch to fill the available width
            },
          }}
        >
          <MiningCompanySelect
            plodID={instanceID}
            value={miningCompanySelectorID}
            setValue={setMiningCompanySelectorID}
            onChange={handleMiningCompanyChange}
            miningCompanyOptions={miningCompanyOptions}
            setMiningCompanyOptions={setMiningCompanyOptions}
          />

          <ContractSelect
            plodID={instanceID}
            miningCompanySelectorID={miningCompanySelectorID}
            value={contractSelectorID}
            setValue={setContractSelectorID}
            onChange={handleContractChange}
            contractOptions={contractOptions}
            setContractOptions={setContractOptions}
          />
        </Box >

        <Stack direction='row' marginLeft='15px'>
          <DrillingProgramSelect
            plodID={instanceID}
            miningCompanySelectorID={miningCompanySelectorID}
            contractSelectorID={contractSelectorID}
            value={drillingProgramSelectorID}
            setValue={setDrillingProgramSelectorID}
            onChange={handleDrillingProgramChange}
            drillingProgramOptions={drillingProgramOptions}
            setDrillingProgramOptions={setDrillingProgramOptions}
          />

          <Stack spacing={0} padding="5px">
            <FormHelperText>&nbsp;</FormHelperText>   {/* This is so dumb - but it atleast keeps the height the same */}
            <IconButton
              sx={{
                border: '1px solid',
                borderColor: '#cbcbcb',
                height: "56px",
                width: "56px",
                borderRadius: 1,
                display: 'flex', // Aligns icon vertically
                alignItems: 'center', // Centers the icon vertically
                justifyContent: 'center', // Centers the icon horizontally
              }}
              disabled={drillingProgramSelectorID === ''}
              color="primary"
              onClick={() => handleDrillingProgramButtonClick("Update")}
            >
              <EditIcon />
            </IconButton>
          </Stack>

          <Stack spacing={0} padding="5px">
            <FormHelperText>&nbsp;</FormHelperText>   {/* This is so dumb - but it atleast keeps the height the same */}
            <IconButton
              sx={{
                border: '1px solid',
                borderColor: '#cbcbcb',
                height: "56px",
                width: "56px",
                borderRadius: 1,
                display: 'flex', // Aligns icon vertically
                alignItems: 'center', // Centers the icon vertically
                justifyContent: 'center', // Centers the icon horizontally
              }}
              disabled={contractSelectorID === ""}
              color="primary"
              onClick={() => handleDrillingProgramButtonClick("Add")}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Stack>
      </>
      <Accordion style={{ marginLeft: 21, marginTop: 10, width: "640px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Contract Settings
        </AccordionSummary>
        <AccordionDetails>
          {(dataError || !contractSelectorID || !miningCompanySelectorID) &&
            <Typography variant="body1" color={dataError ? "error" : "text.secondary"}>
              {dataError ? dataError : !miningCompanySelectorID ? 'Please select a Client' : !contractSelectorID ? 'Please select a Contract' : ''}
            </Typography>}
          {/*//TODO: Need to make this use the new grid, not depreciated one. */}
          <Grid container spacing={2}>
            {/* First Row: Invoice Workflow and Plod Workflow */}
            <Grid item xs={6}>
              <GenericSelect<IWorkflow>
                label="Invoice Workflow"
                value={selectedContractData?.ID_WF_Workflow_Invoice?.toString() ?? ''}
                onChange={(event: any) => handleFieldChange("ID_WF_Workflow_Invoice", event.target.value)}
                options={workflowOptions}
                disabled={!!dataError || contractSelectorID === ''}
                valueField="ID"
                labelField="WorkflowName"
                loading={loadingWorkflowOptions}
                selectsx={{ width: '100%' }}
                stacksx={{ padding: '0', paddingRight: '0' }}
              />
            </Grid>
            <Grid item xs={6}>
              <GenericSelect<IWorkflow>
                label="Plod Workflow"
                value={selectedContractData?.ID_WF_Workflow_Plod?.toString() ?? ''}
                onChange={(event: any) => handleFieldChange("ID_WF_Workflow_Plod", event.target.value)}
                options={workflowOptions}
                disabled={!!dataError || contractSelectorID === ''}
                valueField="ID"
                labelField="WorkflowName"
                loading={loadingWorkflowOptions}
                selectsx={{ width: '100%' }}
                stacksx={{ padding: '0', paddingRight: '0' }}
              />
            </Grid>

            {/* Second Row: Start Date and End Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <Stack spacing={0}>
                  <FormHelperText>Start Date</FormHelperText>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label=""
                    format="DD/MM/YYYY"
                    defaultValue={null}
                    disabled={!!dataError || contractSelectorID == ''}
                    value={selectedContractData?.StartDate != null ? dayjs(selectedContractData?.StartDate) : null}
                    onChange={(newValue: any) => handleFieldChange("StartDate", newValue)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={0}>
                  <FormHelperText>End Date</FormHelperText>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label=""
                    format="DD/MM/YYYY"
                    defaultValue={null}
                    disabled={!!dataError || contractSelectorID === ''}
                    value={selectedContractData?.EndDate != null ? dayjs(selectedContractData.EndDate) : null}
                    onChange={(newValue: any) => handleFieldChange("EndDate", newValue)}
                  />
                </Stack>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            sx={{
              height: "28px",
              width: "250px",
            }}
            variant="outlined"
            disabled={contractSelectorID === ''}
            onClick={handleContractSettingsSubmit}
          >
            Save Changes
          </Button>
        </AccordionActions>
      </Accordion>


      <Box sx={{ width: "100%", }}>
        <Tabs //TODO: Put this into a custom component so that I can have it smaller etc.
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={mainTab}
          onChange={handleMainTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="main tabs"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            marginLeft: '15px', // Ensure no left margin
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab sx={{ height: 60 }} label="Drilling Program Setup" />
          <Tab sx={{ height: 60 }} label="Drilling Program Cost" />
        </Tabs>
        <TabPanel value={mainTab} index={0}>
          <DrillingProgramSetupTab
            ID_EA_MiningCompany={miningCompanySelectorID}
            ID_EC_Contract={contractSelectorID}
            ID_EDP_DrillingProgram={drillingProgramSelectorID}
            tabValue={tasksAndConsumablesTab}
            onTabChange={setTasksAndConsumablesTab}
          />
        </TabPanel>
        <TabPanel value={mainTab} index={1}>
          <DrillingProgramCostTab
            ID_EA_MiningCompany={miningCompanySelectorID}
            ID_EC_Contract={contractSelectorID}
            ID_EDP_DrillingProgram={drillingProgramSelectorID}
            tabValue={personnelAndDrillingTab}
            onTabChange={setPersonnelAndDrillingTab}
          />
        </TabPanel>
      </Box>
      <Dialog
        open={drillingProgramDialogOpen}
        onClose={() => setDrillingProgramDialogOpen(false)}
        maxWidth="sm" fullWidth>
        <DialogTitle>{drillingProgramDialogAdding ? "Add Drilling Program" : "Update Drilling Program"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="standard"
            margin="dense"
            autoFocus
            required
            fullWidth
            value={drillingProgramDialogName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDrillingProgramDialogName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDrillingProgramDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDrillingProgramDialogSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractsSetup;
