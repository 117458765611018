import React from "react";
import NewPlodBox from "../newPlodBox";
import CustomPlodSheetDataGrid from "../CustomPlodSheetDataGrid";
import { GridColDef, GridEditSingleSelectCell, GridEditSingleSelectCellProps, GridRowModesModel } from "@mui/x-data-grid-premium";
import { getActionsForRow } from '../../EditableRowHandlers';
import { IPersonnelRow } from "../../../Interfaces/Shift.interface";
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import { SetAnotherFieldFromSelectedOptionCell } from "../../CustomCells";
import { singleSelectComparator } from "../../../functions/singleSelectComparator";
import { IGenericDropDownOption } from "../../../Interfaces/GenericDropDownOption.interface";
import { IDPPersonnelOption } from "../../../Interfaces/DPPersonnelOption";

interface PersonnelsGridProps {
  canEditPersonnelType: boolean;
  personnelOptions: IDPPersonnelOption[];
  personnelTypeOptions: IGenericDropDownOption[]; //TODO: Make this the proper type.

  personnelRows: IPersonnelRow[];
  personnelRowModesModel: GridRowModesModel;
  setPersonnelRowModesModel: (model: GridRowModesModel) => void;
  setPersonnelRows: React.Dispatch<React.SetStateAction<IPersonnelRow[]>>;

  loading: boolean;
  disabled: boolean;
  canEdit: boolean;
}

export const PersonnelsGrid: React.FunctionComponent<PersonnelsGridProps> = ({
  canEditPersonnelType,
  personnelOptions,
  personnelTypeOptions,

  personnelRows,
  personnelRowModesModel,
  setPersonnelRowModesModel,
  setPersonnelRows,

  loading,
  disabled,
  canEdit
}) => {

  const Handlers = useStandardEditableRowActions({
    dataRows: personnelRows,
    dataRowModesModel: personnelRowModesModel,
    setDataRowModesModel: setPersonnelRowModesModel,
    setDataRows: setPersonnelRows,
  });

  const Columns: GridColDef<any>[] = [
    {
      field: "ID_EA_Personnel",
      headerName: "Personnel",
      flex: 2,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (
        <SetAnotherFieldFromSelectedOptionCell
          {...params}
          fieldstogetandsetonchange={
            [
              {
                field: 'ID_EA_PersonnelType',
                fieldtogetvalue: 'ID_EA_PersonnelType'
              }
            ]
          }
        />
      ),
      sortComparator: singleSelectComparator,
    },
    {
      field: "ID_EA_PersonnelType",
      headerName: "Personnel Type",
      flex: 2,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelTypeOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (

        <GridEditSingleSelectCell {...params} disabled={!canEditPersonnelType} />
      )
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 5,
      minWidth: 200,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: personnelRowModesModel,
          handlers: Handlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
          disabled: !canEdit
        }),
    }
  ];


  return (
    <NewPlodBox color="#5f2d87" text="Personnel" key="PersonnelDataGrid">
      <CustomPlodSheetDataGrid
        columns={Columns}
        rows={personnelRows}
        rowModesModel={personnelRowModesModel}
        setRows={setPersonnelRows}
        setRowModesModel={setPersonnelRowModesModel}
        fieldToFocus={"ID_EA_Personnel"}
        buttonText={"Add Personnel"}
        initialRow={{ ID_EA_Personnel: '', ID_EA_PersonnelType: '', Hours: 0, Comment: '' }}
        disabled={!canEdit || disabled}
        loading={loading}
        isCellEditable={(params) => canEdit && !disabled}
      />
    </NewPlodBox>
  );
};