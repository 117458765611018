import React from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import getInstanceID from "../../../functions/getInstanceID";
import { AlertService } from '../../../services/AlertService';
import { GenericDataGrid } from "../../../components/GenericDatagrid";
import { IWorkflow } from "../../../Interfaces/Workflow.interface";

export const WorkflowsGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: 'WorkflowName',
      editable: true,
      minWidth: 200,
      flex: 4,
    },
  ];

  const initialRow: Partial<IWorkflow> = {
    WorkflowName: ''
  };

  const validateFields = async (row: IWorkflow): Promise<boolean> => {
    if (!row.WorkflowName || row.WorkflowName === "") {
      await AlertService.showAlert("Workflow Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IWorkflow>
        name="Workflow"
        baseURL={`/api/Plods/ID_${instanceID}/Workflows/`}
        columns={columns}
        primaryField="WorkflowName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};