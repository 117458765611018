import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { Api } from "../../../api";
import { IOneOffCharge } from '../../../Interfaces/OneOffCharge.interface';

interface DrillingProgramOneOffChargesGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramOneOffChargesGrid: React.FC<DrillingProgramOneOffChargesGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'One_Off_Charge_Name',
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: 'Charge',
      field: 'One_Off_Charge',
      editable: true,
      flex: 1,
      minWidth: 200,
      type: "number",
      valueFormatter: (params) => {
        if (params == null) {
          return "$";
        }
        return "$" + (Math.round(params * 100) / 100).toFixed(2);
      }, // actually could maybe use for plod components
      headerAlign: "left",
      align: "left",
    },
  ];

  const initialRow: Partial<IOneOffCharge> = {
    One_Off_Charge_Name: '',
    One_Off_Charge: 0,
  };

  const validateFields = async (row: IOneOffCharge): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.One_Off_Charge_Name) validationErrors.push("Name cannot be empty.");
    if (!row.One_Off_Charge) validationErrors.push("Charge cannot be empty.");
    else if (row.One_Off_Charge < 0) validationErrors.push("Charge cannot be less than 0.");
    if (validationErrors.length) {
      //Need to make this show the Task Charge Rates name. 'Task Charge Rates {name} row'
      await AlertService.showAlert(`One Off Charges "${row.One_Off_Charge}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };

  async function handleFetch(): Promise<IOneOffCharge[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const { data } = await Api.post(
          `/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/OneOffCharges/`,
        );
        return data as IOneOffCharge[];
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IOneOffCharge>
        name="One Off Charge"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/OneOffCharges/`}
        columns={columns}
        primaryField="One_Off_Charge_Name"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
      />
    </Box>
  );
};
