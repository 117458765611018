//#region Imports!
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { GenericToolbarProps } from './CustomPlodSheetDataGrid';
import {
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { ITaskRow } from "../../Interfaces/Shift.interface";
import { randomId } from "@mui/x-data-grid-generator";
import { IDPTaskOption } from '../../Interfaces/DPTaskOption.interface';
import { calculateHourAndMinuteDifference } from "../../functions/calculateHourAndMinuteDifference";
interface DrillingToolbarSetNewRowStartDrillingDetailsProps<T> extends GenericToolbarProps<T> {
  taskRows: ITaskRow[];
  taskOptions: IDPTaskOption[];
}
export const DrillingToolbarSetNewRowStartDrillingDetails = <T,>({
  setRows,
  setRowModesModel,
  initialRow,
  fieldToFocus,
  buttonText,
  disabled,
  taskRows,
  taskOptions
}: DrillingToolbarSetNewRowStartDrillingDetailsProps<T>,
) => {
  const handleAddClick = () => {
    const ID = randomId(); // Ensure randomId() is defined or imported
    // Filter all tasks with drilling type
    const allValidDrillingTasksInGrid = taskRows.filter((taskRow) => {
      const taskOption = taskOptions.find((option) => option.value === taskRow.ID_EA_Task);
      return (
        taskOption?.IsDrillingTask === true &&
        taskRow.FromTime != null && dayjs(taskRow.FromTime).isValid() &&
        taskRow.ToTime != null && dayjs(taskRow.ToTime).isValid()
      );
    });
    //TODO: Make this get the latest from edit rows as well.
    // Update the rows state
    setRows((oldRows) => {
      let newFromTime: Dayjs | string | null = null;
      let newToTime: Dayjs | string | null = null;

      // Sort drilling tasks by FromTime and find the latest non-overlapping ToTime
      allValidDrillingTasksInGrid
        .sort((a, b) => {
          return calculateHourAndMinuteDifference(a.FromTime, b.FromTime);
        })
        .forEach((drillingTask) => {
          const isOverlapping = oldRows.some((oldRow: any) =>
            dayjs(oldRow.FromTime).format("HH:mm") === dayjs(drillingTask.FromTime).format("HH:mm")
          );

          if (!isOverlapping) {
            newFromTime = drillingTask.FromTime;
            newToTime = drillingTask.ToTime;
          }
        });

      // If no valid latestToTime is found, derive it from old rows
      if (!newFromTime) {
        //This loops and gets the latest time.
        newFromTime = oldRows
          .map((row: any) => row.ToTime)
          .reduce((latest, current) => {
            const latestDate = new Date(latest);
            const currentDate = new Date(current);
            return currentDate > latestDate ? current : latest;
          }, new Date(0).setHours(0));

        // Default to epoch time if still null
        if (!newFromTime) {
          newFromTime = dayjs(new Date(0).setHours(0));
        }
      }

      //Convert to dayjs format otherwise it all breaks
      const dayjsnewdate = dayjs(newFromTime);

      const newRow = {
        ...initialRow,
        ID,
        FromTime: dayjsnewdate, // New FromTime based on latest ToTime
        ToTime: newToTime != null ? dayjs(newToTime) : null, //Put the ToTime if it exists on the plodsheet.
        isNew: true,
      };

      // Return the updated rows with the new row added
      return [...oldRows, newRow];
    });
    setRowModesModel((oldModel: T) => ({
      ...oldModel,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        style={{ marginLeft: 'auto', marginRight: 0 }}
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </GridToolbarContainer>
  );
};