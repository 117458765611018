import { GridRowId, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-premium';
import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
interface BaseRow {
  ID: any; // "ID" is required for every row
  isNew?: boolean; // "isNew" is optional, but will always be present at some point
}

interface UseEditableRowActionsProps<T extends BaseRow> {
  dataRows: T[]; // The data rows, typed as an array of T (which extends BaseRow)
  dataRowModesModel: GridRowModesModel; // The row modes model
  setDataRowModesModel: (model: GridRowModesModel) => void; // A setter function for updating row modes
  setDataRows: (rows: T[]) => void; // A setter function for updating rows
  fieldToFocus?: keyof T extends string ? keyof T : never; // sets the field name thing.
  rowTemplate?: Partial<T>; // The rowTemplate, typed to match the structure of T
}

const useStandardEditableRowActions = <T extends BaseRow>({
  dataRows,
  dataRowModesModel,
  setDataRowModesModel,
  setDataRows,
  fieldToFocus,
  rowTemplate,
}: UseEditableRowActionsProps<T>) => {
  // Handle the "Save" action
  const handleSaveClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.View },
    };
    setDataRowModesModel(newModel); // Directly set the new model
  };

  // Handle the "Cancel" action
  const handleCancelClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    };
    setDataRowModesModel(newModel); // Directly set the new model
    const editedRow = dataRows.find((row) => row.ID === ID);
    if (editedRow?.isNew) {
      setDataRows(dataRows.filter((row) => row.ID !== ID)); // Remove the new row if it's canceled
    }
  };

  // Handle the "Edit" action
  const handleEditClick = (ID: GridRowId) => () => {
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    };
    setDataRowModesModel(newModel); // Directly set the new model
  };

  // Update row modes model when any mode changes
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel); // Directly set the new model
  };

  const handleAddClick = () => {
    const ID = randomId();
    setDataRows([
      ...dataRows,
      { ID, ...rowTemplate, isNew: true } as T, // Add the new row, isNew will always be true for new rows
    ]);
    const newModel: GridRowModesModel = {
      ...dataRowModesModel,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
    };
    setDataRowModesModel(newModel); // Directly set the new model
  };


  const handleDeleteClick = (ID: GridRowId) => () => {
    setDataRows(dataRows.filter((row) => row.ID !== ID)); // Using ID to filter
  };

  return {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
    handleAddClick,
    handleDeleteClick,
  };
};

export default useStandardEditableRowActions;
