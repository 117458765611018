import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Api } from "../api";
import { IDrillingProgram } from '../Interfaces/DrillingProgram.interface';
import GenericSelect from "./GenericSelect";

interface DrillingProgramSelectProps {
  plodID: string;
  miningCompanySelectorID: string;
  contractSelectorID: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  width?: string;
  showAllField?: boolean;
  drillingProgramOptions: IDrillingProgram[];
  setDrillingProgramOptions: React.Dispatch<React.SetStateAction<IDrillingProgram[]>>;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
}

const DrillingProgramSelect: React.FC<DrillingProgramSelectProps> = ({
  plodID,
  miningCompanySelectorID,
  contractSelectorID,
  value,
  onChange,
  errorMessage,
  disabled,
  width = "250px",
  showAllField = false,
  drillingProgramOptions,
  setDrillingProgramOptions,
  setValue
}) => {
  const [loading, setLoading] = React.useState(false);
  const [controller, setController] = useState<AbortController | null>(null);

  const fetchDrillingProgramData = async () => {
    if (controller) {
      // Abort the previous request
      controller.abort();
    }

    const newController = new AbortController();
    setController(newController);

    setLoading(true);
    setDrillingProgramOptions([]);

    if (!miningCompanySelectorID || !contractSelectorID || contractSelectorID === "-1") {
      setDrillingProgramOptions([]);
      setLoading(false);
      return;
    }

    try {
      const { data } = await Api.post(
        `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/ID_${contractSelectorID}/DrillingPrograms`,
        null,
        { signal: newController.signal }
      );

      let localitem = localStorage.getItem("ID_EDP_DrillingProgram"); // Read from localStorage
      if (localitem != null && setValue) {
        if (data.some((x: { ID_EDP_DrillingProgram: any }) => x.ID_EDP_DrillingProgram == localitem)) {
          setValue(localitem);
        } else {
          localStorage.removeItem("ID_EDP_DrillingProgram");
        }
      }
      setDrillingProgramOptions(data);
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching Drilling Programs", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (miningCompanySelectorID || contractSelectorID) {
      fetchDrillingProgramData();
    }

    // Cleanup on component unmount
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [contractSelectorID, plodID, miningCompanySelectorID]);

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    localStorage.setItem("ID_EDP_DrillingProgram", event.target.value); // Save to localStorage
    onChange(event.target.value);
  };

  return (
    <GenericSelect<IDrillingProgram>
      label="Drilling Program"
      value={contractSelectorID === "-1" ? "-1" : value}
      onChange={handleSelectChange}
      options={drillingProgramOptions}
      disabled={disabled || (drillingProgramOptions.length === 0 && !contractSelectorID) || errorMessage != null}
      valueField="ID_EDP_DrillingProgram"
      labelField="DrillingProgramName"
      loading={loading}
      showAllOption={showAllField}
    />
  );
};

export default DrillingProgramSelect;
