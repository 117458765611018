import React from "react";
import {
  GridEditBooleanCell,
  GridEditBooleanCellProps,
} from "@mui/x-data-grid-premium";

export function CustomEditBooleanOnlyShowIfTypeCell(props: GridEditBooleanCellProps & { mustbethistype: string }) {
  if (props.row.type == props.mustbethistype) {
    return <GridEditBooleanCell {...props} />;
  }
  else {
    return null;
  }
}
