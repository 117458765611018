import React, { useEffect } from "react";
import { FormControl, FormControlLabel, FormLabel, Grid2 as Grid, Radio, RadioGroup, SelectChangeEvent, } from "@mui/material";
import { IStatusWithOrder } from '../../Interfaces/Shift.interface';
import GenericSelect from '../GenericSelect';


interface StatusMigrateOptionProps {
  statusOptions: IStatusWithOrder[];
  statusFilter: string;

  statusPromoteFilter: string;
  setStatusPromoteFilter: React.Dispatch<React.SetStateAction<string>>;

  // statusChangeRadioButtonValue: string;
  // setStatusChangeRadioButtonValue: React.Dispatch<React.SetStateAction<string>>;

  loading: boolean;
  disabled: boolean;
}

export const StatusMigrateOption: React.FC<StatusMigrateOptionProps> = (props: StatusMigrateOptionProps) => {
  const {
    statusOptions,
    statusFilter,

    statusPromoteFilter,
    setStatusPromoteFilter,

    // statusChangeRadioButtonValue,
    // setStatusChangeRadioButtonValue,

    loading,
    disabled,
  } = props;
  const [statusChangeRadioButtonValue, setStatusChangeRadioButtonValue] = React.useState('migrate');
  const [nextStatusID, setNextStatusID] = React.useState<string>('');

  const handleStatusPromoteFilterChange = (event: SelectChangeEvent) => {
    const value = event.target.value.toString();
    if (value != statusFilter && value != nextStatusID) {
      setStatusChangeRadioButtonValue('');
    }
    else if (value == statusFilter) {
      setStatusChangeRadioButtonValue('retain');
    }
    else if (value == nextStatusID) {
      setStatusChangeRadioButtonValue('migrate');
    }
    setStatusPromoteFilter(value);
  };


  const setNearestStatusOption = (value: string) => {
    if (value == "migrate") {
      const currentStatus = statusOptions.find((status) => status.ID == statusFilter) as IStatusWithOrder | null;
      if (currentStatus) {
        // Sort the statuses by StatusOrder first if necessary (one-time sort).
        const sortedStatuses = [...statusOptions].sort((a, b) => a.StatusOrder - b.StatusOrder);

        // Find the index of the current status
        const currentIndex = sortedStatuses.findIndex((status) => status.ID === currentStatus.ID);

        // Find the closest "other" option (next or previous, depending on your need).
        let closestOtherOption: IStatusWithOrder | null = null;

        if (currentIndex !== -1) {
          // Example: Closest next status
          closestOtherOption = sortedStatuses[currentIndex + 1] || sortedStatuses[currentIndex]; // trys to get next, otherwise it get current

          // this will try and get the closest that isn't this value. 
          setNextStatusID(closestOtherOption.ID.toString());
          setStatusPromoteFilter(closestOtherOption.ID.toString());
          return;
        }
      }
    }
    setStatusPromoteFilter(statusFilter);
  };

  useEffect(() => {
    if (statusOptions.length < 2) { // if there can only be one status, then migrate it to another, otherwise just behave as normal.
      setStatusChangeRadioButtonValue('retain');
      setNearestStatusOption('retain');
    }
    else {
      setStatusChangeRadioButtonValue('migrate');
      setNearestStatusOption('migrate');
    }
  },
    [statusFilter, statusOptions]
  );


  const handleStatusChangeRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as string;
    setStatusChangeRadioButtonValue(value);
    setNearestStatusOption(value);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"

      sx={{
        margin: '15px', // shorthand for both marginLeft and marginRight
      }}
    >
      <Grid size="auto">
        <FormControl>
          <FormLabel id="retain-or-promote-status-label">Promote</FormLabel>
          <RadioGroup
            aria-labelledby="retain-or-promote-status-label"
            defaultValue="migrate"
            name="retain-or-promote-status"
            value={statusChangeRadioButtonValue}
            onChange={handleStatusChangeRadioButtonChange}
          >
            <FormControlLabel
              disabled={disabled}
              value="retain"
              control={<Radio />}
              label="Retain status"
            />
            <FormControlLabel
              disabled={statusOptions.length < 2 || disabled}
              value="migrate"
              control={<Radio />}
              label="Migrate to next status"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid size="auto" sx={{
        justifyContent: "space-evenly",
        alignItems: "center",
      }}>
        <GenericSelect<IStatusWithOrder>
          label="Status"
          plurificationText="es"
          value={statusPromoteFilter}
          onChange={handleStatusPromoteFilterChange}
          options={statusOptions}
          disabled={disabled} // locks it to the first item
          valueField="ID"
          labelField="StatusName"
          loading={loading}
          stacksx={{ padding: 0 }}
        />
      </Grid>
    </Grid>
  );
};