import React from "react";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import getInstanceID from "../functions/getInstanceID";
import { AlertService } from '../services/AlertService';
import { GenericDataGrid } from "../components/GenericDatagrid";
import { IRig } from "../Interfaces/Rig.interface";
import { CustomLocationEditCell } from "../components/CustomCells";

const Rigs: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      field: 'RigName',
      headerName: "Rig Name",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'Latitude',
      headerName: 'Latitude',
      flex: 1,
      minWidth: 125,
      editable: true,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return (
          <CustomLocationEditCell
            {...params}
          />
        );
      },
    },
    {
      field: 'Longitude',
      headerName: 'Longitude',
      flex: 1,
      minWidth: 125,
      editable: true,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return (
          <CustomLocationEditCell
            {...params}
          />
        );
      },
    },
  ];

  const initialRow: Partial<IRig> = {
    RigName: ''
  };

  const validateFields = async (row: IRig): Promise<boolean> => {
    const validationErrors = [];
    if (!row.RigName?.trim()) validationErrors.push("Rig Name cannot be empty.");
    if (row.Latitude > 90 || row.Latitude < -90) validationErrors.push("Latitude must be between -90 and 90");
    if (row.Longitude > 180 || row.Longitude < -180) validationErrors.push("Longitude must be between -180 and 180");
    if (validationErrors.length) {
      await AlertService.showAlert(`Rig "${row.RigName}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IRig>
        name="Rig"
        baseURL={`/api/Plods/ID_${instanceID}/Rigs/`}
        columns={columns}
        primaryField="RigName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};

export default Rigs;