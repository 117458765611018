import React from "react";
import { Box } from "@mui/material";
import { GridColDef, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { IDPRig } from "../../../Interfaces/DPRig.interface";
import { Api } from "../../../api";
import { SingleSelectOnlyOptionsNotUsedCell } from '../../CustomCells/SingleSelectOnlyOptionsNotUsedCell';
import { singleSelectComparator } from '../../../functions/singleSelectComparator';
import { IRig } from '../../../Interfaces/Rig.interface';

interface DrillingProgramRigsGridProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
}

export const DrillingProgramRigsGrid: React.FC<DrillingProgramRigsGridProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram
}) => {
  const instanceID = getInstanceID();
  const [rigOptions, setRigOptions] = React.useState<IRig[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "ID_EA_Rig",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: rigOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        return <SingleSelectOnlyOptionsNotUsedCell {...params} valueField="ID" labelField="RigName" />;
      },
      getOptionValue: (value) => (value as IRig).ID,
      getOptionLabel: (value) => (value as IRig).RigName,
      sortComparator: singleSelectComparator,
    },
  ];

  const initialRow: Partial<IDPRig> = {
    ID_EA_Rig: '',
  };

  const validateFields = async (row: IDPRig): Promise<boolean> => {
    if (row.ID_EA_Rig === "") {
      await AlertService.showAlert("You must select a Rig", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<IDPRig[]> {
    try {
      if (ID_EA_MiningCompany && ID_EC_Contract && ID_EDP_DrillingProgram) {
        const body = {
          query: `{
            Plods(ID_AP_Plod: ${instanceID}) {
              MiningCompanys(ID: ${ID_EA_MiningCompany}) {
                Contracts(ID: ${ID_EC_Contract}) {
                  DrillingPrograms(ID: ${ID_EDP_DrillingProgram}) {
                    DPRigs {
                      ID
                      ID_EA_Rig
                    }
                  }
                }
              }
              Rigs {
                ID
                RigName
              }
            }
          }`
        };

        const { data } = await Api.post(
          `/api/Plods/~GraphQL`,
          body
        );

        const { Rigs, MiningCompanys } = data.data.Plods[0];
        const { DPRigs } = MiningCompanys[0].Contracts[0].DrillingPrograms[0];

        //Checks the data.
        if (!Rigs) throw new Error("Missing Rigs");
        if (!DPRigs) throw new Error("Missing DPRigs");

        //Sets the location options and returns the sites.
        setRigOptions(Rigs);
        return DPRigs;
      }
      else {
        return [];
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  const gridMessage = !ID_EA_MiningCompany ? "Please select a Client"
    : !ID_EC_Contract ? "Please select a Contract"
      : !ID_EDP_DrillingProgram ? "Please select a Drilling Program"
        : undefined;

  return (
    <Box sx={{ height: '50vh', margin: '10px' }}>
      <GenericDataGrid<IDPRig>
        name="Rig"
        baseURL={`/api/Plods/ID_${instanceID}/MiningCompanys/ID_${ID_EA_MiningCompany}/Contracts/ID_${ID_EC_Contract}/DrillingPrograms/ID_${ID_EDP_DrillingProgram}/DPRigs`}
        columns={columns}
        primaryField="ID_EA_Rig"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
        disabledAddButton={!ID_EA_MiningCompany || !ID_EC_Contract || !ID_EDP_DrillingProgram}
        gridMessage={gridMessage}
        isCellEditable={
          // only allow to add and delete, cannot edit.
          (params) => {
            if (params.row.isNew) {
              return true;
            }
            else {
              return false;
            }
          }
        }
        canEdit={false}
      />
    </Box>
  );
};
