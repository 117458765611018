import React from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import getInstanceID from "../../../functions/getInstanceID";
import { AlertService } from '../../../services/AlertService';
import { GenericDataGrid } from "../../../components/GenericDatagrid";
import { IStatus } from '../../../Interfaces/Status.interface';
import { ColourPickerCell, ColourPickerEditCell } from '../../ColourPickerCell';

export const StatusesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: 'StatusName',
      editable: true,
      minWidth: 200,
      flex: 4,
    },
    {
      field: 'Colour',
      headerName: 'Colour',
      renderCell: (params) => <ColourPickerCell {...params} />,
      renderEditCell: (params) => <ColourPickerEditCell {...params} />,
      width: 100,
      editable: true,
    },
  ];

  const initialRow: Partial<IStatus> = {
    StatusName: ''
  };

  const validateFields = async (row: IStatus): Promise<boolean> => {
    if (!row.StatusName || row.StatusName === "") {
      await AlertService.showAlert("Status Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<IStatus>
        name="Status"
        baseURL={`/api/Plods/ID_${instanceID}/Statuses/`}
        columns={columns}
        primaryField="StatusName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};