import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import React from "react";
import { Api } from "../../../api";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from "../../GenericDatagrid";
import { IRoleMenuAccess } from "../../../Interfaces/RoleMenuAccess.interface";

interface RoleMenuPermissionsGridProps {
  RoleID: string;
  Disabled: boolean;
}
const RoleMenuPermissionsGrid: React.FunctionComponent<RoleMenuPermissionsGridProps> = ({ RoleID, Disabled }) => {
  let instanceID = getInstanceID();

  let columns: GridColDef<any>[] = [
    {
      headerName: "Menu",
      field: "Menu",
      flex: 2,
      minWidth: 200,
      editable: false,
      type: "string",
    },
    {
      headerName: "Active",
      field: "Active",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "boolean",
    },
  ];

  async function handleFetch(): Promise<IRoleMenuAccess[]> {
    try {
      if (RoleID) {
        const { data } = await Api.post(
          `/api/Plods/ID_${instanceID}/Roles/ID_${RoleID}/MenuItem/`
        );
        return data;
      }
      else {
        return []; // If you return a Promise.reject, it will treat it like an error.
      }
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  return (
    <Box sx={{ height: "70vh", maxHeight: '800px', margin: "10px" }}>
      <GenericDataGrid<IRoleMenuAccess>
        name="Task"
        baseURL={`/api/Plods/ID_${instanceID}/Roles/ID_${RoleID}/MenuItem/`}
        columns={columns}
        primaryField="Menu"
        initialRow={{}}
        handleFetch={handleFetch}
        gridMessage={!RoleID ? "Please Select a Role" : undefined}
        canAdd={false}
        canDelete={false}
      />
    </Box>
  );
};

export { RoleMenuPermissionsGrid };
