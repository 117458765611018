import React, { useEffect, useState } from "react";
import {
  DataGridPremium,
  GridColDef,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { Api, handleErrorResponse } from "../api";
import getInstanceID from "../functions/getInstanceID";
import { AlertService } from "../services/AlertService";
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import { GenericToolbar } from "./GenericToolbar";
import { getActionsForRow } from "./EditableRowHandlers";
import { GridCellParams } from '@mui/x-data-grid';
import { GenericToolbarProps } from "./GenericToolbar";

//TODO: make it so that it passes up a "handle fetch" and then set the data.
interface BaseRow {
  ID: string | number;
  isNew?: boolean;
}
interface GenericDataGridProps<T extends BaseRow> {
  name: string;
  baseURL: string;
  columns: GridColDef[];
  primaryField: keyof Omit<T, "ID" | "isNew">;
  initialRow: Partial<Omit<T, "ID" | "isNew">>;
  gridMessage?: string | null;
  showGridRetryFetchButton?: boolean;
  loading?: boolean;
  disabledAddButton?: boolean;
  validateFields?: (row: T) => boolean | Promise<boolean>;// Allows for manual validation of rows. E.G if you want it to have to have certain values.
  isCellEditable?: (params: GridCellParams) => boolean;
  handleFetch?: () => T[] | Promise<T[]>;

  fetchURL?: string;
  deleteURL?: string;
  updateURL?: string;
  addURL?: string;

  //Maybe move these along with handlers into a sub component?
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;

  // canArchive?: boolean;
  // handleAddClick?: (ID: GridRowId) => () => void;
  // handleDeleteClick?: (ID: GridRowId) => () => void;
  // handleArchiveClick?: (ID: GridRowId) => () => void;

  getRowName?: (row: T) => string;

  ToolbarComponent?: React.ComponentType<GenericToolbarProps<T>>; // Allow custom toolbar

}
/**
 * @description
 * A generic and reusable data grid component for performing CRUD (Create, Read, Update, Delete) operations. 
 * It allows the user to:
 * - Fetch data from a given API endpoint.
 * - Add, edit, and delete rows with customizable actions.
 * - Perform row-level validation before saving changes.
 * 
 * @template T
 * @param {string} name - The name of the data entity (e.g., "Bit", "Rig").
 * @param {string} baseURL - The base API URL for CRUD operations.
 * @param {GridColDef[]} columns - The column definitions to be displayed in the grid.
 * @param {keyof Omit<T, "ID" | "isNew">} primaryField - The primary field used to identify each row.
 * @param {Partial<Omit<T, "ID" | "isNew">>} initialRow - The initial structure of a new row.
 * @param {string | null} [gridMessage] - Optional message to show on the grid -> If null or undefined will show internal error message.
 * @param {boolean} [showGridRetryFetchButton] - Optional will show retry button when grid message is not blank -> Default is false.
 * @param {boolean} [loading] - Optional will show loading animation when true.
 * @param {boolean} [disabledAddButton] - Optional will show disable the add {name} button when true.
 * @param {Function} [handleFetch] - Optional function for manually handling the fetch of data.
 * @param {Function} [validateFields] - Optional function for row-level validation before saving.
 * @param {string} [fetchURL] - Optional URL to fetch existing data.
 * @param {string} [deleteURL] - Optional URL for deleting data.
 * @param {string} [updateURL] - Optional URL for updating data.
 * @param {string} [canAdd] - Optional to set if will show save button.
 * @param {string} [canEdit] - Optional to set if will show edit button.
 * @param {string} [canDelete] - Optional URL for adding new data.
 * @param {Function} [getRowName] - Optional for getting a rows name (useful if you want a combination instead of just the field.)
 * @param {React.ComponentType<GenericToolbarProps<T>>} [ToolbarComponent] - Optional if you want to give your own toolbar component. Defaults to generic with add button.
 * 
 * @returns {JSX
 * .Element} The rendered `GenericDataGrid` component.
 * 
 * @note The type `T` passed to the component must have an `ID` field of type `string` or `number`, as it is used to uniquely identify each row in the grid.
 */

export const GenericDataGrid = <T extends BaseRow>({
  name,
  baseURL,
  columns,
  primaryField,
  initialRow,
  gridMessage,
  showGridRetryFetchButton = false,
  loading,
  disabledAddButton = false,
  isCellEditable,
  handleFetch,
  fetchURL,
  deleteURL,
  updateURL,
  addURL,
  validateFields,
  canAdd = true,
  canEdit = true,
  canDelete = true,
  getRowName,
  ToolbarComponent,
}: GenericDataGridProps<T>) => {

  const instanceID = getInstanceID();
  const [dataRows, setDataRows] = useState<T[]>([]);
  const [gridLoading, setGridLoading] = useState<boolean>(true);
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => {
    fetchData();
  }, [instanceID, baseURL, fetchURL]);

  const fetchData = async () => {
    //? Might need to look into have an array of values called "required" values, that will trigger an update, and will not load data unless it has all off them.
    setGridLoading(true);
    setDataGridError(null);
    try {
      if (handleFetch) {
        const data = await Promise.resolve(handleFetch()); // If provided a custom handle fetch, just need to return the "rows to display."
        setDataRows(data);
      }
      else {
        const { data } = await Api.post(
          fetchURL ?? baseURL);
        setDataRows(data);
      }
    } catch (e) {
      setDataGridError(`An error occurred while loading ${name} data.`);
    }
    setGridLoading(false);
  };

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });
  const getCurrentRowName = (row: T): string => {
    const rowName = getRowName ? getRowName(row) : `"${row[primaryField]}"`;
    const rowNamePretty = !rowName ? ` ${rowName}` : rowName;
    return rowNamePretty;
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentRow = dataRows.filter((row) => row.ID === ID)[0];
    const currentRowName = getCurrentRowName(currentRow);
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the ${name} ${currentRowName}.`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          deleteURL ?? `${baseURL}/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted the ${name} ${currentRowName}.`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting the ${name} ${currentRowName}.`);
      }
    }
  };

  const processRowUpdate = async (newRow: T): Promise<T> => {
    if (validateFields) {
      const isValid = await Promise.resolve(validateFields(newRow));
      if (!isValid) {
        return Promise.reject("Validation failed"); // This rejects the promise and halts the update
      }
    }

    const currentRowName = getCurrentRowName(newRow);
    //TODO: Make it have some validation you can code up here
    try {
      const rawData = JSON.stringify({ ...newRow });
      const addEndpoint = addURL ?? `${baseURL}/~AddItem`;
      const updateEndpoint = updateURL ?? `${baseURL}/~UpdateItem`;
      const { data } = await Api.post(
        newRow.isNew ? addEndpoint : updateEndpoint,
        rawData
      );
      const updatedRow = {
        ...newRow,
        ID: data.ID ?? newRow.ID,
        isNew: false,
      };
      setDataRows((prev) =>
        prev.map((row) => {
          if (row.ID === newRow.ID) {
            return updatedRow as T; // Ensures the result conforms to type T
          }
          return row;
        })
      );

      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} ${name} ${currentRowName}.`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} ${name} ${currentRowName}.`);
      return Promise.reject("Error Saving"); // This rejects the promise and halts the update
    }
  };

  const columnsWithActions: GridColDef[] = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      resizable: false,
      getActions: ({ id: ID }) => getActionsForRow({
        ID,
        rowModesModel: dataRowModesModel,
        handlers: {
          handleDeleteClick,
          handleCancelClick,
          handleEditClick,
          handleSaveClick
        },
        actions: {
          cancel: canEdit || canAdd,
          edit: canEdit,
          save: canEdit || canAdd,
          delete: canDelete
        },
      }),
    },
  ];

  // If no custom ToolbarComponent, fall back to GenericToolbar
  const DaToolbar = ToolbarComponent ? (
    <ToolbarComponent
      setRows={setDataRows}
      setRowModesModel={setDataRowModesModel}
      fieldToFocus={primaryField}
      buttonText={`Add ${name}`}
      disabled={disabledAddButton || loading || gridLoading}
      initialRow={initialRow}
      showButton={canAdd}
    />
  ) : (
    <GenericToolbar
      setRows={setDataRows}
      setRowModesModel={setDataRowModesModel}
      fieldToFocus={primaryField}
      buttonText={`Add ${name}`}
      disabled={disabledAddButton || loading || gridLoading}
      initialRow={initialRow}
      showButton={canAdd}
    />
  );


  return (
    <DataGridPremium
      columns={columnsWithActions}
      rows={dataRows}
      loading={gridLoading || loading}
      rowModesModel={dataRowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      processRowUpdate={processRowUpdate}
      editMode="row"
      disableRowSelectionOnClick
      getRowId={(row) => row.ID} // Gets the id from database, not the local style one
      slots={{
        toolbar: () => (DaToolbar),
        noRowsOverlay: () => (
          <CustomNoRowsOverlay
            message={gridMessage ? gridMessage : dataGridError ? dataGridError : `No ${name} Data`}
            onRetry={gridMessage ? showGridRetryFetchButton ? () => fetchData() : undefined : dataGridError ? () => fetchData() : undefined}
          />
        ),
      }}
      isCellEditable={isCellEditable}
      disableAggregation
      disableColumnPinning
      disableRowGrouping
      disableColumnFilter
      disableColumnSelector
      disableColumnResize={columns.length < 2}
      style={{ overflow: 'auto' }} // Ensure scroll bars are enabled
    />
  );
};