import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../functions/getInstanceID";
import { AlertService } from "../../services/AlertService";
import { IBitType } from '../../Interfaces/BitType.interface';
import { GenericDataGrid } from "../GenericDatagrid";

export const BitTypesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: 'BitType',
      field: 'BitType',
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  const initialRow: Partial<IBitType> = {
    BitType: ''
  };

  const validateFields = async (row: IBitType): Promise<boolean> => {
    if (!row.BitType || row.BitType === "") {
      await AlertService.showAlert("Bit Type cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '80vh', margin: '10px', }}>
      <GenericDataGrid<IBitType>
        name="Bit Type"
        baseURL={`/api/Plods/ID_${instanceID}/BitTypes/`}
        columns={columns}
        primaryField="BitType"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
