import { Grid2 } from "@mui/material";
import React from "react";
import { TeamMemberContractPermissionCard } from ".";

interface ITeamMemberMiningAccessCards {
  miningAccess: any[]
  setMiningAccess: React.Dispatch<React.SetStateAction<any[]>>;
  disabled: boolean;
}

const TeamMemberMiningAccessCards: React.FunctionComponent<ITeamMemberMiningAccessCards> = ({
  miningAccess,
  setMiningAccess,
  disabled
}) => {
  function handleDeleteMiningCompanyClick(MiningCompanyID: string): void {
    setMiningAccess(prev => prev.filter((miningcompany) => miningcompany.ID_EA_MiningCompany != MiningCompanyID)); // Using ID to filter
  }

  function handleSelectedItemsUpdate(MiningCompanyID: string, SelectedItems: string[]) {
    //This will save it to the main thing (too lazy to try and make it work with it lol)
    setMiningAccess((prev: any[]) => prev.map((miningcompany: any) =>
    (miningcompany.ID_EA_MiningCompany.toString() == MiningCompanyID
      ? {
        ...miningcompany,
        AllContracts: SelectedItems.some((item: string) => item == 'all'),
        ContractAccessYN: miningcompany.ContractAccessYN.map((contract: any) => ({
          ...contract,
          Access: SelectedItems.some((item: string) => item == contract.ID_EC_Contract),
        })),
      }
      : {
        ...miningcompany
      }
    )));
  }

  return (
    <>
      <Grid2
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
        }}
        columns={16}
      >
        {miningAccess.map((item: any, index) => (
          <Grid2 key={index} size="auto">
            <TeamMemberContractPermissionCard
              miningAccess={item}
              handleDeleteMiningCompanyClick={handleDeleteMiningCompanyClick}
              handleSelectedItemsUpdate={handleSelectedItemsUpdate}
              disabled={disabled}
            />
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};

export { TeamMemberMiningAccessCards };