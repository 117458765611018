import React, { useState } from "react";
import { Box } from "@mui/material";
import { GridApi, GridColDef, GridComparatorFn, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { ISite } from '../../../Interfaces/Site.interface';
import { Api } from "../../../api";
import { ILocation } from "../../../Interfaces/Location.interface";
import { singleSelectComparator } from "../../../functions/singleSelectComparator";

//TODO: make it so that it passes up a "handle fetch" and then set the data.
export const SitesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const [locationOptions, setLocationOptions] = useState<ILocation[]>([]);

  const columns: GridColDef[] = [
    {
      headerName: "Site Name",
      field: "SiteName",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Location Name",
      field: "ID_EA_Location",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: locationOptions,
      getOptionValue: (value) => (value as ILocation).ID,
      getOptionLabel: (value) => (value as ILocation).LocationName,
      sortComparator: singleSelectComparator
    },
  ];

  const initialRow: Partial<ISite> = {
    SiteName: '',
  };

  const validateFields = async (row: ISite): Promise<boolean> => {
    const validationErrors: string[] = [];
    if (!row.SiteName?.trim()) validationErrors.push("Site Name cannot be empty.");
    if (!row.ID_EA_Location) validationErrors.push("Location cannot be empty.");
    if (validationErrors.length) {
      await AlertService.showAlert(`Site "${row.SiteName}" row has fields causing errors.`, 'criticalerror', validationErrors.join("\n"));
      return false;
    }
    return true; // Validation passed
  };


  async function handleFetch(): Promise<ISite[]> {
    try {
      const body = {
        query: `{
          Plods(ID_AP_Plod: ${instanceID}) {
            Locations {
              ID
              LocationName
            }
            Sites {
              ID
              SiteName
              ID_EA_Location
            }
          }
        }`
      };

      const { data } = await Api.post(
        `/api/Plods/~GraphQL`,
        body
      );

      const { Locations, Sites } = data.data.Plods[0];
      //Checks the data.
      if (!Sites) throw new Error("Missing Sites");
      if (!Locations) throw new Error("Missing Locations");
      //Sets the location options and returns the sites.
      setLocationOptions(Locations);
      return Sites;
    } catch (e) {
      return Promise.reject("An error occured while fetching data"); // This rejects the promise and halts the update
    }
  }

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<ISite>
        name="Sites"
        baseURL={`/api/Plods/ID_${instanceID}/Sites/`}
        columns={columns}
        primaryField="SiteName"
        initialRow={initialRow}
        validateFields={validateFields}
        handleFetch={handleFetch}
      />
    </Box>
  );
};
