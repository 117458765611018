import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../functions/getInstanceID";
import { IBitSize } from '../../Interfaces/BitSize.interface';
import { GenericDataGrid } from '../GenericDatagrid';
import { AlertService } from "../../services/AlertService";

export const BitSizesGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: 'Size',
      field: 'Size',
      editable: true,
      flex: 1,
      minWidth: 200,
    }
  ];

  const initialRow: Partial<IBitSize> = {
    Size: ''
  };

  const validateFields = async (row: IBitSize): Promise<boolean> => {
    if (!row.Size || row.Size === "") {
      await AlertService.showAlert("Bit Size cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '80vh', margin: '10px' }}>
      <GenericDataGrid<IBitSize>
        name="Bit Size"
        baseURL={`/api/Plods/ID_${instanceID}/BitSizes/`}
        columns={columns}
        primaryField="Size"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
