import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  RichTreeView,
  useTreeViewApiRef,
} from "@mui/x-tree-view";
import React from "react";


interface ITeamMemberContractPermissionCard {
  miningAccess: any;
  handleDeleteMiningCompanyClick: (MiningCompanyID: string) => void;
  handleSelectedItemsUpdate: (MiningCompanyID: string, SelectedItems: string[]) => void;
  disabled: boolean;
}

const TeamMemberContractPermissionCard: React.FunctionComponent<ITeamMemberContractPermissionCard> = (
  {
    miningAccess,
    handleDeleteMiningCompanyClick,
    handleSelectedItemsUpdate,
    disabled,
  }) => {

  const myItems = [
    {
      id: "all",
      label: "All Contracts",
      children: miningAccess.ContractAccessYN.map((contract: any) => ({
        id: contract.ID_EC_Contract.toString(),
        label: contract.ContractName,
      })),
    },
  ];

  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    [
      ...(miningAccess.AllContracts ? ['all'] : []), // Add 'all' if AllContracts is true
      ...miningAccess.ContractAccessYN
        .filter((contract: any) => contract.Access) // Filter only contracts with Access
        .map((contract: any) => contract.ID_EC_Contract.toString()), // Map to strings
    ]
  );


  const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
  const apiRef: any = useTreeViewApiRef();

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean
  ) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent,
    newSelectedItems: string[]
  ) => {
    const itemsToSelect: string[] = [];
    const itemsToUnselect: { [itemId: string]: boolean } = {};
    const toggledValue = toggledItemRef.current;
    const [keyName, isSelected] = Object.entries(toggledValue)[0];
    if (keyName == 'all') {
      //Do all contracts here
      if (isSelected) {
        let mythings = [...myItems[0].children.map((children: any) => children.id.toString())];
        itemsToSelect.push('all', ...mythings);
      }
      else {
        // Adding each item from `myItems` with `contract.ID_EC_Contract` as the key and true as the value
        myItems[0].children.forEach((children: any) => {
          itemsToUnselect[children.id.toString()] = true;
        });
      }
    }
    else {
      //Do just an indivual one if that exists
      if (isSelected) {
        //If all are selected then it will set the parent to the 'AllContracts' request.
        const allIdsPresent = myItems[0].children.every((child: any) =>
          newSelectedItems.includes(child.id)
        );
        if (allIdsPresent) {
          itemsToSelect.push('all');
        }
        itemsToSelect.push(keyName);
      }
      else {
        itemsToUnselect['all'] = true;
        itemsToUnselect[keyName] = true;
      }
    }

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnselect[itemId]
        )
      )
    );
    handleSelectedItemsUpdate(miningAccess.ID_EA_MiningCompany.toString(), newSelectedItemsWithChildren);
    setSelectedItems(newSelectedItemsWithChildren);
    toggledItemRef.current = {};
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mt: 2,
        ml: 2,
        mb: 2,
        width: "100%",
        minWidth: 400,
        maxWidth: 400,
      }}
      key={miningAccess.ID_EA_MiningCompany}
    >
      <CardContent sx={{ position: "relative" }}>
        <Typography variant="body1">
          <strong>{miningAccess.MiningCompanyName}</strong>
        </Typography>
        <IconButton sx={{
          position: "absolute",
          top: 0,
          right: 0,
          cursor: "pointer",
        }}
          disabled={disabled}
          onClick={() => handleDeleteMiningCompanyClick(miningAccess.ID_EA_MiningCompany.toString())}>
          <CloseIcon

          />
        </IconButton>

        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: "100%", maxWidth: 250 }}>
            <RichTreeView
              disableSelection={disabled}
              multiSelect
              checkboxSelection
              apiRef={apiRef}
              items={myItems}
              selectedItems={selectedItems}
              onSelectedItemsChange={handleSelectedItemsChange}
              onItemSelectionToggle={handleItemSelectionToggle}
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export { TeamMemberContractPermissionCard };