
import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../components/TabPanel';
import getInstanceID from '../functions/getInstanceID';
import { WorkflowsGrid, StatusesGrid, WorkflowConfigGrid } from '../components/Configuration/Workflow Setup/';

const Configuration: React.FC = () => {
  let instanceID: string = getInstanceID();

  const [secondaryTabValue, setSecondaryTabValue] = useState<number>(0);
  const handleSecondaryTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSecondaryTabValue(newValue);
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile
        value={0}
        // onChange={handleMainTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Config To Edit Selector"
        sx={{
          display: 'flex', // Use flexbox to control the alignment
          justifyContent: 'flex-start', // Align to the left to avoid indentation
          marginLeft: '15px', // Ensure no left margin
          '.MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0,
            width: 0
          }
        }}
      >
        <Tab sx={{ width: 175, height: 60 }} value={0} label="WorkFlow Setup" />
        {/* <Tab sx={{ maxWidth: 175, height: 60 }}  disabled={loading} value={1} label="Instance Config" /> */}
      </Tabs>
      <TabPanel value={0} index={0}>
        <Box sx={{ height: "90vh", }}>
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile
            value={secondaryTabValue}
            onChange={handleSecondaryTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Config To Edit Selector"
            sx={{
              marginLeft: '15px',
              display: 'flex', // Use flexbox to control the alignment
              justifyContent: 'flex-start', // Align to the left to avoid indentation
              '.MuiTabs-scrollButtons.Mui-disabled': {
                opacity: 0,
                width: 0
              }
            }}
          >
            <Tab sx={{ width: 175, height: 60 }} label="Workflows" />
            <Tab sx={{ width: 175, height: 60 }} label="Statuses" />
            <Tab sx={{ width: 175, height: 60 }} label="Workflow Config" />
          </Tabs>
          <TabPanel value={secondaryTabValue} index={0}>
            {secondaryTabValue === 0 && <WorkflowsGrid />}  {/* Load only when selected */}
          </TabPanel>
          <TabPanel value={secondaryTabValue} index={1}>
            {secondaryTabValue === 1 && <StatusesGrid />}
          </TabPanel>
          <TabPanel value={secondaryTabValue} index={2}>
            {secondaryTabValue === 2 && <WorkflowConfigGrid />}
          </TabPanel>
        </Box>
      </TabPanel>
    </>
  );
};

export default Configuration;

