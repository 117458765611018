import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import getInstanceID from "../../../functions/getInstanceID";
import { GenericDataGrid } from '../../GenericDatagrid';
import { AlertService } from "../../../services/AlertService";
import { ITask } from '../../../Interfaces/Task.interface';

export const TasksGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "TaskName",
      editable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Long Description",
      field: "LongDescription",
      editable: true,
      flex: 2,
      minWidth: 400,
    },
  ];

  const initialRow: Partial<ITask> = {
    TaskName: '',
  };

  const validateFields = async (row: ITask): Promise<boolean> => {
    if (!row.TaskName || row.TaskName === "") {
      await AlertService.showAlert("Task Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '90vh', margin: '10px' }}>
      <GenericDataGrid<ITask>
        name="Task"
        baseURL={`/api/Plods/ID_${instanceID}/Tasks/`}
        columns={columns}
        primaryField="TaskName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
};
