import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { TabPanel } from "./../TabPanel";
import {
  DrillingProgramRigsGrid,
  DrillingProgramConsumablesGrid,
  DrillingProgramSitesGrid,
  DrillingProgramPersonnelsGrid,
  DrillingProgramTasksGrid,
  DrillingProgramBitSizesGrid,
  DrillingProgramBitTypesGrid,
  DrillingProgramObjectivesGrid
} from './DrillingProgramSetup';

interface DrillingProgramSetupTabProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
  tabValue: number;
  onTabChange: (newValue: number) => void;
}

export const DrillingProgramSetupTab: React.FC<DrillingProgramSetupTabProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram,
  tabValue,
  onTabChange
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '15px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Drilling Program Setup Tab Selector"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab label="Rigs" />
          <Tab label="Consumables" />
          <Tab label="Sites" />
          <Tab label="Personnel" />
          <Tab label="Tasks" />
          <Tab label="Bit Sizes" />
          <Tab label="Bit Types" />
          <Tab label="Objectives" />
        </Tabs>
      </Box>
      {/*The Grids that show underneath. */}
      <TabPanel value={tabValue} index={0}>
        <DrillingProgramRigsGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DrillingProgramConsumablesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DrillingProgramSitesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <DrillingProgramPersonnelsGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <DrillingProgramTasksGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <DrillingProgramBitSizesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <DrillingProgramBitTypesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <DrillingProgramObjectivesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
    </Box>
  );
};