import {
  Box,
  SelectChangeEvent,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import GenericSelect from "./../GenericSelect";
import { TabPanel } from "./../TabPanel";
import { RoleMenuPermissionsGrid, RoleWorkflowPermissionsGrid } from './Components';

const RoleAccessTab: React.FunctionComponent = () => {
  let InstanceID = getInstanceID();
  const [tabValue, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataError, setDataError] = useState<string | null>(null);
  const [roleID, setRoleID] = React.useState('');
  const [roleName, setRoleName] = React.useState('');

  const [roleOptions, setRoleOptions] = useState<any[]>([]);

  const fetchData = async () => {
    setRoleOptions([]);
    setDataError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/Roles`);
      setRoleOptions(data);
    } catch (e) {
      setRoleOptions([]);
      setDataError("Error occured while loading Roles.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (InstanceID) fetchData();
  }, [InstanceID]);

  function HandleRoleSelectionChange(event: SelectChangeEvent): void {
    setRoleID(event.target.value as string);
    var RoleName = roleOptions.find((role) => role.ID == event.target.value)?.RoleName;
    setRoleName(RoleName);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start", // Aligns the button to the right
          alignItems: "flex-start",     // Aligns the button to the bottom
          marginLeft: "10px",          // Pushes the button to the bottom if there's space
        }}
      >
        <GenericSelect<any>
          label="Role"
          value={roleID}
          onChange={(event) => HandleRoleSelectionChange(event)}
          options={roleOptions}
          disabled={false}
          valueField="ID"
          labelField="RoleName"
          loading={loading}
        />
      </Box >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '10px' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Role Managements Tabs">
            <Tab label="Menu Items" disabled={loading} />
            <Tab label="Workflows" disabled={loading} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {tabValue === 0 && <RoleMenuPermissionsGrid RoleID={roleID} Disabled={loading || !roleID} />}  {/* Load only when selected */}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {tabValue === 1 && <RoleWorkflowPermissionsGrid RoleID={roleID} RoleName={roleName} Disabled={loading || !roleID} />}
        </TabPanel>
      </Box>
    </Box>
  );
};


export { RoleAccessTab };