import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { TabPanel } from "./../TabPanel";
import {
  DrillingProgramTaskChargeRatesGrid,
  DrillingProgramMonthlyChargesGrid,
  DrillingProgramOneOffChargesGrid,
  DrillingProgramShiftChargesGrid,
  DrillingProgramPerMRatesGrid
} from './DrillingProgramCost';

interface DrillingProgramCostTabProps {
  ID_EA_MiningCompany: string | number;
  ID_EC_Contract: string | number;
  ID_EDP_DrillingProgram: string | number;
  tabValue: number;
  onTabChange: (newValue: number) => void;
}

export const DrillingProgramCostTab: React.FC<DrillingProgramCostTabProps> = ({
  ID_EA_MiningCompany,
  ID_EC_Contract,
  ID_EDP_DrillingProgram,
  tabValue,
  onTabChange
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '10px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Drilling Program Cost Tab Selector"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab label="Task Charge Rates" />
          <Tab label="Monthly Charges" />
          <Tab label="One Off Charges" />
          <Tab label="Shift Charges" />
          <Tab label="Per M Rates" />
        </Tabs>
      </Box>
      {/*The Grids that show underneath. */}
      <TabPanel value={tabValue} index={0}>
        <DrillingProgramTaskChargeRatesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DrillingProgramMonthlyChargesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DrillingProgramOneOffChargesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <DrillingProgramShiftChargesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <DrillingProgramPerMRatesGrid
          ID_EA_MiningCompany={ID_EA_MiningCompany}
          ID_EC_Contract={ID_EC_Contract}
          ID_EDP_DrillingProgram={ID_EDP_DrillingProgram}
        />
      </TabPanel>
    </Box>
  );
};