import {
  ConsumableRow,
  IDrillingDetailsRow,
  OneOffChargeRow,
  IPersonnelRow,
  ITaskRow,
} from "../../Interfaces/Shift.interface";
import dayjs, { Dayjs } from "dayjs";

interface ValidationRule<T> {
  check: (row: T) => boolean;
  message: string;
}

interface ValidationParams {
  personnelRows?: IPersonnelRow[];
  drillingDetailsRows?: IDrillingDetailsRow[];
  taskRows?: ITaskRow[];
  consumableRows?: ConsumableRow[];
  oneOffChargeRows?: OneOffChargeRow[];
  ID_EA_Rig: string;
  ID_EA_Site: string;
  DayNight: string;
  ID_WF_Status: string;
  ID_EA_Objective?: string; // this one is optional
  Date: string; // Assuming it's a string, update as needed.
}
interface ValidationConditions {
  MustHaveObjective: boolean;
  PersonnelMustHaveData: boolean;
  DrillingDetailsTimeConfig: "FromToTime" | "Hours" | "None";
  TaskTimeConfig: "FromToTime" | "Hours";
}
const runValidations = <T>(
  rules: ValidationRule<T>[],
  rows?: T[]
): string[] => {
  const errors: string[] = [];
  if (rows != undefined) {
    rules.forEach(({ check, message }) => {
      if (rows.some(check)) {
        errors.push(message);
      }
    });
  }
  return errors;
};

export const ValidateFields = (
  {
    personnelRows,
    drillingDetailsRows,
    taskRows,
    consumableRows,
    oneOffChargeRows,
    ID_EA_Rig,
    ID_EA_Site,
    DayNight,
    ID_WF_Status,
    ID_EA_Objective,
    Date,
  }: ValidationParams,
  {
    MustHaveObjective,
    PersonnelMustHaveData,
    DrillingDetailsTimeConfig,
    TaskTimeConfig,
  }: ValidationConditions
): string[] => {
  const validationErrors: string[] = [];

  // General validations
  if (PersonnelMustHaveData && personnelRows && personnelRows.length === 0)
    validationErrors.push("Personnel cannot be empty.");
  if (ID_EA_Rig === "") validationErrors.push("You must select a Rig.");
  if (ID_EA_Site === "") validationErrors.push("You must select a Site.");
  if (DayNight === "") validationErrors.push("You must select a Shift.");
  if (ID_WF_Status === "") validationErrors.push("You must select a Status.");
  if (MustHaveObjective && (ID_EA_Objective == null || ID_EA_Objective === ""))
    validationErrors.push("You must select an Objective.");
  if (!dayjs(Date).isValid())
    validationErrors.push("You must select a valid Date.");
  if (validationErrors.length < 1) {
    // Validation rules for specific rows
    const personnelRules: ValidationRule<IPersonnelRow>[] = [
      {
        check: (row) => row.ID_EA_Personnel == null || row.ID_EA_Personnel == 0,
        message: "Personnel cannot be empty.",
      },
      {
        check: (row) =>
          row.ID_EA_PersonnelType == null || row.ID_EA_PersonnelType == 0,
        message: "Personnel Type cannot be empty.",
      },
      { check: (row) => row.Hours < 0, message: "Hours cannot be negative." },
    ];

    const drillingDetailsRules: ValidationRule<IDrillingDetailsRow>[] = [
      { check: (row) => !row.Hole.trim(), message: "Hole cannot be empty." },
      {
        check: (row) => row.FromDepth < 0,
        message: "FromDepth cannot be negative.",
      },
      {
        check: (row) => row.ToDepth < 0,
        message: "ToDepth cannot be negative.",
      },
      {
        check: (row) => row.TotalRecovered < 0,
        message: "Total recovered cannot be negative.",
      },
      {
        check: (row) => !row.BitSerial.trim(),
        message: "Bit # cannot be empty.",
      },
      {
        check: (row) => row.ID_EA_BitSize < 0,
        message: "Bit Size cannot be empty.",
      },
    ];

    if (DrillingDetailsTimeConfig == "FromToTime") {
      drillingDetailsRules.push(
        {
          check: (row) => !row.FromTime || !dayjs(row.FromTime).isValid(),
          message: "From Time cannot be empty.",
        },
        {
          check: (row) => !row.ToTime || !dayjs(row.ToTime).isValid(),
          message: "To Time cannot be empty.",
        }
      );
    }
    if (DrillingDetailsTimeConfig == "Hours") {
      drillingDetailsRules.push({
        check: (row) => !row.Hours || row.Hours < 0,
        message: "Hours must be 0 or greater.",
      });
    }

    const taskRules: ValidationRule<ITaskRow>[] = [
      //TODO: Make it check here and see if it needs to go further in and if it needs the alt charge time thing, and ensure it has that if needed.
      { check: (row) => row.ID_EA_Task < 0, message: "Task cannot be empty." },
      {
        check: (row) => row.ChargeType < 0,
        message: "Charge cannot be empty.",
      },
    ];

    if (TaskTimeConfig == "FromToTime") {
      taskRules.push(
        {
          check: (row) => !row.FromTime || !dayjs(row.FromTime).isValid(),
          message: "From Time cannot be empty.",
        },
        {
          check: (row) => !row.ToTime || !dayjs(row.ToTime).isValid(),
          message: "To Time cannot be empty.",
        }
      );
    }
    if (TaskTimeConfig == "Hours") {
      taskRules.push({
        check: (row) => !row.Hours || row.Hours < 0,
        message: "Hours must be 0 or greater.",
      });
    }

    const consumableRules: ValidationRule<ConsumableRow>[] = [
      {
        check: (row) => row.ID_EA_Consumable < 0,
        message: "Consumable cannot be empty.",
      },
      {
        check: (row) => row.Quantity < 0,
        message: "Quantity cannot be negative.",
      },
    ];

    const oneOffChargeRules: ValidationRule<OneOffChargeRow>[] = [
      {
        check: (row) => row.ID_EDP_OneOffCharge < 0,
        message: "One Off Charge cannot be empty.",
      },
      {
        check: (row) => row.Quantity < 0,
        message: "Quantity cannot be negative.",
      },
    ];

    // Running validations

    validationErrors.push(...runValidations(personnelRules, personnelRows));
    validationErrors.push(
      ...runValidations(drillingDetailsRules, drillingDetailsRows)
    );
    validationErrors.push(...runValidations(taskRules, taskRows));
    validationErrors.push(...runValidations(consumableRules, consumableRows));
    validationErrors.push(
      ...runValidations(oneOffChargeRules, oneOffChargeRows)
    );
  }
  return validationErrors;
};
