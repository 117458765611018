import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { GenericToolbarProps } from './CustomPlodSheetDataGrid';
import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { randomId } from "@mui/x-data-grid-generator";

export const GenericToolbarSetNewRowLatestTime = <T,>({
  setRows,
  setRowModesModel,
  initialRow,
  fieldToFocus,
  buttonText,
  disabled,
}: GenericToolbarProps<T>) => {
  const handleAddClick = () => {
    const ID = randomId(); // Ensure randomId() is defined or imported

    // Get the current rows from the state
    setRows((oldRows) => {
      // Find the latest ToTime (by hours, ignoring day, year, or month)
      let latestToTime = oldRows
        .map((row: any) => row.ToTime) // Assuming ToTime is a Date object
        .reduce((latest, current) => {
          const latestDate = new Date(latest);
          const currentDate = new Date(current);
          return currentDate > latestDate ? current : latest;
        }, new Date(0).setHours(0)); // Default to epoch if no rows are found
      if (latestToTime == null) {
        latestToTime = new Date(0).setHours(0);
      }
      // Adjust the FromTime of the new row (add 1 hour to the latest ToTime)
      const dayjsnewdate = dayjs(latestToTime);
      // Create the new row, set FromTime to be the latest ToTime + 1 hour
      const newRow = {
        ...initialRow,
        ID,
        FromTime: dayjsnewdate, // New FromTime based on latest ToTime
        isNew: true,
      };

      // Return the updated rows with the new row added
      return [...oldRows, newRow];
    });
    setRowModesModel((oldModel: T) => ({
      ...oldModel,
      [ID]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        style={{ marginLeft: 'auto', marginRight: 0 }}
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </GridToolbarContainer>
  );
};