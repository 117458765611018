import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { LoadScript } from '@react-google-maps/api';
import { LicenseInfo } from '@mui/x-license';

//Need this to be a .env thing
LicenseInfo.setLicenseKey(process.env.REACT_APP_NEXT_PUBLIC_MUI_LICENSE!);
const root = createRoot(document.getElementById("root")!);



root.render(
  <Provider store={store}>
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!}>
      <App />
    </LoadScript>
  </Provider>
);