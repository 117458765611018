import {
  Box,
  SelectChangeEvent,
} from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridBooleanCell,
  GridColDef,
  GridEditBooleanCell,
  GridEditBooleanCellProps,
  GridEditInputCell,
  GridRenderCellParams,
  GridRowModel,
  GridRowModesModel,
  GridSortItem,
} from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { Api, handleErrorResponse } from "../../../api";
import getInstanceID from "../../../functions/getInstanceID";
import CustomNoRowsOverlay from "../../CustomNoRowsOverlay";
import { AlertService } from "../../../services/AlertService";
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import { getActionsForRow } from "../../EditableRowHandlers";
import { CustomBooleanOnlyShowIfTypeCell } from "./CustomBooleanOnlyShowIfTypeCell";
import { CustomEditBooleanOnlyShowIfTypeCell } from "./CustomEditBooleanOnlyShowIfTypeCell";
import CustomToolbarWithDropDown from "../../CustomToolbarWithDropDown";
import WorkflowRoleStatusPermissionsDiagram from './WorkflowRoleStatusPermissionsDiagram';


interface RoleMenuPermissionsGridProps {
  RoleID: string;
  RoleName: string;
  Disabled: boolean;
}

const RoleWorkflowPermissionsGrid: React.FunctionComponent<RoleMenuPermissionsGridProps> = ({ RoleID, RoleName, Disabled }) => {
  let InstanceID = getInstanceID();

  const [dataRowModesModel, setDataRowModesModel] = React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [DataGridError, setDataGridError] = useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  //#region Workflow Selector thing.
  const [workflowID, setWorkflowID] = React.useState('');
  const [workflowOptions, setWorkflowOptions] = useState<any[]>([]);
  const [workflowLoading, setWorkflowLoading] = React.useState(false);

  function HandleWorkflowSelectionChange(event: SelectChangeEvent): void {
    setWorkflowID(event.target.value as string);
  }

  const fetchWorkflowData = async () => {
    setWorkflowID('');
    setWorkflowOptions([]);
    setDataGridError(null);
    setWorkflowLoading(true);
    try {
      if (InstanceID) {
        const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/Workflows`);
        setWorkflowOptions(data);
      }
    } catch (e) {
      setWorkflowOptions([]);
      setDataGridError("Error occured while loading Workflows.");
    }
    setWorkflowLoading(false);
  };

  useEffect(() => {
    if (InstanceID) fetchWorkflowData();
  }, [InstanceID]);
  //#endregion

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });


  let columns: GridColDef<any>[] = [
    {
      headerName: "Can Migrate?",
      field: "canMigrate",
      width: 125,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="statusMigratePerms" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="statusMigratePerms" />;
      }
    },
    {
      headerName: "Browse",
      field: "viewDoc",
      width: 100,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="status" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="status" />;
      }
    },
    {
      headerName: "Read",
      field: "readDoc",
      width: 100,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="status" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="status" />;
      }
    },
    {
      headerName: "Edit",
      field: "updatDoc",
      width: 100,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="status" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="status" />;
      }
    },
    {
      headerName: "Add",
      field: "createDoc",
      width: 100,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="status" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="status" />;
      }
    },
    {
      headerName: "Delete",
      field: "deleteDoc",
      width: 100,
      editable: true,
      type: "boolean",
      renderCell: (params: GridRenderCellParams) => {
        return <CustomBooleanOnlyShowIfTypeCell  {...params} mustbethistype="status" />;
      },
      renderEditCell: (params: GridEditBooleanCellProps) => {
        return <CustomEditBooleanOnlyShowIfTypeCell {...params} mustbethistype="status" />;
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID, row }) => {
        const canEdit = row.type == 'statusMigratePerms' && row.parentActualID == row.actualID;
        return getActionsForRow({
          ID,
          rowModesModel: dataRowModesModel,
          handlers: { handleCancelClick, handleEditClick, handleSaveClick },
          actions: { cancel: true, edit: !canEdit, save: true },
        });
      }
    },
  ];



  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      let URL = newRow.type == 'statusMigratePerms' ? `ID_${newRow.parentActualID}/MigratePermission/` : "";
      const rawData = JSON.stringify({
        ...newRow,
        ID: newRow.actualID,
        hierarchy: null,
      });
      const { data } = await Api.post(
        `/api/Plods/ID_${InstanceID}/Roles/ID_${RoleID}/WorkFlows/ID_${workflowID}/WorkFlowStatus/${URL}~UpdateItem`,
        rawData
      );

      setDataRows((prev) =>
        prev.map((row: any) =>
          row.ID === newRow.ID ? { ...newRow } : row
        )
      ); // This actually updates the datarow, technically it doesn't actually need it lol.

      await AlertService.showAlert(`Successfully Updated Menu Access "${newRow.statusName}".`, "success");
      return newRow;
    } catch (e) {
      handleErrorResponse(e, `Error Updating Status Permissions "${newRow.statusName}".`);
      return;
    }
  };

  const fetchData = async () => {
    setDataRows([]);
    setDataGridError(null);
    setLoading(true);
    try {
      if (RoleID && workflowID) {
        const body = `{
          Plods(ID_AP_Plod: ${InstanceID}) {
            Roles(ID: ${RoleID}) {
              WorkFlows(ID: ${workflowID}) {
                WorkFlowStatus {
                  ID
                  statusName
                  colour
                  createDoc
                  readDoc
                  updatDoc
                  deleteDoc
                  viewDoc
                  statusOrder
                  MigratePermission {
                    ID
                    statusName
                    colour
                    statusOrder
                    canMigrate
                  }
                }
              }
            }
          }
        }`;
        const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
        const theData = data.data.Plods[0].Roles[0].WorkFlows[0].WorkFlowStatus;
        var returnData: any[] = [];
        theData.sort((a: any, b: any) => a.statusOrder - b.statusOrder)//Sorts the status via the statusOrder field
          .forEach((statuses: any) => {
            const { MigratePermission, ...data } = statuses;
            var statusID = "status-" + data.ID;
            const parentItem = {
              ...data,
              ID: statusID,
              actualID: data.ID,
              type: 'status',
              hierarchy: [statusID]
            };
            returnData.push(parentItem);

            MigratePermission.sort((a: any, b: any) => a.statusOrder - b.statusOrder)//Sorts the status via the statusOrder field
              .forEach((migratePerms: any) => {
                var migrateID = statusID + "statusMigratePerms-" + migratePerms.ID;
                if (parentItem.actualID != migratePerms.ID) {
                  returnData.push({
                    ...migratePerms,
                    ID: migrateID,
                    type: 'statusMigratePerms',
                    actualID: migratePerms.ID,
                    parentActualID: data.ID,
                    hierarchy: [statusID, migrateID]
                  });
                }
              }
              );
          });
        setDataRows(returnData);
      }
    } catch (e) {
      setDataRows([]);
      setDataGridError("An error occured while loading Workflow Status Items, Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [RoleID, workflowID, InstanceID]);


  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Status Name',
    valueGetter: (value, row, column, apiRef) => {
      return row.statusName;
    },
    flex: 1,
    minWidth: 200
  };

  const isCellEditable: DataGridPremiumProps['isCellEditable'] = (params) => {
    const isSameAsParent = params.row.type == 'statusMigratePerms' && params.row.parentActualID == params.row.actualID;
    return !isSameAsParent;
  };

  return (<>
    <Box sx={{ height: "70vh", maxHeight: '800px', margin: "10px" }}>
      <DataGridPremium
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        rows={dataRows}
        columns={columns}
        getRowId={(row) => row.ID}
        editMode="row"
        isCellEditable={isCellEditable}
        rowModesModel={dataRowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        loading={loading}
        defaultGroupingExpansionDepth={-1}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: () => (
            <CustomToolbarWithDropDown
              label={"Workflow"}
              value={workflowID}
              onChange={HandleWorkflowSelectionChange}
              options={workflowOptions}
              valueField={"ID"}
              labelField={"WorkflowName"}
              loading={workflowLoading}
              disabled={!RoleID}
              customMessage={!RoleID ? "Please Select a Role" : null}
            />
          ),
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={DataGridError ? DataGridError : !RoleID ? "Please Select a Role" : !workflowID ? "Please Select a Workflow" : "No Role Status Data"}
              onRetry={DataGridError ? fetchData : undefined}
            />
          ),
        }}
      />
    </Box>


    <WorkflowRoleStatusPermissionsDiagram data={dataRows} WorkflowID={workflowID} RoleName={RoleName} loading={loading} />
  </>
  );
};

export { RoleWorkflowPermissionsGrid };