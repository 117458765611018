import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../components/TabPanel";
import { BitsGrid, BitSizesGrid, BitTypesGrid, ManufacturersGrid } from '../components/Bits';


const Bits: React.FunctionComponent = () => {
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Bit Editing Selector"
        sx={{
          display: 'flex', // Use flexbox to control the alignment
          justifyContent: 'flex-start', // Align to the left to avoid indentation
          marginLeft: '10px', // Ensure no left margin
          '.MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0,
            width: 0
          }
        }}
      >
        <Tab sx={{ width: 175, height: 60 }} label="Bits Sizes" />
        <Tab sx={{ width: 175, height: 60 }} label="Bits Types" />
        <Tab sx={{ width: 175, height: 60 }} label="Manufacturers" />
        <Tab sx={{ width: 175, height: 60 }} label="Bits" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {tabValue === 1 && <div />}  <BitSizesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {tabValue === 1 && <div />}  <BitTypesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {tabValue === 1 && <div />}  <ManufacturersGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {tabValue === 1 && <div />}  <BitsGrid />
      </TabPanel>
    </>
  );
};

export default Bits;
