import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { Api, handleErrorResponse } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import { AlertService } from "../../services/AlertService";
import useStandardEditableRowActions from "../../components/useStandardEditableRowActions";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { GenericToolbar } from "../GenericToolbar";
import { getActionsForRow } from "../EditableRowHandlers";
import { IManufacturers } from '../../Interfaces/Manufacturer.interface';
import { GenericDataGrid } from "../GenericDatagrid";

export const ManufacturersGrid: React.FC = () => {
  const instanceID = getInstanceID();

  const columns: GridColDef[] = [
    {
      headerName: 'Manufacturer Name',
      field: 'ManufacturerName',
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  const initialRow: Partial<IManufacturers> = {
    ManufacturerName: ''
  };

  const validateFields = async (row: IManufacturers): Promise<boolean> => {
    if (!row.ManufacturerName || row.ManufacturerName === "") {
      await AlertService.showAlert("Manufacturer Name cannot be empty", 'criticalerror');
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  return (
    <Box sx={{ height: '80vh', margin: '10px' }}>
      <GenericDataGrid<IManufacturers>
        name="Manufacturer"
        baseURL={`/api/Plods/ID_${instanceID}/Manufacturers/`}
        columns={columns}
        primaryField="ManufacturerName"
        initialRow={initialRow}
        validateFields={validateFields}
      />
    </Box>
  );
}; 
