import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { TabPanel } from "./../TabPanel";
import { PersonnelsGrid, PersonnelTypesGrid, SitesGrid, LocationsGrid, DrillingTypesGrid, ObjectivesGrid } from "./PersonnelAndDrilling";

interface PersonnelAndDrillingTabProps {
  tabValue: number;
  onTabChange: (newValue: number) => void;
}

const PersonnelAndDrillingTab: React.FC<PersonnelAndDrillingTabProps> = ({ tabValue, onTabChange }) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '10px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="Personnel and Drilling Tab Selector"
          sx={{
            display: 'flex', // Use flexbox to control the alignment
            justifyContent: 'flex-start', // Align to the left to avoid indentation
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0,
              width: 0
            }
          }}
        >
          <Tab sx={{ width: 175, height: 60 }} label="Personnels" />
          <Tab sx={{ width: 175, height: 60 }} label="Personnels Type" />
          <Tab sx={{ width: 175, height: 60 }} label="Sites" />
          <Tab sx={{ width: 175, height: 60 }} label="Locations" />
          <Tab sx={{ width: 175, height: 60 }} label="Drilling Types" />
          <Tab sx={{ width: 175, height: 60 }} label="Objectives" />
        </Tabs>
      </Box>
      {/*The Grids that show underneath. */}
      <TabPanel value={tabValue} index={0}>
        <PersonnelsGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PersonnelTypesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <SitesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <LocationsGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <DrillingTypesGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <ObjectivesGrid />
      </TabPanel>
    </Box>
  );
};


export { PersonnelAndDrillingTab };