import React from "react";
import NewPlodBox from "../newPlodBox";
import CustomPlodSheetDataGrid from "../CustomPlodSheetDataGrid";
import { GridColDef, GridRenderEditCellParams, GridRowModesModel } from "@mui/x-data-grid-premium";
import { IGenericDropDownOption } from "../../../Interfaces/GenericDropDownOption.interface";
import { HoursColumns } from "../Columns/";
import { FireEventOnEditCell, GetDifferenceOfTwoFieldsCell } from "../../CustomCells/";
import { singleSelectComparator } from "../../../functions/singleSelectComparator";
import { getActionsForRow } from '../../EditableRowHandlers';
import { DrillingToolbarSetNewRowStartDrillingDetails } from "../DrillingToolbarSetNewRowStartDrillingDetails";
import { IDrillingDetailsRow, ITaskRow } from "../../../Interfaces/Shift.interface";
import { IDPTaskOption } from "../../../Interfaces/DPTaskOption.interface";
import { GenericToolbarSetNewRowLatestTime } from '../GenericToolbarSetNewRowLatestTime';
import useStandardEditableRowActions from "../../useStandardEditableRowActions";
import { RenderDrillingDetailsHoleEditCell } from "../CustomCells";

interface DrillingDetailsGridProps {
  DrillingDetailsTimeConfig: string;
  bitSizeOptions: IGenericDropDownOption[], // TODO: Make this the proper type
  bitTypeOptions: IGenericDropDownOption[],// TODO: Make this the proper type

  drillingDetailsRows: IDrillingDetailsRow[],
  drillingDetailsRowModesModel: GridRowModesModel, // The row modes model
  setDrillingDetailsRowModesModel: (model: GridRowModesModel) => void, // A setter function for updating row modes
  setDrillingDetailsRows: React.Dispatch<React.SetStateAction<IDrillingDetailsRow[]>>;

  NewDrillingDetailsDefaultFromTime: string; // This is the config on what can happen

  loading: boolean;
  disabled: boolean;
  canEdit: boolean;

  //This is for the custom request that will do the "is drilling task latest" stuff ->
  //TODO: Look at just passing in the toolbar instead.
  taskRows: ITaskRow[];
  taskOptions: IDPTaskOption[];

}

export const DrillingDetailsGrid: React.FunctionComponent<DrillingDetailsGridProps> = ({
  DrillingDetailsTimeConfig,
  bitSizeOptions,
  bitTypeOptions,
  drillingDetailsRows,
  drillingDetailsRowModesModel,
  setDrillingDetailsRowModesModel,
  setDrillingDetailsRows,
  NewDrillingDetailsDefaultFromTime,
  loading,
  disabled,
  taskRows,
  taskOptions,
  canEdit
}) => {

  //Pull the generic time columns
  const drillingDetailsTimeColumns: GridColDef<any>[] = HoursColumns(DrillingDetailsTimeConfig);

  const Handlers = useStandardEditableRowActions({
    dataRows: drillingDetailsRows,
    dataRowModesModel: drillingDetailsRowModesModel,
    setDataRowModesModel: setDrillingDetailsRowModesModel,
    setDataRows: setDrillingDetailsRows,
  });


  //The other columns (might make some switch on off etc at some point)
  const drillingDetailsColumns: GridColDef<any>[] = [
    ...drillingDetailsTimeColumns,
    {
      field: "Hole",
      headerName: "Hole",
      flex: 2,
      minWidth: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => <RenderDrillingDetailsHoleEditCell {...params} />,
    },
    {
      field: "FromDepth",
      headerName: "From",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <FireEventOnEditCell
            {...params}
            fieldstoeditonchange={[{ field: 'Total', value: null }]}
          />
        );
      },
    },
    {
      field: "ToDepth",
      headerName: "To",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <FireEventOnEditCell
            {...params}
            fieldstoeditonchange={[{ field: 'Total', value: null }]}
          />
        );
      },
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
      valueGetter: (value, row) => {
        let total = row.ToDepth - row.FromDepth;
        return total;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GetDifferenceOfTwoFieldsCell
            {...params}
            fieldfrom={"FromDepth"}
            fieldto={"ToDepth"}
          />
        );
      },
    },
    {
      field: "Stickup",
      headerName: "Stickup",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
    },
    {
      field: "TotalRecovered",
      headerName: "Total Recovered",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "number",
    },
    {
      field: "BitSerial",
      headerName: "Bit #",
      flex: 1,
      minWidth: 200,
      editable: true,
    },
    {
      field: "ID_EA_BitType",
      headerName: "Bit Type",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: bitTypeOptions,
      sortComparator: singleSelectComparator,
    },
    {
      field: "ID_EA_BitSize",
      headerName: "Bit Size",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
      sortComparator: singleSelectComparator,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) =>
        getActionsForRow({
          ID,
          rowModesModel: drillingDetailsRowModesModel,
          handlers: Handlers,
          actions: { cancel: true, edit: true, save: true, delete: true },
          disabled: !canEdit
        }),
    }
  ];



  const DrillingDetailsToolbarComponent = NewDrillingDetailsDefaultFromTime === 'LatestToTime'
    ? GenericToolbarSetNewRowLatestTime
    : NewDrillingDetailsDefaultFromTime === 'FromDrillingTask'
      ? (props: any) => <DrillingToolbarSetNewRowStartDrillingDetails {...props} taskRows={taskRows} taskOptions={taskOptions} />
      : undefined;



  return (
    <NewPlodBox color="#f26b31" text="Drilling Details" key="DrillingDetailsDataGrid">
      <CustomPlodSheetDataGrid
        columns={drillingDetailsColumns}
        rows={drillingDetailsRows}
        rowModesModel={drillingDetailsRowModesModel}
        setRows={setDrillingDetailsRows}
        setRowModesModel={setDrillingDetailsRowModesModel}
        fieldToFocus={"Hole"}
        buttonText={"Add Drilling Detail"}
        initialRow={{ Hole: '', FromDepth: 0, ToDepth: 0, Total: 0, TotalRecovered: 0, Stickup: 0, BitSerial: '', ID_EA_BitType: '', ID_EA_BitSize: '' }}
        disabled={!canEdit || disabled}
        loading={loading}
        ToolbarComponent={DrillingDetailsToolbarComponent}
        isCellEditable={(params) => canEdit && !disabled}
      />
    </NewPlodBox>
  );
};