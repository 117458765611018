import {
  Box,
} from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { Api, handleErrorResponse } from "../../api";
import getInstanceID from "../../functions/getInstanceID";
import CustomNoRowsOverlay from "../CustomNoRowsOverlay";
import { AlertService } from "../../services/AlertService";
import useStandardEditableRowActions from "../useStandardEditableRowActions";
import { getActionsForRow } from "../EditableRowHandlers";
import { GenericToolbar } from '../GenericToolbar';

const RoleManagementGrid: React.FunctionComponent = () => {
  let InstanceID = getInstanceID();

  const [dataRowModesModel, setDataRowModesModel] = React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const [DataGridPremiumError, setDataGridPremiumError] = useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);


  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentRoleName: string = await dataRows.filter((row) => row.ID === ID)[0].RoleName;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Role "${currentRoleName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/Roles/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Role "${currentRoleName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Role "${currentRoleName}".`);
      }
    }
  };

  let columns: GridColDef<any>[] = [
    {
      headerName: "Role",
      field: "RoleName",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => getActionsForRow({
        ID,
        rowModesModel: dataRowModesModel,
        handlers: { handleDeleteClick, handleCancelClick, handleEditClick, handleSaveClick },
        actions: { cancel: true, edit: true, save: true, delete: true },
      }),
    },
  ];

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (!newRow.RoleName?.trim()) {
      await AlertService.showAlert(`Row has fields causing errors.`, 'criticalerror', 'Missing Role Name');
      return;
    }
    else {
      try {
        const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
        const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
        const { data } = await Api.post(
          `/api/Plods/ID_${InstanceID}/Roles/${endpoint}`,
          rawData
        );
        const updatedRow = {
          ...newRow,
          ID: data.ID,
          isNew: false,
        };
        setDataRows((prev) =>
          prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
        );
        await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Role "${newRow.RoleName}".`, "success");
        return updatedRow;
      } catch (e) {
        handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Role "${newRow.RoleName}".`);
        return;
      }
    }
  };

  const fetchData = async () => {
    setDataRows([]);
    setDataGridPremiumError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${InstanceID}/Roles`);
      setDataRows(data);
    } catch (e) {
      setDataRows([]);
      setDataGridPremiumError("An error occured while loading Roles, Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (InstanceID) fetchData();
  }, [InstanceID]);

  return (
    <Box sx={{ height: "70vh", maxHeight: '800px', margin: "10px" }}>
      <DataGridPremium
        rows={dataRows}
        columns={columns}
        getRowId={(row) => row.ID} // Gets the id from database, not the local style one
        editMode="row"
        rowModesModel={dataRowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        loading={loading}
        slots={{
          toolbar: () => (
            <GenericToolbar
              setRows={setDataRows}
              setRowModesModel={setDataRowModesModel}
              fieldToFocus="RoleName"
              buttonText="Add Role"
              disabled={loading}
              initialRow={{ RoleName: '' }}
            />
          ),
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={DataGridPremiumError ? DataGridPremiumError : "No Team Member Data"}
              onRetry={DataGridPremiumError ? fetchData : undefined}
            />
          ),
        }}
      />
    </Box>
  );
};

export { RoleManagementGrid };