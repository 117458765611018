import React from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridComparatorFn, GridSingleSelectColDef, GridSortCellParams } from '@mui/x-data-grid-premium';

//TODO: Allow this to pass in a custom field for the label.


export const singleSelectComparator = (
  v1: any,
  v2: any,
  cellParams1: GridSortCellParams<any>,
  cellParams2: GridSortCellParams<any>,
  getLabelValue?: (value: any) => string // New optional parameter for custom label retrieval
) => {
  // Access API and field
  const api: GridApiPremium = cellParams1.api;
  const field = cellParams1.field;

  // Get column definition
  const columnDef: GridSingleSelectColDef = api.getColumn(field) as GridSingleSelectColDef;

  // Ensure options and functions are defined
  if (!columnDef.valueOptions || !columnDef.getOptionValue || !columnDef.getOptionLabel) {
    console.warn("Column definition is missing required properties");
    return 0;
  }

  const options = Array.isArray(columnDef.valueOptions) ? columnDef.valueOptions : [];
  const getOptionValue = columnDef.getOptionValue;
  const getOptionLabel = columnDef.getOptionLabel;

  // Find matching options
  const value1 = options.find((option) => getOptionValue(option) === v1);
  const value2 = options.find((option) => getOptionValue(option) === v2);

  // Handle null or undefined values first
  if (value1 == null && value2 == null) {
    return 0; // Treat null values as equivalent
  }
  else if (value1 == null) {
    return -1; // Null values come before non-null values
  }
  else if (value2 == null) {
    return 1; // Non-null values come after null values
  }
  else {
    // Use the provided custom getLabelValue function or fallback to getOptionLabel
    const label1 = getLabelValue ? getLabelValue(value1) : getOptionLabel(value1);
    const label2 = getLabelValue ? getLabelValue(value2) : getOptionLabel(value2);

    // Compare labels
    return label1.localeCompare(label2);
  }
};
